import { TiktokenModel } from "js-tiktoken";
import {
  ModelInputEntry,
  ModelInputEntryCost,
  ModelInputPreset,
  ModelInputPresetEntryInput,
  ModelInputPresetEntryInputUserValue,
  Models,
  TaskExecutionResultInput,
  TokenizerModel,
} from "../../../graphql/Codegen/API";
import CoreConstants from "../CoreConstants";
import { countWords, removeAllWhitespace } from "./StringHelper";
let encodingForModel: any;
import("js-tiktoken").then((tiktoken) => {
  encodingForModel = tiktoken.encodingForModel;
});
function convertTokenizerModelToTiktoken(
  tokenizerModel?: TokenizerModel
): TiktokenModel {
  if (tokenizerModel == undefined) return "gpt-4o";
  return tokenizerModel.replace("__", ".").replace("_", "-") as TiktokenModel;
}
export function calculateCostPerUsage(cost: number) {
  return Math.ceil(cost * CoreConstants.CREDIT_TO_USD_RATION * 100) / 100;
}
export function calculateTotalCreditAwardPerUsage(cost: number) {
  return (
    Math.ceil(
      cost *
        CoreConstants.CREDIT_TO_USD_RATION *
        (1 - CoreConstants.PRESET_TAX) *
        100
    ) / 100
  );
}

function presetInputReducedString(
  userInputPresetData: ModelInputPresetEntryInputUserValue
): string | undefined {
  return userInputPresetData.presetEntryDatas?.reduce((sum, current) => {
    return sum + (current?.val ?? "");
  }, "");
}

export function countTokens(
  inputText: string,
  tokenizerModel?: TokenizerModel
) {
  let convertedModel = convertTokenizerModelToTiktoken(
    tokenizerModel ?? TokenizerModel.gpt_4o
  );
  const enc = encodingForModel(convertedModel);
  const tokens = enc.encode(inputText);
  return tokens.length;
}
function calculateTokenCosts(
  cost: ModelInputEntryCost,
  userInputData: TaskExecutionResultInput,
  activePresetData: ModelInputPresetEntryInput | undefined | null,
  userInputPresetData: ModelInputPresetEntryInputUserValue | undefined | null
) {
  if (cost?.costPerToken == undefined || cost?.tokenizerModel == undefined)
    return 0;

  let costBasis = 0;
  let convertedModel = convertTokenizerModelToTiktoken(cost.tokenizerModel);
  const enc = encodingForModel(convertedModel);
  const tokens = enc.encode(userInputData.value);
  costBasis += Math.max(1, Math.round(tokens.length * cost.costPerToken));

  //Preset Defaults
  if (activePresetData) {
    let presetTokensCount =
      activePresetData?.presetEntryDatas?.reduce((sum, current) => {
        return sum + (current?.metaData?.TOKENS_COUNT ?? 0);
      }, 0) ?? 0;
    costBasis += Math.round(presetTokensCount * (cost.costPerToken ?? 0));
  }
  //Preset User Input
  if (userInputPresetData) {
    let accumulatedUserPresetInput =
      presetInputReducedString(userInputPresetData);
    const presetTokens = enc.encode(accumulatedUserPresetInput);
    costBasis += Math.round(presetTokens.length * cost.costPerToken);
  }
  return costBasis;
}
function calculateStringCosts(
  cost: ModelInputEntryCost,
  userInputData: TaskExecutionResultInput,
  activePresetData: ModelInputPresetEntryInput | undefined | null,
  userInputPresetData: ModelInputPresetEntryInputUserValue | undefined | null
) {
  let costBasis = 0;
  if (cost?.costPerWord != undefined) {
    if (userInputData.value) {
      costBasis += countWords(userInputData.value) * cost.costPerWord;
    }
    //Preset Defaults
    if (activePresetData?.presetEntryDatas) {
      activePresetData.presetEntryDatas.forEach((entry) => {
        if (entry?.val)
          costBasis += countWords(entry.val) * (cost?.costPerWord ?? 0);
      });
    }

    //Preset User Input
    if (userInputPresetData) {
      let accumulatedUserPresetInput =
        presetInputReducedString(userInputPresetData);
      if (accumulatedUserPresetInput) {
        costBasis += countWords(accumulatedUserPresetInput) * cost.costPerWord;
      }
    }
  }

  if (cost?.costPerCharacter) {
    if (userInputData.value) {
      costBasis +=
        removeAllWhitespace(userInputData.value).length * cost.costPerCharacter;
    }

    //Preset Defaults
    if (activePresetData?.presetEntryDatas) {
      let presetCharacterLength = activePresetData.presetEntryDatas.reduce(
        (sum, current) => {
          return sum + (current?.metaData?.STRING_LENGTH ?? 0);
        },
        0
      );
      costBasis += presetCharacterLength * cost.costPerCharacter;
    }

    //Preset User Input
    if (userInputPresetData) {
      let accumulatedUserPresetInput =
        presetInputReducedString(userInputPresetData);
      if (accumulatedUserPresetInput) {
        costBasis +=
          removeAllWhitespace(accumulatedUserPresetInput).length *
          cost.costPerCharacter;
      }
    }
  }
  return costBasis;
}
function calculateInputEntryCostMain(
  cost: ModelInputEntryCost,
  userInputData: TaskExecutionResultInput,
  activePresetData: ModelInputPresetEntryInput | undefined | null,
  userInputPresetData: ModelInputPresetEntryInputUserValue | undefined | null
): { costBasis: number; costMultiplier: number } {
  var costBasis = 0;
  var costMultiplier = 1;
  if (cost?.flatFee) {
    costBasis += cost.flatFee;
  }
  costBasis += calculateTokenCosts(
    cost,
    userInputData,
    activePresetData,
    userInputPresetData
  );

  if (cost?.isMultiplier == true) {
    if (userInputData.value) {
      if (+userInputData.value > 0) costMultiplier *= +userInputData.value;
    }
    //Preset Defaults
    if (activePresetData) {
      costMultiplier *= +(activePresetData?.presetEntryDatas?.[0]?.val ?? 1);
    }
  }
  costBasis += calculateStringCosts(
    cost,
    userInputData,
    activePresetData,
    userInputPresetData
  );
  if (cost?.costPerValue != undefined) {
    if (userInputData.value)
      costBasis += +userInputData.value * cost?.costPerValue;
    //Preset Defaults
    if (activePresetData) {
      activePresetData.presetEntryDatas?.forEach((entry) => {
        if (entry?.val)
          if (userInputData.value)
            costBasis += +userInputData.value * (cost?.costPerValue ?? 0);
      });
    }
  }
  if (cost.costPerFileDuration) {
    const duration = userInputData?.metaData?.DURATION;
    if (duration) {
      const audioMinutes = +duration / 60;
      if (cost?.costPerFileDuration)
        costBasis += Math.round(audioMinutes * cost?.costPerFileDuration);
    }
    //Preset Defaults
    let presetEntryDatas = activePresetData?.presetEntryDatas;
    if (presetEntryDatas != undefined) {
      let allDurations =
        presetEntryDatas.reduce((sum, current) => {
          return sum + (current?.metaData?.DURATION ?? 0);
        }, 0) / 60.0;
      costBasis += Math.round(allDurations * cost.costPerFileDuration);
    }
  }
  return { costBasis, costMultiplier };
}
function calculateInputEntryCost(
  modelInfo: Models,
  userInputData: TaskExecutionResultInput,
  withAPI: boolean,
  activePresetData: ModelInputPresetEntryInput | undefined | null,
  userInputPresetData: ModelInputPresetEntryInputUserValue | undefined | null
): { costBasis: number; costMultiplier: number } {
  let targetInput = modelInfo.inputs?.entries?.find(
    (modelInput) => modelInput?.backendName == userInputData.name
  );
  var costBasis = 0;
  var costMultiplier = 1;
  if (targetInput == undefined) return { costBasis, costMultiplier };

  let targetCost =
    withAPI == true ? targetInput.costWithAPIKey : targetInput.cost;
  if (targetCost) {
    let baseCalcs = calculateInputEntryCostMain(
      targetCost,
      userInputData,
      activePresetData,
      userInputPresetData
    );
    costBasis += baseCalcs.costBasis;
    costMultiplier *= baseCalcs.costMultiplier;
  }
  if (targetInput?.options != undefined) {
    targetInput.options.forEach((option) => {
      if (option == undefined) return;
      let targetOptionCost =
        withAPI == true ? option.costWithAPIKey : option.cost;
      if (targetOptionCost) {
        if (userInputData.value == option.value) costBasis += targetOptionCost;
        //Preset Defaults
        if (activePresetData?.presetEntryDatas) {
          activePresetData!.presetEntryDatas!.forEach((entry) => {
            if (entry?.val != undefined && option.value != undefined) {
              if (entry.val == option.value) costBasis += targetOptionCost ?? 0;
            }
          });
        }
      }
    });
  }

  // costInterpolateMin
  // costInterpolateMax
  // costPerFileSize
  return { costBasis, costMultiplier };
}

export function calculateQuote(
  modelInfo: Models,
  userInputs: TaskExecutionResultInput[],
  withAPI: boolean,
  activePreset: ModelInputPreset | undefined,
  presetInputUserValues: ModelInputPresetEntryInputUserValue[] | undefined
): number {
  var finalCost = 0;
  var finalMultiplier = 1;
  for (let inputData of userInputs) {
    let { costBasis, costMultiplier } = calculateInputEntryCost(
      modelInfo,
      inputData,
      withAPI,
      activePreset?.entries?.find((x) => x?.modelInputName == inputData.name),
      presetInputUserValues?.find((x) => x?.modelInputName == inputData.name)
    );
    finalCost += costBasis;
    finalMultiplier *= costMultiplier;
  }
  return Math.ceil(finalCost * finalMultiplier);
}
