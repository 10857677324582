/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UpdateMLTaskExecutionResultInputNoDS = {
  id: string,
  userID?: string | null,
  HEARTReactionStats?: number | null,
  CUTEReactionStats?: number | null,
  FUNNYReactionStats?: number | null,
  WOWReactionStats?: number | null,
  SADReactionStats?: number | null,
  ANGRYReactionStats?: number | null,
  SCARYReactionStats?: number | null,
  POOPReactionStats?: number | null,
  GROSSReactionStats?: number | null,
  taskName?: string | null,
  modelName?: string | null,
  privacyLevel?: TaskPrivacyLevels | null,
  inputs?: Array< TaskExecutionResultInputInput | null > | null,
  output?: TaskExecutionResultOutputInput | null,
  creditsUsed?: number | null,
  status?: TaskResultStatus | null,
  createdAt?: string | null,
  mltasksworkspaceID?: string | null,
  modelsID?: string | null,
};

export enum TaskPrivacyLevels {
  public = "public",
  private = "private",
}


export type TaskExecutionResultInputInput = {
  name?: string | null,
  value?: string | null,
  type?: ModelInputOutputEntryType | null,
  metaData?: InputMetaDataInput | null,
};

export enum ModelInputOutputEntryType {
  TEXT = "TEXT",
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
  NUMBER_FLOAT = "NUMBER_FLOAT",
  NUMBER_INT = "NUMBER_INT",
  URL_IMAGE = "URL_IMAGE",
  URL_AUDIO = "URL_AUDIO",
  URL_VIDEO = "URL_VIDEO",
}


export type InputMetaDataInput = {
  FILE_NAME?: string | null,
  FILE_TYPE?: string | null,
  FILE_SIZE_KB?: number | null,
  DURATION?: number | null,
  WIDTH?: number | null,
  HEIGHT?: number | null,
  STRING_LENGTH?: number | null,
  TOKENS_COUNT?: number | null,
};

export type TaskExecutionResultOutputInput = {
  message?: string | null,
  entries?: Array< TaskExecutionResultOutputEntryInput | null > | null,
};

export type TaskExecutionResultOutputEntryInput = {
  name?: string | null,
  value?: string | null,
  type?: ModelInputOutputEntryType | null,
};

export enum TaskResultStatus {
  DRAFT = "DRAFT",
  ENQUEUED = "ENQUEUED",
  EXECUTING = "EXECUTING",
  PENDING_CALLBACK = "PENDING_CALLBACK",
  POLLING_FOR_RESULTS = "POLLING_FOR_RESULTS",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
}


export type MLTaskExecutionResult = {
  id: string,
  userID: string,
  HEARTReactionStats?: number | null,
  CUTEReactionStats?: number | null,
  FUNNYReactionStats?: number | null,
  WOWReactionStats?: number | null,
  SADReactionStats?: number | null,
  ANGRYReactionStats?: number | null,
  SCARYReactionStats?: number | null,
  POOPReactionStats?: number | null,
  GROSSReactionStats?: number | null,
  Reactions?: ModelTaskReactionConnection | null,
  taskName?: string | null,
  modelName?: string | null,
  privacyLevel?: TaskPrivacyLevels | null,
  inputs?:  Array<TaskExecutionResultInput | null > | null,
  output?: TaskExecutionResultOutput | null,
  creditsUsed?: number | null,
  status?: TaskResultStatus | null,
  presetUserInput?: ModelInputPresetInputUserValue | null,
  metadata?: TaskExecutionResultMetadata | null,
  createdAt: string,
  mltasksworkspaceID: string,
  modelsID: string,
  updatedAt: string,
};

export type ModelTaskReactionConnection = {
  items:  Array<TaskReaction | null >,
  nextToken?: string | null,
};

export type TaskReaction = {
  userID: string,
  type: TaskReactionType,
  mltaskexecutionresultID: string,
  createdAt: string,
  updatedAt: string,
};

export enum TaskReactionType {
  HEART = "HEART",
  CUTE = "CUTE",
  FUNNY = "FUNNY",
  WOW = "WOW",
  SAD = "SAD",
  ANGRY = "ANGRY",
  SCARY = "SCARY",
  POOP = "POOP",
  GROSS = "GROSS",
}


export type TaskExecutionResultInput = {
  name?: string | null,
  value?: string | null,
  type?: ModelInputOutputEntryType | null,
  metaData?: InputMetaData | null,
};

export type InputMetaData = {
  FILE_NAME?: string | null,
  FILE_TYPE?: string | null,
  FILE_SIZE_KB?: number | null,
  DURATION?: number | null,
  WIDTH?: number | null,
  HEIGHT?: number | null,
  STRING_LENGTH?: number | null,
  TOKENS_COUNT?: number | null,
};

export type TaskExecutionResultOutput = {
  message?: string | null,
  entries?:  Array<TaskExecutionResultOutputEntry | null > | null,
};

export type TaskExecutionResultOutputEntry = {
  name?: string | null,
  value?: string | null,
  type?: ModelInputOutputEntryType | null,
};

export type ModelInputPresetInputUserValue = {
  presetID?: string | null,
  presetVersion?: string | null,
  presetName?: string | null,
  presetCreatorName?: string | null,
  presetCreatorUID?: string | null,
  presetValues?:  Array<ModelInputPresetEntryInputUserValue | null > | null,
};

export type ModelInputPresetEntryInputUserValue = {
  modelInputName?: string | null,
  presetEntryDatas?:  Array<ModelInputPresetEntryInputUserValueEntryData | null > | null,
};

export type ModelInputPresetEntryInputUserValueEntryData = {
  parameterName?: string | null,
  val?: string | null,
  presetOrder?: number | null,
};

export type TaskExecutionResultMetadata = {
  LumaLabsGenerationID?: string | null,
  ReplicatePredictionID?: string | null,
  RunwayMLGenerationID?: string | null,
};

export type UpdateUserInputNoDS = {
  id: string,
  name?: string | null,
  username?: string | null,
  currentSubscriptionPlan?: SubscriptionPlan | null,
  periodEndOfLastSubscriptionCredits?: number | null,
  createdPresetCount?: number | null,
  completedTasksCount?: number | null,
  stripeCustomerID?: string | null,
  stripeConnectAccountID?: string | null,
  stripeConnectAccountSetupComplete?: boolean | null,
  email?: string | null,
  picture?: string | null,
  credits?: number | null,
  redeemableCredits?: number | null,
};

export enum SubscriptionPlan {
  NO_PLAN = "NO_PLAN",
  SUBSCRIPTION_5000 = "SUBSCRIPTION_5000",
  SUBSCRIPTION_15000 = "SUBSCRIPTION_15000",
  SUBSCRIPTION_100000 = "SUBSCRIPTION_100000",
}


export type User = {
  id: string,
  name?: string | null,
  username?: string | null,
  anthropicAPIKey?: string | null,
  elevenLabsAPIKey?: string | null,
  FalAPIKey?: string | null,
  lumaLabsAPIKey?: string | null,
  nimsAPIKey?: string | null,
  openAIAPIKey?: string | null,
  replicateAPIKey?: string | null,
  runwayMLAPIKey?: string | null,
  SAIAPIKey?: string | null,
  currentSubscriptionPlan?: SubscriptionPlan | null,
  periodEndOfLastSubscriptionCredits?: number | null,
  createdPresetCount?: number | null,
  completedTasksCount?: number | null,
  stripeCustomerID?: string | null,
  stripeConnectAccountID?: string | null,
  stripeConnectAccountSetupComplete?: boolean | null,
  email?: string | null,
  picture?: string | null,
  credits?: number | null,
  redeemableCredits?: number | null,
  tiktok?: TiktokData | null,
  pinterest?: PinterestData | null,
  linkedin?: LinkedinData | null,
  google?: GoogleData | null,
  twitter?: TwitterData | null,
  facebook?: FacebookData | null,
  SocialManPosts?: ModelSocialManPostConnection | null,
  MLTaskExecutionResults?: ModelMLTaskExecutionResultConnection | null,
  MLTasksWorkspaces?: ModelMLTasksWorkspaceConnection | null,
  ModelInputPresets?: ModelModelInputPresetConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type TiktokData = {
  tokenData: TiktokTokenData,
  userData: TiktokUserData,
};

export type TiktokTokenData = {
  accessToken: string,
  accessTokenExpirationDate: number,
  refreshToken: string,
  scope: string,
  refreshTokenExpirationDate: number,
};

export type TiktokUserData = {
  avatarURL?: string | null,
  avatarURL100?: string | null,
  avatarLargeURL?: string | null,
  displayName?: string | null,
  bioDescription?: string | null,
  profileDeepLink?: string | null,
  isVerified?: boolean | null,
  username?: string | null,
  followerCount?: number | null,
  followingCount?: number | null,
  likesCount?: number | null,
  videoCount?: number | null,
  privacyLevelOptions?: Array< string | null > | null,
  commentDisabled?: boolean | null,
  duetDisabled?: boolean | null,
  stitchDisabled?: boolean | null,
  maxVideoPostDuration_sec?: number | null,
};

export type PinterestData = {
  tokenData: PinterestTokenData,
  userData: PinterestUserData,
  boardsData?:  Array<PinterestUserBoardData | null > | null,
};

export type PinterestTokenData = {
  accessToken: string,
  accessTokenExpirationDate: number,
  refreshToken: string,
  scope: string,
  refreshTokenExpirationDate: number,
};

export type PinterestUserData = {
  accountType?: string | null,
  id?: string | null,
  profileImage?: string | null,
  websiteUrl?: string | null,
  username?: string | null,
  about?: string | null,
  businessName?: string | null,
  boardCount?: number | null,
  pinCount?: number | null,
  followerCount?: number | null,
  followingCount?: number | null,
  monthlyViews?: number | null,
};

export type PinterestUserBoardData = {
  id?: string | null,
  name?: string | null,
  pinCount?: number | null,
  followerCount?: number | null,
  imageCoverUrl?: string | null,
  createdAt?: string | null,
  description?: string | null,
  privacy?: string | null,
};

export type LinkedinData = {
  tokenData: LinkedinTokenData,
  userData: LinkedinUserData,
};

export type LinkedinTokenData = {
  accessToken: string,
  accessTokenExpirationDate: number,
  scope: string,
};

export type LinkedinUserData = {
  email?: string | null,
  name?: string | null,
  picture?: string | null,
  sub?: string | null,
};

export type GoogleData = {
  tokenData: GoogleTokenData,
  userData: GoogleUserData,
  channelsData:  Array<YoutubeChannelData | null >,
};

export type GoogleTokenData = {
  accessToken?: string | null,
  refreshToken?: string | null,
  scope?: string | null,
  tokenType?: string | null,
  idToken?: string | null,
  expiresOn?: number | null,
};

export type GoogleUserData = {
  id?: string | null,
  email?: string | null,
  verifiedEmail?: boolean | null,
  name?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  picture?: string | null,
  locale?: string | null,
};

export type YoutubeChannelData = {
  id?: string | null,
  customUrl?: string | null,
  title?: string | null,
  description?: string | null,
  thumbnail?: string | null,
  longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
};

export enum YoutubeChannelDataLongUploadStatus {
  ALLOWED = "ALLOWED",
  DISALLOWED = "DISALLOWED",
  ELIGIBLE = "ELIGIBLE",
}


export type TwitterData = {
  tokenData: TwitterTokenData,
  userData: TwitterUserData,
};

export type TwitterTokenData = {
  accessToken?: string | null,
  accessTokenSecret?: string | null,
  refreshToken?: string | null,
  codeVerifier?: string | null,
  oauthTokenSecret?: string | null,
  expiresOn?: number | null,
};

export type TwitterUserData = {
  id?: string | null,
  name?: string | null,
  username?: string | null,
  description?: string | null,
  verifiedType?: TwitterVerifiedType | null,
  profilePictureURL?: string | null,
};

export enum TwitterVerifiedType {
  UNVERIFIED = "UNVERIFIED",
  BLUE = "BLUE",
  BUSINESS = "BUSINESS",
  GOVERNMENT = "GOVERNMENT",
}


export type FacebookData = {
  userData: FacebookUserData,
  pagesData:  Array<FacebookPageData | null >,
  instagramsData:  Array<InstagramUserData | null >,
};

export type FacebookUserData = {
  id?: string | null,
  name?: string | null,
  picture?: string | null,
  accessToken?: string | null,
  expiresOn?: number | null,
};

export type FacebookPageData = {
  id?: string | null,
  name?: string | null,
  category?: string | null,
  picture?: string | null,
  accessToken?: string | null,
  expiresOn?: number | null,
};

export type InstagramUserData = {
  connectedPageID?: string | null,
  id?: string | null,
  igID?: string | null,
  biography?: string | null,
  name?: string | null,
  username?: string | null,
  profilePictureURL?: string | null,
  followsCount?: number | null,
  followersCount?: number | null,
  mediaCount?: number | null,
  accessToken?: string | null,
  expiresOn?: number | null,
};

export type ModelSocialManPostConnection = {
  items:  Array<SocialManPost | null >,
  nextToken?: string | null,
};

export type SocialManPost = {
  id: string,
  title?: string | null,
  description?: string | null,
  s3Key?: string | null,
  s3KeyResized?: string | null,
  userID: string,
  createdAt: string,
  scheduledAt?: string | null,
  postedAt?: string | null,
  status: SocialManPostStatus,
  tiktok?: SocialManPostTiktokData | null,
  linkedin?: SocialManPostLinkedinData | null,
  pinterest?: SocialManPostPinterestData | null,
  youtube?: SocialManPostYoutubeData | null,
  instagram?: SocialManPostInstagramData | null,
  facebook?: SocialManPostFacebookData | null,
  twitter?: SocialManPostTwitterData | null,
  postedToTiktok?: boolean | null,
  postedToLinkedin?: boolean | null,
  postedToPinterest?: boolean | null,
  postedToYoutube?: boolean | null,
  postedToFacebook?: boolean | null,
  postedToInstagram?: boolean | null,
  postedToTwitter?: boolean | null,
  enabledTiktok?: boolean | null,
  enabledLinkedin?: boolean | null,
  enabledPinterest?: boolean | null,
  enabledYoutube?: boolean | null,
  enabledFacebook?: boolean | null,
  enabledInstagram?: boolean | null,
  enabledTwitter?: boolean | null,
  updatedAt: string,
};

export enum SocialManPostStatus {
  DRAFT = "DRAFT",
  ENQUEUED = "ENQUEUED",
  SCHEDULED = "SCHEDULED",
  EXECUTING = "EXECUTING",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
}


export type SocialManPostTiktokData = {
  title?: string | null,
  caption?: string | null,
  privacy?: SocialManTiktokPrivacyStatus | null,
  usersCanComment?: boolean | null,
  usersCanDuet?: boolean | null,
  usersCanStitch?: boolean | null,
  contentDisclosureEnabled?: boolean | null,
  contentDisclosureYourBrand?: boolean | null,
  contentDisclosureBrandedContent?: boolean | null,
  videoCoverTimestampMs?: number | null,
  postID?: string | null,
};

export enum SocialManTiktokPrivacyStatus {
  PUBLIC_TO_EVERYONE = "PUBLIC_TO_EVERYONE",
  FOLLOWER_OF_CREATOR = "FOLLOWER_OF_CREATOR",
  MUTUAL_FOLLOW_FRIENDS = "MUTUAL_FOLLOW_FRIENDS",
  SELF_ONLY = "SELF_ONLY",
}


export type SocialManPostLinkedinData = {
  caption?: string | null,
  mediaDescription?: string | null,
  postID?: string | null,
};

export type SocialManPostPinterestData = {
  title?: string | null,
  description?: string | null,
  link?: string | null,
  thumbnailURL?: string | null,
  boardID?: string | null,
  pinID?: string | null,
};

export type SocialManPostYoutubeData = {
  title?: string | null,
  description?: string | null,
  tags?: string | null,
  thumbnailURL?: string | null,
  privacy?: string | null,
  category?: string | null,
  targetChannelID?: string | null,
  videoID?: string | null,
};

export type SocialManPostInstagramData = {
  caption: string,
  thumbnail?: string | null,
  targetAccountID?: string | null,
  postToStory?: boolean | null,
  postID?: string | null,
  storyID?: string | null,
};

export type SocialManPostFacebookData = {
  caption: string,
  thumbnail?: string | null,
  targetPageID?: string | null,
  postToStory?: boolean | null,
  postID?: string | null,
  storyID?: string | null,
  storyURL?: string | null,
};

export type SocialManPostTwitterData = {
  caption: string,
  tweetID?: string | null,
};

export type ModelMLTaskExecutionResultConnection = {
  items:  Array<MLTaskExecutionResult | null >,
  nextToken?: string | null,
};

export type ModelMLTasksWorkspaceConnection = {
  items:  Array<MLTasksWorkspace | null >,
  nextToken?: string | null,
};

export type MLTasksWorkspace = {
  id: string,
  name?: string | null,
  description?: string | null,
  userID: string,
  MLTaskExecutionResults?: ModelMLTaskExecutionResultConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelModelInputPresetConnection = {
  items:  Array<ModelInputPreset | null >,
  nextToken?: string | null,
};

export type ModelInputPreset = {
  id: string,
  presetName?: string | null,
  description?: string | null,
  cost?: number | null,
  usage?: number | null,
  isPublic?: boolean | null,
  isOpenSource?: boolean | null,
  entries?:  Array<ModelInputPresetEntry | null > | null,
  versionHistory?:  Array<ModelInputPresetVersionHistory | null > | null,
  versionName?: string | null,
  username?: string | null,
  taskName?: string | null,
  modelName?: string | null,
  modelsID: string,
  userID: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelInputPresetEntry = {
  modelInputName?: string | null,
  modelInputType?: ModelInputOutputEntryType | null,
  presetEntryDatas?:  Array<ModelInputPresetEntryData | null > | null,
};

export type ModelInputPresetEntryData = {
  entryType?: InputPresetEntryType | null,
  parameterName?: string | null,
  val?: string | null,
  description?: string | null,
  presetOrder?: number | null,
  metaData?: InputMetaData | null,
};

export enum InputPresetEntryType {
  PRESET_INPUT = "PRESET_INPUT",
  USER_INPUT = "USER_INPUT",
}


export type ModelInputPresetVersionHistory = {
  versionName?: string | null,
  cost?: number | null,
  entries?:  Array<ModelInputPresetEntry | null > | null,
};

export type UpdateSocialManPostInputNoDS = {
  id: string,
  title?: string | null,
  description?: string | null,
  s3Key?: string | null,
  s3KeyResized?: string | null,
  userID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  postedAt?: string | null,
  scheduledAt?: string | null,
  status?: SocialManPostStatus | null,
  postedToTiktok?: boolean | null,
  postedToLinkedin?: boolean | null,
  postedToPinterest?: boolean | null,
  postedToYoutube?: boolean | null,
  postedToFacebook?: boolean | null,
  postedToInstagram?: boolean | null,
  postedToTwitter?: boolean | null,
  enabledTiktok?: boolean | null,
  enabledLinkedin?: boolean | null,
  enabledPinterest?: boolean | null,
  enabledYoutube?: boolean | null,
  enabledFacebook?: boolean | null,
  enabledInstagram?: boolean | null,
  enabledTwitter?: boolean | null,
};

export type CreateMLTasksWorkspaceInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  userID: string,
  createdAt?: string | null,
};

export type ModelMLTasksWorkspaceConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMLTasksWorkspaceConditionInput | null > | null,
  or?: Array< ModelMLTasksWorkspaceConditionInput | null > | null,
  not?: ModelMLTasksWorkspaceConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateMLTasksWorkspaceInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  userID?: string | null,
  createdAt?: string | null,
};

export type DeleteMLTasksWorkspaceInput = {
  id: string,
};

export type CreateModelInputPresetInput = {
  id?: string | null,
  presetName?: string | null,
  description?: string | null,
  cost?: number | null,
  usage?: number | null,
  isPublic?: boolean | null,
  isOpenSource?: boolean | null,
  entries?: Array< ModelInputPresetEntryInput | null > | null,
  versionHistory?: Array< ModelInputPresetVersionHistoryInput | null > | null,
  versionName?: string | null,
  username?: string | null,
  taskName?: string | null,
  modelName?: string | null,
  modelsID: string,
  userID: string,
};

export type ModelInputPresetEntryInput = {
  modelInputName?: string | null,
  modelInputType?: ModelInputOutputEntryType | null,
  presetEntryDatas?: Array< ModelInputPresetEntryDataInput | null > | null,
};

export type ModelInputPresetEntryDataInput = {
  entryType?: InputPresetEntryType | null,
  parameterName?: string | null,
  val?: string | null,
  description?: string | null,
  presetOrder?: number | null,
  metaData?: InputMetaDataInput | null,
};

export type ModelInputPresetVersionHistoryInput = {
  versionName?: string | null,
  cost?: number | null,
  entries?: Array< ModelInputPresetEntryInput | null > | null,
};

export type ModelModelInputPresetConditionInput = {
  presetName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  cost?: ModelIntInput | null,
  usage?: ModelIntInput | null,
  isPublic?: ModelBooleanInput | null,
  isOpenSource?: ModelBooleanInput | null,
  versionName?: ModelStringInput | null,
  username?: ModelStringInput | null,
  taskName?: ModelStringInput | null,
  modelName?: ModelStringInput | null,
  modelsID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelModelInputPresetConditionInput | null > | null,
  or?: Array< ModelModelInputPresetConditionInput | null > | null,
  not?: ModelModelInputPresetConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateModelInputPresetInput = {
  id: string,
  presetName?: string | null,
  description?: string | null,
  cost?: number | null,
  usage?: number | null,
  isPublic?: boolean | null,
  isOpenSource?: boolean | null,
  entries?: Array< ModelInputPresetEntryInput | null > | null,
  versionHistory?: Array< ModelInputPresetVersionHistoryInput | null > | null,
  versionName?: string | null,
  username?: string | null,
  taskName?: string | null,
  modelName?: string | null,
  modelsID?: string | null,
  userID?: string | null,
};

export type DeleteModelInputPresetInput = {
  id: string,
};

export type CreateTaskReactionInput = {
  userID: string,
  type: TaskReactionType,
  mltaskexecutionresultID: string,
};

export type ModelTaskReactionConditionInput = {
  type?: ModelTaskReactionTypeInput | null,
  mltaskexecutionresultID?: ModelIDInput | null,
  and?: Array< ModelTaskReactionConditionInput | null > | null,
  or?: Array< ModelTaskReactionConditionInput | null > | null,
  not?: ModelTaskReactionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelTaskReactionTypeInput = {
  eq?: TaskReactionType | null,
  ne?: TaskReactionType | null,
};

export type UpdateTaskReactionInput = {
  userID: string,
  type?: TaskReactionType | null,
  mltaskexecutionresultID?: string | null,
};

export type DeleteTaskReactionInput = {
  userID: string,
};

export type CreateMLTaskExecutionResultInput = {
  id?: string | null,
  userID: string,
  HEARTReactionStats?: number | null,
  CUTEReactionStats?: number | null,
  FUNNYReactionStats?: number | null,
  WOWReactionStats?: number | null,
  SADReactionStats?: number | null,
  ANGRYReactionStats?: number | null,
  SCARYReactionStats?: number | null,
  POOPReactionStats?: number | null,
  GROSSReactionStats?: number | null,
  taskName?: string | null,
  modelName?: string | null,
  privacyLevel?: TaskPrivacyLevels | null,
  inputs?: Array< TaskExecutionResultInputInput | null > | null,
  output?: TaskExecutionResultOutputInput | null,
  creditsUsed?: number | null,
  status?: TaskResultStatus | null,
  presetUserInput?: ModelInputPresetInputUserValueInput | null,
  metadata?: TaskExecutionResultMetadataInput | null,
  createdAt?: string | null,
  mltasksworkspaceID: string,
  modelsID: string,
};

export type ModelInputPresetInputUserValueInput = {
  presetID?: string | null,
  presetVersion?: string | null,
  presetName?: string | null,
  presetCreatorName?: string | null,
  presetCreatorUID?: string | null,
  presetValues?: Array< ModelInputPresetEntryInputUserValueInput | null > | null,
};

export type ModelInputPresetEntryInputUserValueInput = {
  modelInputName?: string | null,
  presetEntryDatas?: Array< ModelInputPresetEntryInputUserValueEntryDataInput | null > | null,
};

export type ModelInputPresetEntryInputUserValueEntryDataInput = {
  parameterName?: string | null,
  val?: string | null,
  presetOrder?: number | null,
};

export type TaskExecutionResultMetadataInput = {
  LumaLabsGenerationID?: string | null,
  ReplicatePredictionID?: string | null,
  RunwayMLGenerationID?: string | null,
};

export type ModelMLTaskExecutionResultConditionInput = {
  userID?: ModelIDInput | null,
  HEARTReactionStats?: ModelIntInput | null,
  CUTEReactionStats?: ModelIntInput | null,
  FUNNYReactionStats?: ModelIntInput | null,
  WOWReactionStats?: ModelIntInput | null,
  SADReactionStats?: ModelIntInput | null,
  ANGRYReactionStats?: ModelIntInput | null,
  SCARYReactionStats?: ModelIntInput | null,
  POOPReactionStats?: ModelIntInput | null,
  GROSSReactionStats?: ModelIntInput | null,
  taskName?: ModelStringInput | null,
  modelName?: ModelStringInput | null,
  privacyLevel?: ModelTaskPrivacyLevelsInput | null,
  creditsUsed?: ModelIntInput | null,
  status?: ModelTaskResultStatusInput | null,
  createdAt?: ModelStringInput | null,
  mltasksworkspaceID?: ModelIDInput | null,
  modelsID?: ModelIDInput | null,
  and?: Array< ModelMLTaskExecutionResultConditionInput | null > | null,
  or?: Array< ModelMLTaskExecutionResultConditionInput | null > | null,
  not?: ModelMLTaskExecutionResultConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelTaskPrivacyLevelsInput = {
  eq?: TaskPrivacyLevels | null,
  ne?: TaskPrivacyLevels | null,
};

export type ModelTaskResultStatusInput = {
  eq?: TaskResultStatus | null,
  ne?: TaskResultStatus | null,
};

export type UpdateMLTaskExecutionResultInput = {
  id: string,
  userID?: string | null,
  HEARTReactionStats?: number | null,
  CUTEReactionStats?: number | null,
  FUNNYReactionStats?: number | null,
  WOWReactionStats?: number | null,
  SADReactionStats?: number | null,
  ANGRYReactionStats?: number | null,
  SCARYReactionStats?: number | null,
  POOPReactionStats?: number | null,
  GROSSReactionStats?: number | null,
  taskName?: string | null,
  modelName?: string | null,
  privacyLevel?: TaskPrivacyLevels | null,
  inputs?: Array< TaskExecutionResultInputInput | null > | null,
  output?: TaskExecutionResultOutputInput | null,
  creditsUsed?: number | null,
  status?: TaskResultStatus | null,
  presetUserInput?: ModelInputPresetInputUserValueInput | null,
  metadata?: TaskExecutionResultMetadataInput | null,
  createdAt?: string | null,
  mltasksworkspaceID?: string | null,
  modelsID?: string | null,
};

export type DeleteMLTaskExecutionResultInput = {
  id: string,
};

export type CreateCreditTransactionsHistoryInput = {
  id?: string | null,
  eventID?: string | null,
  userID: string,
  cashAmountInCents?: number | null,
  creditAmount?: number | null,
  status?: CreditTransactionsHistoryStatus | null,
  transactionType?: CreditTransactionsHistoryType | null,
  context?: CreditTransactionsHistoryContextInput | null,
  createdAt?: string | null,
};

export enum CreditTransactionsHistoryStatus {
  PENDING = "PENDING",
  COMPLETED_AFTER_RECONCILIATION = "COMPLETED_AFTER_RECONCILIATION",
  REQUIRES_MANUAL_INTERVENTION = "REQUIRES_MANUAL_INTERVENTION",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  REFUNDED_USER = "REFUNDED_USER",
}


export enum CreditTransactionsHistoryType {
  CREDIT_PURCHASE = "CREDIT_PURCHASE",
  SUBSCRIPTION_CREATE = "SUBSCRIPTION_CREATE",
  SUBSCRIPTION_RENEWED = "SUBSCRIPTION_RENEWED",
  SUBSCRIPTION_CANCEL = "SUBSCRIPTION_CANCEL",
  SUBSCRIPTION_DOWNGRADE = "SUBSCRIPTION_DOWNGRADE",
  SUBSCRIPTION_UPGRADE = "SUBSCRIPTION_UPGRADE",
  SUBSCRIPTION_DELETE = "SUBSCRIPTION_DELETE",
  PRESET_USAGE_CREDIT = "PRESET_USAGE_CREDIT",
  CREDIT_CASHOUT = "CREDIT_CASHOUT",
}


export type CreditTransactionsHistoryContextInput = {
  taskID?: string | null,
  presetID?: string | null,
  presetVersion?: string | null,
  subscriptionID?: string | null,
  priceID?: string | null,
  transactionID?: string | null,
  feedback?: string | null,
  comment?: string | null,
  reason?: string | null,
};

export type ModelCreditTransactionsHistoryConditionInput = {
  eventID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  cashAmountInCents?: ModelIntInput | null,
  creditAmount?: ModelIntInput | null,
  status?: ModelCreditTransactionsHistoryStatusInput | null,
  transactionType?: ModelCreditTransactionsHistoryTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCreditTransactionsHistoryConditionInput | null > | null,
  or?: Array< ModelCreditTransactionsHistoryConditionInput | null > | null,
  not?: ModelCreditTransactionsHistoryConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelCreditTransactionsHistoryStatusInput = {
  eq?: CreditTransactionsHistoryStatus | null,
  ne?: CreditTransactionsHistoryStatus | null,
};

export type ModelCreditTransactionsHistoryTypeInput = {
  eq?: CreditTransactionsHistoryType | null,
  ne?: CreditTransactionsHistoryType | null,
};

export type CreditTransactionsHistory = {
  id: string,
  eventID?: string | null,
  userID: string,
  cashAmountInCents?: number | null,
  creditAmount?: number | null,
  status?: CreditTransactionsHistoryStatus | null,
  transactionType?: CreditTransactionsHistoryType | null,
  context?: CreditTransactionsHistoryContext | null,
  createdAt: string,
  updatedAt: string,
};

export type CreditTransactionsHistoryContext = {
  taskID?: string | null,
  presetID?: string | null,
  presetVersion?: string | null,
  subscriptionID?: string | null,
  priceID?: string | null,
  transactionID?: string | null,
  feedback?: string | null,
  comment?: string | null,
  reason?: string | null,
};

export type UpdateCreditTransactionsHistoryInput = {
  id: string,
  eventID?: string | null,
  userID?: string | null,
  cashAmountInCents?: number | null,
  creditAmount?: number | null,
  status?: CreditTransactionsHistoryStatus | null,
  transactionType?: CreditTransactionsHistoryType | null,
  context?: CreditTransactionsHistoryContextInput | null,
  createdAt?: string | null,
};

export type DeleteCreditTransactionsHistoryInput = {
  id: string,
};

export type CreateModelsInput = {
  id?: string | null,
  isPublic: boolean,
  name?: string | null,
  usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
  inputs?: ModelInputInput | null,
  outputs?: ModelOutputInput | null,
  mltaskdataID: string,
};

export enum ThirdPartyAPIKeysNames {
  ANTHROPIC = "ANTHROPIC",
  ELEVEN_LABS = "ELEVEN_LABS",
  FAL = "FAL",
  LUMA_LABS = "LUMA_LABS",
  NIM = "NIM",
  OPEN_AI = "OPEN_AI",
  REPLICATE = "REPLICATE",
  RUNWAY_ML = "RUNWAY_ML",
  STABILITY_AI = "STABILITY_AI",
}


export type ModelInputInput = {
  entries?: Array< ModelInputEntryInput | null > | null,
};

export type ModelInputEntryInput = {
  type: ModelInputOutputEntryType,
  cost?: ModelInputEntryCostInput | null,
  costWithAPIKey?: ModelInputEntryCostInput | null,
  displayName?: string | null,
  backendName?: string | null,
  defaultValue?: string | null,
  options?: Array< ModelInputOptionsDataInput | null > | null,
  optionType?: OptionType | null,
  optionStep?: number | null,
  description?: string | null,
  examples?: Array< string | null > | null,
  constraints?: Array< ModelInputConstraintInput | null > | null,
  isRequired?: boolean | null,
};

export type ModelInputEntryCostInput = {
  tokenizerModel?: TokenizerModel | null,
  costPerToken?: number | null,
  costPerValue?: number | null,
  costPerCharacter?: number | null,
  costPerWord?: number | null,
  costInterpolateMin?: number | null,
  costInterpolateMax?: number | null,
  costPerFileSize?: number | null,
  costPerFileDuration?: number | null,
  flatFee?: number | null,
  isMultiplier?: boolean | null,
};

export enum TokenizerModel {
  davinci_002 = "davinci_002",
  babbage_002 = "babbage_002",
  text_davinci_003 = "text_davinci_003",
  text_davinci_002 = "text_davinci_002",
  text_davinci_001 = "text_davinci_001",
  text_curie_001 = "text_curie_001",
  text_babbage_001 = "text_babbage_001",
  text_ada_001 = "text_ada_001",
  davinci = "davinci",
  curie = "curie",
  babbage = "babbage",
  ada = "ada",
  code_davinci_002 = "code_davinci_002",
  code_davinci_001 = "code_davinci_001",
  code_cushman_002 = "code_cushman_002",
  code_cushman_001 = "code_cushman_001",
  davinci_codex = "davinci_codex",
  cushman_codex = "cushman_codex",
  text_davinci_edit_001 = "text_davinci_edit_001",
  code_davinci_edit_001 = "code_davinci_edit_001",
  text_embedding_ada_002 = "text_embedding_ada_002",
  text_embedding_3_small = "text_embedding_3_small",
  text_embedding_3_large = "text_embedding_3_large",
  text_similarity_davinci_001 = "text_similarity_davinci_001",
  text_similarity_curie_001 = "text_similarity_curie_001",
  text_similarity_babbage_001 = "text_similarity_babbage_001",
  text_similarity_ada_001 = "text_similarity_ada_001",
  text_search_davinci_doc_001 = "text_search_davinci_doc_001",
  text_search_curie_doc_001 = "text_search_curie_doc_001",
  text_search_babbage_doc_001 = "text_search_babbage_doc_001",
  text_search_ada_doc_001 = "text_search_ada_doc_001",
  code_search_babbage_code_001 = "code_search_babbage_code_001",
  code_search_ada_code_001 = "code_search_ada_code_001",
  gpt2 = "gpt2",
  gpt_3__5_turbo = "gpt_3__5_turbo",
  gpt_35_turbo = "gpt_35_turbo",
  gpt_3__5_turbo_0301 = "gpt_3__5_turbo_0301",
  gpt_3__5_turbo_0613 = "gpt_3__5_turbo_0613",
  gpt_3__5_turbo_1106 = "gpt_3__5_turbo_1106",
  gpt_3__5_turbo_0125 = "gpt_3__5_turbo_0125",
  gpt_3__5_turbo_16k = "gpt_3__5_turbo_16k",
  gpt_3__5_turbo_16k_0613 = "gpt_3__5_turbo_16k_0613",
  gpt_3__5_turbo_instruct = "gpt_3__5_turbo_instruct",
  gpt_3__5_turbo_instruct_0914 = "gpt_3__5_turbo_instruct_0914",
  gpt_4 = "gpt_4",
  gpt_4_0314 = "gpt_4_0314",
  gpt_4_0613 = "gpt_4_0613",
  gpt_4_32k = "gpt_4_32k",
  gpt_4_32k_0314 = "gpt_4_32k_0314",
  gpt_4_32k_0613 = "gpt_4_32k_0613",
  gpt_4_turbo = "gpt_4_turbo",
  gpt_4_turbo_2024_04_09 = "gpt_4_turbo_2024_04_09",
  gpt_4_turbo_preview = "gpt_4_turbo_preview",
  gpt_4_1106_preview = "gpt_4_1106_preview",
  gpt_4_0125_preview = "gpt_4_0125_preview",
  gpt_4_vision_preview = "gpt_4_vision_preview",
  gpt_4o = "gpt_4o",
  gpt_4o_2024_05_13 = "gpt_4o_2024_05_13",
  gpt_4o_2024_08_06 = "gpt_4o_2024_08_06",
  gpt_4o_mini_2024_07_18 = "gpt_4o_mini_2024_07_18",
  gpt_4o_mini = "gpt_4o_mini",
}


export type ModelInputOptionsDataInput = {
  name?: string | null,
  value?: string | null,
  description?: string | null,
  cost?: number | null,
  costWithAPIKey?: number | null,
};

export enum OptionType {
  TOGGLE_GROUP_SINGLE = "TOGGLE_GROUP_SINGLE",
  DROP_DOWN = "DROP_DOWN",
  STRICT_SLIDER = "STRICT_SLIDER",
  RANGE = "RANGE",
}


export type ModelInputConstraintInput = {
  type?: ModelInputConstraintType | null,
  value?: string | null,
};

export enum ModelInputConstraintType {
  MAX_STRING_LENGTH = "MAX_STRING_LENGTH",
  MAX_TOKENS = "MAX_TOKENS",
  REGEX = "REGEX",
  MAX_VALUE = "MAX_VALUE",
  MIN_VALUE = "MIN_VALUE",
  IMAGE_RATIO = "IMAGE_RATIO",
  IMAGE_WIDTH = "IMAGE_WIDTH",
  IMAGE_HEIGHT = "IMAGE_HEIGHT",
  MAX_FILE_SIZE_MB = "MAX_FILE_SIZE_MB",
}


export type ModelOutputInput = {
  entries?: Array< ModelOutputEntryInput | null > | null,
};

export type ModelOutputEntryInput = {
  type?: ModelInputOutputEntryType | null,
  name?: string | null,
};

export type ModelModelsConditionInput = {
  isPublic?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  usesAPIKeys?: ModelThirdPartyAPIKeysNamesListInput | null,
  mltaskdataID?: ModelIDInput | null,
  and?: Array< ModelModelsConditionInput | null > | null,
  or?: Array< ModelModelsConditionInput | null > | null,
  not?: ModelModelsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelThirdPartyAPIKeysNamesListInput = {
  eq?: Array< ThirdPartyAPIKeysNames | null > | null,
  ne?: Array< ThirdPartyAPIKeysNames | null > | null,
  contains?: ThirdPartyAPIKeysNames | null,
  notContains?: ThirdPartyAPIKeysNames | null,
};

export type Models = {
  id: string,
  isPublic: boolean,
  name?: string | null,
  usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
  inputs?: ModelInput | null,
  outputs?: ModelOutput | null,
  mltaskdataID: string,
  ModelInputPresets?: ModelModelInputPresetConnection | null,
  MLTaskExecutionResults?: ModelMLTaskExecutionResultConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelInput = {
  entries?:  Array<ModelInputEntry | null > | null,
};

export type ModelInputEntry = {
  type: ModelInputOutputEntryType,
  cost?: ModelInputEntryCost | null,
  costWithAPIKey?: ModelInputEntryCost | null,
  displayName?: string | null,
  backendName?: string | null,
  defaultValue?: string | null,
  options?:  Array<ModelInputOptionsData | null > | null,
  optionType?: OptionType | null,
  optionStep?: number | null,
  description?: string | null,
  examples?: Array< string | null > | null,
  constraints?:  Array<ModelInputConstraint | null > | null,
  isRequired?: boolean | null,
};

export type ModelInputEntryCost = {
  tokenizerModel?: TokenizerModel | null,
  costPerToken?: number | null,
  costPerValue?: number | null,
  costPerCharacter?: number | null,
  costPerWord?: number | null,
  costInterpolateMin?: number | null,
  costInterpolateMax?: number | null,
  costPerFileSize?: number | null,
  costPerFileDuration?: number | null,
  flatFee?: number | null,
  isMultiplier?: boolean | null,
};

export type ModelInputOptionsData = {
  name?: string | null,
  value?: string | null,
  description?: string | null,
  cost?: number | null,
  costWithAPIKey?: number | null,
};

export type ModelInputConstraint = {
  type?: ModelInputConstraintType | null,
  value?: string | null,
};

export type ModelOutput = {
  entries?:  Array<ModelOutputEntry | null > | null,
};

export type ModelOutputEntry = {
  type?: ModelInputOutputEntryType | null,
  name?: string | null,
};

export type UpdateModelsInput = {
  id: string,
  isPublic?: boolean | null,
  name?: string | null,
  usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
  inputs?: ModelInputInput | null,
  outputs?: ModelOutputInput | null,
  mltaskdataID?: string | null,
};

export type DeleteModelsInput = {
  id: string,
};

export type CreateMLTaskDataInput = {
  id?: string | null,
  name?: string | null,
  category?: string | null,
  isPublic: boolean,
};

export type ModelMLTaskDataConditionInput = {
  name?: ModelStringInput | null,
  category?: ModelStringInput | null,
  isPublic?: ModelBooleanInput | null,
  and?: Array< ModelMLTaskDataConditionInput | null > | null,
  or?: Array< ModelMLTaskDataConditionInput | null > | null,
  not?: ModelMLTaskDataConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type MLTaskData = {
  id: string,
  name?: string | null,
  category?: string | null,
  isPublic: boolean,
  Models?: ModelModelsConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelModelsConnection = {
  items:  Array<Models | null >,
  nextToken?: string | null,
};

export type UpdateMLTaskDataInput = {
  id: string,
  name?: string | null,
  category?: string | null,
  isPublic?: boolean | null,
};

export type DeleteMLTaskDataInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  name?: string | null,
  username?: string | null,
  anthropicAPIKey?: string | null,
  elevenLabsAPIKey?: string | null,
  FalAPIKey?: string | null,
  lumaLabsAPIKey?: string | null,
  nimsAPIKey?: string | null,
  openAIAPIKey?: string | null,
  replicateAPIKey?: string | null,
  runwayMLAPIKey?: string | null,
  SAIAPIKey?: string | null,
  currentSubscriptionPlan?: SubscriptionPlan | null,
  periodEndOfLastSubscriptionCredits?: number | null,
  createdPresetCount?: number | null,
  completedTasksCount?: number | null,
  stripeCustomerID?: string | null,
  stripeConnectAccountID?: string | null,
  stripeConnectAccountSetupComplete?: boolean | null,
  email?: string | null,
  picture?: string | null,
  credits?: number | null,
  redeemableCredits?: number | null,
  tiktok?: TiktokDataInput | null,
  pinterest?: PinterestDataInput | null,
  linkedin?: LinkedinDataInput | null,
  google?: GoogleDataInput | null,
  twitter?: TwitterDataInput | null,
  facebook?: FacebookDataInput | null,
};

export type TiktokDataInput = {
  tokenData: TiktokTokenDataInput,
  userData: TiktokUserDataInput,
};

export type TiktokTokenDataInput = {
  accessToken: string,
  accessTokenExpirationDate: number,
  refreshToken: string,
  scope: string,
  refreshTokenExpirationDate: number,
};

export type TiktokUserDataInput = {
  avatarURL?: string | null,
  avatarURL100?: string | null,
  avatarLargeURL?: string | null,
  displayName?: string | null,
  bioDescription?: string | null,
  profileDeepLink?: string | null,
  isVerified?: boolean | null,
  username?: string | null,
  followerCount?: number | null,
  followingCount?: number | null,
  likesCount?: number | null,
  videoCount?: number | null,
  privacyLevelOptions?: Array< string | null > | null,
  commentDisabled?: boolean | null,
  duetDisabled?: boolean | null,
  stitchDisabled?: boolean | null,
  maxVideoPostDuration_sec?: number | null,
};

export type PinterestDataInput = {
  tokenData: PinterestTokenDataInput,
  userData: PinterestUserDataInput,
  boardsData?: Array< PinterestUserBoardDataInput | null > | null,
};

export type PinterestTokenDataInput = {
  accessToken: string,
  accessTokenExpirationDate: number,
  refreshToken: string,
  scope: string,
  refreshTokenExpirationDate: number,
};

export type PinterestUserDataInput = {
  accountType?: string | null,
  id?: string | null,
  profileImage?: string | null,
  websiteUrl?: string | null,
  username?: string | null,
  about?: string | null,
  businessName?: string | null,
  boardCount?: number | null,
  pinCount?: number | null,
  followerCount?: number | null,
  followingCount?: number | null,
  monthlyViews?: number | null,
};

export type PinterestUserBoardDataInput = {
  id?: string | null,
  name?: string | null,
  pinCount?: number | null,
  followerCount?: number | null,
  imageCoverUrl?: string | null,
  createdAt?: string | null,
  description?: string | null,
  privacy?: string | null,
};

export type LinkedinDataInput = {
  tokenData: LinkedinTokenDataInput,
  userData: LinkedinUserDataInput,
};

export type LinkedinTokenDataInput = {
  accessToken: string,
  accessTokenExpirationDate: number,
  scope: string,
};

export type LinkedinUserDataInput = {
  email?: string | null,
  name?: string | null,
  picture?: string | null,
  sub?: string | null,
};

export type GoogleDataInput = {
  tokenData: GoogleTokenDataInput,
  userData: GoogleUserDataInput,
  channelsData: Array< YoutubeChannelDataInput | null >,
};

export type GoogleTokenDataInput = {
  accessToken?: string | null,
  refreshToken?: string | null,
  scope?: string | null,
  tokenType?: string | null,
  idToken?: string | null,
  expiresOn?: number | null,
};

export type GoogleUserDataInput = {
  id?: string | null,
  email?: string | null,
  verifiedEmail?: boolean | null,
  name?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  picture?: string | null,
  locale?: string | null,
};

export type YoutubeChannelDataInput = {
  id?: string | null,
  customUrl?: string | null,
  title?: string | null,
  description?: string | null,
  thumbnail?: string | null,
  longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
};

export type TwitterDataInput = {
  tokenData: TwitterTokenDataInput,
  userData: TwitterUserDataInput,
};

export type TwitterTokenDataInput = {
  accessToken?: string | null,
  accessTokenSecret?: string | null,
  refreshToken?: string | null,
  codeVerifier?: string | null,
  oauthTokenSecret?: string | null,
  expiresOn?: number | null,
};

export type TwitterUserDataInput = {
  id?: string | null,
  name?: string | null,
  username?: string | null,
  description?: string | null,
  verifiedType?: TwitterVerifiedType | null,
  profilePictureURL?: string | null,
};

export type FacebookDataInput = {
  userData: FacebookUserDataInput,
  pagesData: Array< FacebookPageDataInput | null >,
  instagramsData: Array< InstagramUserDataInput | null >,
};

export type FacebookUserDataInput = {
  id?: string | null,
  name?: string | null,
  picture?: string | null,
  accessToken?: string | null,
  expiresOn?: number | null,
};

export type FacebookPageDataInput = {
  id?: string | null,
  name?: string | null,
  category?: string | null,
  picture?: string | null,
  accessToken?: string | null,
  expiresOn?: number | null,
};

export type InstagramUserDataInput = {
  connectedPageID?: string | null,
  id?: string | null,
  igID?: string | null,
  biography?: string | null,
  name?: string | null,
  username?: string | null,
  profilePictureURL?: string | null,
  followsCount?: number | null,
  followersCount?: number | null,
  mediaCount?: number | null,
  accessToken?: string | null,
  expiresOn?: number | null,
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null,
  username?: ModelStringInput | null,
  anthropicAPIKey?: ModelStringInput | null,
  elevenLabsAPIKey?: ModelStringInput | null,
  FalAPIKey?: ModelStringInput | null,
  lumaLabsAPIKey?: ModelStringInput | null,
  nimsAPIKey?: ModelStringInput | null,
  openAIAPIKey?: ModelStringInput | null,
  replicateAPIKey?: ModelStringInput | null,
  runwayMLAPIKey?: ModelStringInput | null,
  SAIAPIKey?: ModelStringInput | null,
  currentSubscriptionPlan?: ModelSubscriptionPlanInput | null,
  periodEndOfLastSubscriptionCredits?: ModelIntInput | null,
  createdPresetCount?: ModelIntInput | null,
  completedTasksCount?: ModelIntInput | null,
  stripeCustomerID?: ModelStringInput | null,
  stripeConnectAccountID?: ModelStringInput | null,
  stripeConnectAccountSetupComplete?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  credits?: ModelIntInput | null,
  redeemableCredits?: ModelIntInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelStringInput | null,
};

export type ModelSubscriptionPlanInput = {
  eq?: SubscriptionPlan | null,
  ne?: SubscriptionPlan | null,
};

export type UpdateUserInput = {
  id: string,
  name?: string | null,
  username?: string | null,
  anthropicAPIKey?: string | null,
  elevenLabsAPIKey?: string | null,
  FalAPIKey?: string | null,
  lumaLabsAPIKey?: string | null,
  nimsAPIKey?: string | null,
  openAIAPIKey?: string | null,
  replicateAPIKey?: string | null,
  runwayMLAPIKey?: string | null,
  SAIAPIKey?: string | null,
  currentSubscriptionPlan?: SubscriptionPlan | null,
  periodEndOfLastSubscriptionCredits?: number | null,
  createdPresetCount?: number | null,
  completedTasksCount?: number | null,
  stripeCustomerID?: string | null,
  stripeConnectAccountID?: string | null,
  stripeConnectAccountSetupComplete?: boolean | null,
  email?: string | null,
  picture?: string | null,
  credits?: number | null,
  redeemableCredits?: number | null,
  tiktok?: TiktokDataInput | null,
  pinterest?: PinterestDataInput | null,
  linkedin?: LinkedinDataInput | null,
  google?: GoogleDataInput | null,
  twitter?: TwitterDataInput | null,
  facebook?: FacebookDataInput | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateSocialManPostInput = {
  id?: string | null,
  title?: string | null,
  description?: string | null,
  s3Key?: string | null,
  s3KeyResized?: string | null,
  userID: string,
  createdAt?: string | null,
  scheduledAt?: string | null,
  postedAt?: string | null,
  status: SocialManPostStatus,
  tiktok?: SocialManPostTiktokDataInput | null,
  linkedin?: SocialManPostLinkedinDataInput | null,
  pinterest?: SocialManPostPinterestDataInput | null,
  youtube?: SocialManPostYoutubeDataInput | null,
  instagram?: SocialManPostInstagramDataInput | null,
  facebook?: SocialManPostFacebookDataInput | null,
  twitter?: SocialManPostTwitterDataInput | null,
  postedToTiktok?: boolean | null,
  postedToLinkedin?: boolean | null,
  postedToPinterest?: boolean | null,
  postedToYoutube?: boolean | null,
  postedToFacebook?: boolean | null,
  postedToInstagram?: boolean | null,
  postedToTwitter?: boolean | null,
  enabledTiktok?: boolean | null,
  enabledLinkedin?: boolean | null,
  enabledPinterest?: boolean | null,
  enabledYoutube?: boolean | null,
  enabledFacebook?: boolean | null,
  enabledInstagram?: boolean | null,
  enabledTwitter?: boolean | null,
};

export type SocialManPostTiktokDataInput = {
  title?: string | null,
  caption?: string | null,
  privacy?: SocialManTiktokPrivacyStatus | null,
  usersCanComment?: boolean | null,
  usersCanDuet?: boolean | null,
  usersCanStitch?: boolean | null,
  contentDisclosureEnabled?: boolean | null,
  contentDisclosureYourBrand?: boolean | null,
  contentDisclosureBrandedContent?: boolean | null,
  videoCoverTimestampMs?: number | null,
  postID?: string | null,
};

export type SocialManPostLinkedinDataInput = {
  caption?: string | null,
  mediaDescription?: string | null,
  postID?: string | null,
};

export type SocialManPostPinterestDataInput = {
  title?: string | null,
  description?: string | null,
  link?: string | null,
  thumbnailURL?: string | null,
  boardID?: string | null,
  pinID?: string | null,
};

export type SocialManPostYoutubeDataInput = {
  title?: string | null,
  description?: string | null,
  tags?: string | null,
  thumbnailURL?: string | null,
  privacy?: string | null,
  category?: string | null,
  targetChannelID?: string | null,
  videoID?: string | null,
};

export type SocialManPostInstagramDataInput = {
  caption: string,
  thumbnail?: string | null,
  targetAccountID?: string | null,
  postToStory?: boolean | null,
  postID?: string | null,
  storyID?: string | null,
};

export type SocialManPostFacebookDataInput = {
  caption: string,
  thumbnail?: string | null,
  targetPageID?: string | null,
  postToStory?: boolean | null,
  postID?: string | null,
  storyID?: string | null,
  storyURL?: string | null,
};

export type SocialManPostTwitterDataInput = {
  caption: string,
  tweetID?: string | null,
};

export type ModelSocialManPostConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  s3Key?: ModelStringInput | null,
  s3KeyResized?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  scheduledAt?: ModelStringInput | null,
  postedAt?: ModelStringInput | null,
  status?: ModelSocialManPostStatusInput | null,
  postedToTiktok?: ModelBooleanInput | null,
  postedToLinkedin?: ModelBooleanInput | null,
  postedToPinterest?: ModelBooleanInput | null,
  postedToYoutube?: ModelBooleanInput | null,
  postedToFacebook?: ModelBooleanInput | null,
  postedToInstagram?: ModelBooleanInput | null,
  postedToTwitter?: ModelBooleanInput | null,
  enabledTiktok?: ModelBooleanInput | null,
  enabledLinkedin?: ModelBooleanInput | null,
  enabledPinterest?: ModelBooleanInput | null,
  enabledYoutube?: ModelBooleanInput | null,
  enabledFacebook?: ModelBooleanInput | null,
  enabledInstagram?: ModelBooleanInput | null,
  enabledTwitter?: ModelBooleanInput | null,
  and?: Array< ModelSocialManPostConditionInput | null > | null,
  or?: Array< ModelSocialManPostConditionInput | null > | null,
  not?: ModelSocialManPostConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelSocialManPostStatusInput = {
  eq?: SocialManPostStatus | null,
  ne?: SocialManPostStatus | null,
};

export type UpdateSocialManPostInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  s3Key?: string | null,
  s3KeyResized?: string | null,
  userID?: string | null,
  createdAt?: string | null,
  scheduledAt?: string | null,
  postedAt?: string | null,
  status?: SocialManPostStatus | null,
  tiktok?: SocialManPostTiktokDataInput | null,
  linkedin?: SocialManPostLinkedinDataInput | null,
  pinterest?: SocialManPostPinterestDataInput | null,
  youtube?: SocialManPostYoutubeDataInput | null,
  instagram?: SocialManPostInstagramDataInput | null,
  facebook?: SocialManPostFacebookDataInput | null,
  twitter?: SocialManPostTwitterDataInput | null,
  postedToTiktok?: boolean | null,
  postedToLinkedin?: boolean | null,
  postedToPinterest?: boolean | null,
  postedToYoutube?: boolean | null,
  postedToFacebook?: boolean | null,
  postedToInstagram?: boolean | null,
  postedToTwitter?: boolean | null,
  enabledTiktok?: boolean | null,
  enabledLinkedin?: boolean | null,
  enabledPinterest?: boolean | null,
  enabledYoutube?: boolean | null,
  enabledFacebook?: boolean | null,
  enabledInstagram?: boolean | null,
  enabledTwitter?: boolean | null,
};

export type DeleteSocialManPostInput = {
  id: string,
};

export type ModelMLTasksWorkspaceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMLTasksWorkspaceFilterInput | null > | null,
  or?: Array< ModelMLTasksWorkspaceFilterInput | null > | null,
  not?: ModelMLTasksWorkspaceFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelModelInputPresetFilterInput = {
  id?: ModelIDInput | null,
  presetName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  cost?: ModelIntInput | null,
  usage?: ModelIntInput | null,
  isPublic?: ModelBooleanInput | null,
  isOpenSource?: ModelBooleanInput | null,
  versionName?: ModelStringInput | null,
  username?: ModelStringInput | null,
  taskName?: ModelStringInput | null,
  modelName?: ModelStringInput | null,
  modelsID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelModelInputPresetFilterInput | null > | null,
  or?: Array< ModelModelInputPresetFilterInput | null > | null,
  not?: ModelModelInputPresetFilterInput | null,
};

export type ModelTaskReactionFilterInput = {
  userID?: ModelIDInput | null,
  type?: ModelTaskReactionTypeInput | null,
  mltaskexecutionresultID?: ModelIDInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTaskReactionFilterInput | null > | null,
  or?: Array< ModelTaskReactionFilterInput | null > | null,
  not?: ModelTaskReactionFilterInput | null,
};

export type ModelMLTaskExecutionResultFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  HEARTReactionStats?: ModelIntInput | null,
  CUTEReactionStats?: ModelIntInput | null,
  FUNNYReactionStats?: ModelIntInput | null,
  WOWReactionStats?: ModelIntInput | null,
  SADReactionStats?: ModelIntInput | null,
  ANGRYReactionStats?: ModelIntInput | null,
  SCARYReactionStats?: ModelIntInput | null,
  POOPReactionStats?: ModelIntInput | null,
  GROSSReactionStats?: ModelIntInput | null,
  taskName?: ModelStringInput | null,
  modelName?: ModelStringInput | null,
  privacyLevel?: ModelTaskPrivacyLevelsInput | null,
  creditsUsed?: ModelIntInput | null,
  status?: ModelTaskResultStatusInput | null,
  createdAt?: ModelStringInput | null,
  mltasksworkspaceID?: ModelIDInput | null,
  modelsID?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMLTaskExecutionResultFilterInput | null > | null,
  or?: Array< ModelMLTaskExecutionResultFilterInput | null > | null,
  not?: ModelMLTaskExecutionResultFilterInput | null,
};

export type ModelCreditTransactionsHistoryFilterInput = {
  id?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  cashAmountInCents?: ModelIntInput | null,
  creditAmount?: ModelIntInput | null,
  status?: ModelCreditTransactionsHistoryStatusInput | null,
  transactionType?: ModelCreditTransactionsHistoryTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCreditTransactionsHistoryFilterInput | null > | null,
  or?: Array< ModelCreditTransactionsHistoryFilterInput | null > | null,
  not?: ModelCreditTransactionsHistoryFilterInput | null,
};

export type ModelCreditTransactionsHistoryConnection = {
  items:  Array<CreditTransactionsHistory | null >,
  nextToken?: string | null,
};

export type ModelModelsFilterInput = {
  id?: ModelIDInput | null,
  isPublic?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  usesAPIKeys?: ModelThirdPartyAPIKeysNamesListInput | null,
  mltaskdataID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelModelsFilterInput | null > | null,
  or?: Array< ModelModelsFilterInput | null > | null,
  not?: ModelModelsFilterInput | null,
};

export type ModelMLTaskDataFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  category?: ModelStringInput | null,
  isPublic?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMLTaskDataFilterInput | null > | null,
  or?: Array< ModelMLTaskDataFilterInput | null > | null,
  not?: ModelMLTaskDataFilterInput | null,
};

export type ModelMLTaskDataConnection = {
  items:  Array<MLTaskData | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  username?: ModelStringInput | null,
  anthropicAPIKey?: ModelStringInput | null,
  elevenLabsAPIKey?: ModelStringInput | null,
  FalAPIKey?: ModelStringInput | null,
  lumaLabsAPIKey?: ModelStringInput | null,
  nimsAPIKey?: ModelStringInput | null,
  openAIAPIKey?: ModelStringInput | null,
  replicateAPIKey?: ModelStringInput | null,
  runwayMLAPIKey?: ModelStringInput | null,
  SAIAPIKey?: ModelStringInput | null,
  currentSubscriptionPlan?: ModelSubscriptionPlanInput | null,
  periodEndOfLastSubscriptionCredits?: ModelIntInput | null,
  createdPresetCount?: ModelIntInput | null,
  completedTasksCount?: ModelIntInput | null,
  stripeCustomerID?: ModelStringInput | null,
  stripeConnectAccountID?: ModelStringInput | null,
  stripeConnectAccountSetupComplete?: ModelBooleanInput | null,
  email?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  credits?: ModelIntInput | null,
  redeemableCredits?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelSocialManPostFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  s3Key?: ModelStringInput | null,
  s3KeyResized?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  scheduledAt?: ModelStringInput | null,
  postedAt?: ModelStringInput | null,
  status?: ModelSocialManPostStatusInput | null,
  postedToTiktok?: ModelBooleanInput | null,
  postedToLinkedin?: ModelBooleanInput | null,
  postedToPinterest?: ModelBooleanInput | null,
  postedToYoutube?: ModelBooleanInput | null,
  postedToFacebook?: ModelBooleanInput | null,
  postedToInstagram?: ModelBooleanInput | null,
  postedToTwitter?: ModelBooleanInput | null,
  enabledTiktok?: ModelBooleanInput | null,
  enabledLinkedin?: ModelBooleanInput | null,
  enabledPinterest?: ModelBooleanInput | null,
  enabledYoutube?: ModelBooleanInput | null,
  enabledFacebook?: ModelBooleanInput | null,
  enabledInstagram?: ModelBooleanInput | null,
  enabledTwitter?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSocialManPostFilterInput | null > | null,
  or?: Array< ModelSocialManPostFilterInput | null > | null,
  not?: ModelSocialManPostFilterInput | null,
};

export type ModelSubscriptionMLTasksWorkspaceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMLTasksWorkspaceFilterInput | null > | null,
  or?: Array< ModelSubscriptionMLTasksWorkspaceFilterInput | null > | null,
  userID?: ModelStringInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionModelInputPresetFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  presetName?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  cost?: ModelSubscriptionIntInput | null,
  usage?: ModelSubscriptionIntInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  isOpenSource?: ModelSubscriptionBooleanInput | null,
  versionName?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  taskName?: ModelSubscriptionStringInput | null,
  modelName?: ModelSubscriptionStringInput | null,
  modelsID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionModelInputPresetFilterInput | null > | null,
  or?: Array< ModelSubscriptionModelInputPresetFilterInput | null > | null,
  userID?: ModelStringInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionTaskReactionFilterInput = {
  userID?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  mltaskexecutionresultID?: ModelSubscriptionIDInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTaskReactionFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaskReactionFilterInput | null > | null,
};

export type ModelSubscriptionMLTaskExecutionResultFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  HEARTReactionStats?: ModelSubscriptionIntInput | null,
  CUTEReactionStats?: ModelSubscriptionIntInput | null,
  FUNNYReactionStats?: ModelSubscriptionIntInput | null,
  WOWReactionStats?: ModelSubscriptionIntInput | null,
  SADReactionStats?: ModelSubscriptionIntInput | null,
  ANGRYReactionStats?: ModelSubscriptionIntInput | null,
  SCARYReactionStats?: ModelSubscriptionIntInput | null,
  POOPReactionStats?: ModelSubscriptionIntInput | null,
  GROSSReactionStats?: ModelSubscriptionIntInput | null,
  taskName?: ModelSubscriptionStringInput | null,
  modelName?: ModelSubscriptionStringInput | null,
  privacyLevel?: ModelSubscriptionStringInput | null,
  creditsUsed?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  mltasksworkspaceID?: ModelSubscriptionIDInput | null,
  modelsID?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMLTaskExecutionResultFilterInput | null > | null,
  or?: Array< ModelSubscriptionMLTaskExecutionResultFilterInput | null > | null,
  userID?: ModelStringInput | null,
};

export type ModelSubscriptionCreditTransactionsHistoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  eventID?: ModelSubscriptionIDInput | null,
  cashAmountInCents?: ModelSubscriptionIntInput | null,
  creditAmount?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  transactionType?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCreditTransactionsHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCreditTransactionsHistoryFilterInput | null > | null,
  userID?: ModelStringInput | null,
};

export type ModelSubscriptionModelsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  usesAPIKeys?: ModelSubscriptionStringInput | null,
  mltaskdataID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionModelsFilterInput | null > | null,
  or?: Array< ModelSubscriptionModelsFilterInput | null > | null,
};

export type ModelSubscriptionMLTaskDataFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMLTaskDataFilterInput | null > | null,
  or?: Array< ModelSubscriptionMLTaskDataFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  anthropicAPIKey?: ModelSubscriptionStringInput | null,
  elevenLabsAPIKey?: ModelSubscriptionStringInput | null,
  FalAPIKey?: ModelSubscriptionStringInput | null,
  lumaLabsAPIKey?: ModelSubscriptionStringInput | null,
  nimsAPIKey?: ModelSubscriptionStringInput | null,
  openAIAPIKey?: ModelSubscriptionStringInput | null,
  replicateAPIKey?: ModelSubscriptionStringInput | null,
  runwayMLAPIKey?: ModelSubscriptionStringInput | null,
  SAIAPIKey?: ModelSubscriptionStringInput | null,
  currentSubscriptionPlan?: ModelSubscriptionStringInput | null,
  periodEndOfLastSubscriptionCredits?: ModelSubscriptionIntInput | null,
  createdPresetCount?: ModelSubscriptionIntInput | null,
  completedTasksCount?: ModelSubscriptionIntInput | null,
  stripeCustomerID?: ModelSubscriptionStringInput | null,
  stripeConnectAccountID?: ModelSubscriptionStringInput | null,
  stripeConnectAccountSetupComplete?: ModelSubscriptionBooleanInput | null,
  email?: ModelSubscriptionStringInput | null,
  picture?: ModelSubscriptionStringInput | null,
  credits?: ModelSubscriptionIntInput | null,
  redeemableCredits?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  id?: ModelStringInput | null,
};

export type ModelSubscriptionSocialManPostFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  s3Key?: ModelSubscriptionStringInput | null,
  s3KeyResized?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  scheduledAt?: ModelSubscriptionStringInput | null,
  postedAt?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  postedToTiktok?: ModelSubscriptionBooleanInput | null,
  postedToLinkedin?: ModelSubscriptionBooleanInput | null,
  postedToPinterest?: ModelSubscriptionBooleanInput | null,
  postedToYoutube?: ModelSubscriptionBooleanInput | null,
  postedToFacebook?: ModelSubscriptionBooleanInput | null,
  postedToInstagram?: ModelSubscriptionBooleanInput | null,
  postedToTwitter?: ModelSubscriptionBooleanInput | null,
  enabledTiktok?: ModelSubscriptionBooleanInput | null,
  enabledLinkedin?: ModelSubscriptionBooleanInput | null,
  enabledPinterest?: ModelSubscriptionBooleanInput | null,
  enabledYoutube?: ModelSubscriptionBooleanInput | null,
  enabledFacebook?: ModelSubscriptionBooleanInput | null,
  enabledInstagram?: ModelSubscriptionBooleanInput | null,
  enabledTwitter?: ModelSubscriptionBooleanInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSocialManPostFilterInput | null > | null,
  or?: Array< ModelSubscriptionSocialManPostFilterInput | null > | null,
  userID?: ModelStringInput | null,
};

export type UpdateMLTaskExecutionResultNoDSMutationVariables = {
  input: UpdateMLTaskExecutionResultInputNoDS,
};

export type UpdateMLTaskExecutionResultNoDSMutation = {
  updateMLTaskExecutionResultNoDS?:  {
    id: string,
    userID: string,
    HEARTReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    SADReactionStats?: number | null,
    ANGRYReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    POOPReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        userID: string,
        type: TaskReactionType,
        mltaskexecutionresultID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
      metaData?:  {
        FILE_NAME?: string | null,
        FILE_TYPE?: string | null,
        FILE_SIZE_KB?: number | null,
        DURATION?: number | null,
        WIDTH?: number | null,
        HEIGHT?: number | null,
        STRING_LENGTH?: number | null,
        TOKENS_COUNT?: number | null,
      } | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
    presetUserInput?:  {
      presetID?: string | null,
      presetVersion?: string | null,
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    metadata?:  {
      LumaLabsGenerationID?: string | null,
      ReplicatePredictionID?: string | null,
      RunwayMLGenerationID?: string | null,
    } | null,
    createdAt: string,
    mltasksworkspaceID: string,
    modelsID: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserNoDSMutationVariables = {
  input: UpdateUserInputNoDS,
};

export type UpdateUserNoDSMutation = {
  updateUserNoDS?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSocialManPostNoDSMutationVariables = {
  input: UpdateSocialManPostInputNoDS,
};

export type UpdateSocialManPostNoDSMutation = {
  updateSocialManPostNoDS?:  {
    id: string,
    title?: string | null,
    description?: string | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    userID: string,
    createdAt: string,
    scheduledAt?: string | null,
    postedAt?: string | null,
    status: SocialManPostStatus,
    tiktok?:  {
      title?: string | null,
      caption?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      contentDisclosureBrandedContent?: boolean | null,
      videoCoverTimestampMs?: number | null,
      postID?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      title?: string | null,
      description?: string | null,
      link?: string | null,
      thumbnailURL?: string | null,
      boardID?: string | null,
      pinID?: string | null,
    } | null,
    youtube?:  {
      title?: string | null,
      description?: string | null,
      tags?: string | null,
      thumbnailURL?: string | null,
      privacy?: string | null,
      category?: string | null,
      targetChannelID?: string | null,
      videoID?: string | null,
    } | null,
    instagram?:  {
      caption: string,
      thumbnail?: string | null,
      targetAccountID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
    } | null,
    facebook?:  {
      caption: string,
      thumbnail?: string | null,
      targetPageID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
      storyURL?: string | null,
    } | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    postedToTiktok?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToYoutube?: boolean | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToTwitter?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledYoutube?: boolean | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledTwitter?: boolean | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserUsernameMutationVariables = {
  userID: string,
  username: string,
};

export type UpdateUserUsernameMutation = {
  updateUserUsername?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMLTasksWorkspaceMutationVariables = {
  input: CreateMLTasksWorkspaceInput,
  condition?: ModelMLTasksWorkspaceConditionInput | null,
};

export type CreateMLTasksWorkspaceMutation = {
  createMLTasksWorkspace?:  {
    id: string,
    name?: string | null,
    description?: string | null,
    userID: string,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMLTasksWorkspaceMutationVariables = {
  input: UpdateMLTasksWorkspaceInput,
  condition?: ModelMLTasksWorkspaceConditionInput | null,
};

export type UpdateMLTasksWorkspaceMutation = {
  updateMLTasksWorkspace?:  {
    id: string,
    name?: string | null,
    description?: string | null,
    userID: string,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMLTasksWorkspaceMutationVariables = {
  input: DeleteMLTasksWorkspaceInput,
  condition?: ModelMLTasksWorkspaceConditionInput | null,
};

export type DeleteMLTasksWorkspaceMutation = {
  deleteMLTasksWorkspace?:  {
    id: string,
    name?: string | null,
    description?: string | null,
    userID: string,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateModelInputPresetMutationVariables = {
  input: CreateModelInputPresetInput,
  condition?: ModelModelInputPresetConditionInput | null,
};

export type CreateModelInputPresetMutation = {
  createModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    cost?: number | null,
    usage?: number | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        val?: string | null,
        description?: string | null,
        presetOrder?: number | null,
      } | null > | null,
    } | null > | null,
    versionHistory?:  Array< {
      versionName?: string | null,
      cost?: number | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null > | null,
    versionName?: string | null,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    modelsID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateModelInputPresetMutationVariables = {
  input: UpdateModelInputPresetInput,
  condition?: ModelModelInputPresetConditionInput | null,
};

export type UpdateModelInputPresetMutation = {
  updateModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    cost?: number | null,
    usage?: number | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        val?: string | null,
        description?: string | null,
        presetOrder?: number | null,
      } | null > | null,
    } | null > | null,
    versionHistory?:  Array< {
      versionName?: string | null,
      cost?: number | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null > | null,
    versionName?: string | null,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    modelsID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteModelInputPresetMutationVariables = {
  input: DeleteModelInputPresetInput,
  condition?: ModelModelInputPresetConditionInput | null,
};

export type DeleteModelInputPresetMutation = {
  deleteModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    cost?: number | null,
    usage?: number | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        val?: string | null,
        description?: string | null,
        presetOrder?: number | null,
      } | null > | null,
    } | null > | null,
    versionHistory?:  Array< {
      versionName?: string | null,
      cost?: number | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null > | null,
    versionName?: string | null,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    modelsID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTaskReactionMutationVariables = {
  input: CreateTaskReactionInput,
  condition?: ModelTaskReactionConditionInput | null,
};

export type CreateTaskReactionMutation = {
  createTaskReaction?:  {
    userID: string,
    type: TaskReactionType,
    mltaskexecutionresultID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTaskReactionMutationVariables = {
  input: UpdateTaskReactionInput,
  condition?: ModelTaskReactionConditionInput | null,
};

export type UpdateTaskReactionMutation = {
  updateTaskReaction?:  {
    userID: string,
    type: TaskReactionType,
    mltaskexecutionresultID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTaskReactionMutationVariables = {
  input: DeleteTaskReactionInput,
  condition?: ModelTaskReactionConditionInput | null,
};

export type DeleteTaskReactionMutation = {
  deleteTaskReaction?:  {
    userID: string,
    type: TaskReactionType,
    mltaskexecutionresultID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMLTaskExecutionResultMutationVariables = {
  input: CreateMLTaskExecutionResultInput,
  condition?: ModelMLTaskExecutionResultConditionInput | null,
};

export type CreateMLTaskExecutionResultMutation = {
  createMLTaskExecutionResult?:  {
    id: string,
    userID: string,
    HEARTReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    SADReactionStats?: number | null,
    ANGRYReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    POOPReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        userID: string,
        type: TaskReactionType,
        mltaskexecutionresultID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
      metaData?:  {
        FILE_NAME?: string | null,
        FILE_TYPE?: string | null,
        FILE_SIZE_KB?: number | null,
        DURATION?: number | null,
        WIDTH?: number | null,
        HEIGHT?: number | null,
        STRING_LENGTH?: number | null,
        TOKENS_COUNT?: number | null,
      } | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
    presetUserInput?:  {
      presetID?: string | null,
      presetVersion?: string | null,
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    metadata?:  {
      LumaLabsGenerationID?: string | null,
      ReplicatePredictionID?: string | null,
      RunwayMLGenerationID?: string | null,
    } | null,
    createdAt: string,
    mltasksworkspaceID: string,
    modelsID: string,
    updatedAt: string,
  } | null,
};

export type UpdateMLTaskExecutionResultMutationVariables = {
  input: UpdateMLTaskExecutionResultInput,
  condition?: ModelMLTaskExecutionResultConditionInput | null,
};

export type UpdateMLTaskExecutionResultMutation = {
  updateMLTaskExecutionResult?:  {
    id: string,
    userID: string,
    HEARTReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    SADReactionStats?: number | null,
    ANGRYReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    POOPReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        userID: string,
        type: TaskReactionType,
        mltaskexecutionresultID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
      metaData?:  {
        FILE_NAME?: string | null,
        FILE_TYPE?: string | null,
        FILE_SIZE_KB?: number | null,
        DURATION?: number | null,
        WIDTH?: number | null,
        HEIGHT?: number | null,
        STRING_LENGTH?: number | null,
        TOKENS_COUNT?: number | null,
      } | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
    presetUserInput?:  {
      presetID?: string | null,
      presetVersion?: string | null,
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    metadata?:  {
      LumaLabsGenerationID?: string | null,
      ReplicatePredictionID?: string | null,
      RunwayMLGenerationID?: string | null,
    } | null,
    createdAt: string,
    mltasksworkspaceID: string,
    modelsID: string,
    updatedAt: string,
  } | null,
};

export type DeleteMLTaskExecutionResultMutationVariables = {
  input: DeleteMLTaskExecutionResultInput,
  condition?: ModelMLTaskExecutionResultConditionInput | null,
};

export type DeleteMLTaskExecutionResultMutation = {
  deleteMLTaskExecutionResult?:  {
    id: string,
    userID: string,
    HEARTReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    SADReactionStats?: number | null,
    ANGRYReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    POOPReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        userID: string,
        type: TaskReactionType,
        mltaskexecutionresultID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
      metaData?:  {
        FILE_NAME?: string | null,
        FILE_TYPE?: string | null,
        FILE_SIZE_KB?: number | null,
        DURATION?: number | null,
        WIDTH?: number | null,
        HEIGHT?: number | null,
        STRING_LENGTH?: number | null,
        TOKENS_COUNT?: number | null,
      } | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
    presetUserInput?:  {
      presetID?: string | null,
      presetVersion?: string | null,
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    metadata?:  {
      LumaLabsGenerationID?: string | null,
      ReplicatePredictionID?: string | null,
      RunwayMLGenerationID?: string | null,
    } | null,
    createdAt: string,
    mltasksworkspaceID: string,
    modelsID: string,
    updatedAt: string,
  } | null,
};

export type CreateCreditTransactionsHistoryMutationVariables = {
  input: CreateCreditTransactionsHistoryInput,
  condition?: ModelCreditTransactionsHistoryConditionInput | null,
};

export type CreateCreditTransactionsHistoryMutation = {
  createCreditTransactionsHistory?:  {
    id: string,
    eventID?: string | null,
    userID: string,
    cashAmountInCents?: number | null,
    creditAmount?: number | null,
    status?: CreditTransactionsHistoryStatus | null,
    transactionType?: CreditTransactionsHistoryType | null,
    context?:  {
      taskID?: string | null,
      presetID?: string | null,
      presetVersion?: string | null,
      subscriptionID?: string | null,
      priceID?: string | null,
      transactionID?: string | null,
      feedback?: string | null,
      comment?: string | null,
      reason?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCreditTransactionsHistoryMutationVariables = {
  input: UpdateCreditTransactionsHistoryInput,
  condition?: ModelCreditTransactionsHistoryConditionInput | null,
};

export type UpdateCreditTransactionsHistoryMutation = {
  updateCreditTransactionsHistory?:  {
    id: string,
    eventID?: string | null,
    userID: string,
    cashAmountInCents?: number | null,
    creditAmount?: number | null,
    status?: CreditTransactionsHistoryStatus | null,
    transactionType?: CreditTransactionsHistoryType | null,
    context?:  {
      taskID?: string | null,
      presetID?: string | null,
      presetVersion?: string | null,
      subscriptionID?: string | null,
      priceID?: string | null,
      transactionID?: string | null,
      feedback?: string | null,
      comment?: string | null,
      reason?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCreditTransactionsHistoryMutationVariables = {
  input: DeleteCreditTransactionsHistoryInput,
  condition?: ModelCreditTransactionsHistoryConditionInput | null,
};

export type DeleteCreditTransactionsHistoryMutation = {
  deleteCreditTransactionsHistory?:  {
    id: string,
    eventID?: string | null,
    userID: string,
    cashAmountInCents?: number | null,
    creditAmount?: number | null,
    status?: CreditTransactionsHistoryStatus | null,
    transactionType?: CreditTransactionsHistoryType | null,
    context?:  {
      taskID?: string | null,
      presetID?: string | null,
      presetVersion?: string | null,
      subscriptionID?: string | null,
      priceID?: string | null,
      transactionID?: string | null,
      feedback?: string | null,
      comment?: string | null,
      reason?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateModelsMutationVariables = {
  input: CreateModelsInput,
  condition?: ModelModelsConditionInput | null,
};

export type CreateModelsMutation = {
  createModels?:  {
    id: string,
    isPublic: boolean,
    name?: string | null,
    usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
    inputs?:  {
      entries?:  Array< {
        type: ModelInputOutputEntryType,
        displayName?: string | null,
        backendName?: string | null,
        defaultValue?: string | null,
        optionType?: OptionType | null,
        optionStep?: number | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        isRequired?: boolean | null,
      } | null > | null,
    } | null,
    outputs?:  {
      entries?:  Array< {
        type?: ModelInputOutputEntryType | null,
        name?: string | null,
      } | null > | null,
    } | null,
    mltaskdataID: string,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateModelsMutationVariables = {
  input: UpdateModelsInput,
  condition?: ModelModelsConditionInput | null,
};

export type UpdateModelsMutation = {
  updateModels?:  {
    id: string,
    isPublic: boolean,
    name?: string | null,
    usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
    inputs?:  {
      entries?:  Array< {
        type: ModelInputOutputEntryType,
        displayName?: string | null,
        backendName?: string | null,
        defaultValue?: string | null,
        optionType?: OptionType | null,
        optionStep?: number | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        isRequired?: boolean | null,
      } | null > | null,
    } | null,
    outputs?:  {
      entries?:  Array< {
        type?: ModelInputOutputEntryType | null,
        name?: string | null,
      } | null > | null,
    } | null,
    mltaskdataID: string,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteModelsMutationVariables = {
  input: DeleteModelsInput,
  condition?: ModelModelsConditionInput | null,
};

export type DeleteModelsMutation = {
  deleteModels?:  {
    id: string,
    isPublic: boolean,
    name?: string | null,
    usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
    inputs?:  {
      entries?:  Array< {
        type: ModelInputOutputEntryType,
        displayName?: string | null,
        backendName?: string | null,
        defaultValue?: string | null,
        optionType?: OptionType | null,
        optionStep?: number | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        isRequired?: boolean | null,
      } | null > | null,
    } | null,
    outputs?:  {
      entries?:  Array< {
        type?: ModelInputOutputEntryType | null,
        name?: string | null,
      } | null > | null,
    } | null,
    mltaskdataID: string,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMLTaskDataMutationVariables = {
  input: CreateMLTaskDataInput,
  condition?: ModelMLTaskDataConditionInput | null,
};

export type CreateMLTaskDataMutation = {
  createMLTaskData?:  {
    id: string,
    name?: string | null,
    category?: string | null,
    isPublic: boolean,
    Models?:  {
      items:  Array< {
        id: string,
        isPublic: boolean,
        name?: string | null,
        usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
        mltaskdataID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMLTaskDataMutationVariables = {
  input: UpdateMLTaskDataInput,
  condition?: ModelMLTaskDataConditionInput | null,
};

export type UpdateMLTaskDataMutation = {
  updateMLTaskData?:  {
    id: string,
    name?: string | null,
    category?: string | null,
    isPublic: boolean,
    Models?:  {
      items:  Array< {
        id: string,
        isPublic: boolean,
        name?: string | null,
        usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
        mltaskdataID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMLTaskDataMutationVariables = {
  input: DeleteMLTaskDataInput,
  condition?: ModelMLTaskDataConditionInput | null,
};

export type DeleteMLTaskDataMutation = {
  deleteMLTaskData?:  {
    id: string,
    name?: string | null,
    category?: string | null,
    isPublic: boolean,
    Models?:  {
      items:  Array< {
        id: string,
        isPublic: boolean,
        name?: string | null,
        usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
        mltaskdataID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSocialManPostMutationVariables = {
  input: CreateSocialManPostInput,
  condition?: ModelSocialManPostConditionInput | null,
};

export type CreateSocialManPostMutation = {
  createSocialManPost?:  {
    id: string,
    title?: string | null,
    description?: string | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    userID: string,
    createdAt: string,
    scheduledAt?: string | null,
    postedAt?: string | null,
    status: SocialManPostStatus,
    tiktok?:  {
      title?: string | null,
      caption?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      contentDisclosureBrandedContent?: boolean | null,
      videoCoverTimestampMs?: number | null,
      postID?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      title?: string | null,
      description?: string | null,
      link?: string | null,
      thumbnailURL?: string | null,
      boardID?: string | null,
      pinID?: string | null,
    } | null,
    youtube?:  {
      title?: string | null,
      description?: string | null,
      tags?: string | null,
      thumbnailURL?: string | null,
      privacy?: string | null,
      category?: string | null,
      targetChannelID?: string | null,
      videoID?: string | null,
    } | null,
    instagram?:  {
      caption: string,
      thumbnail?: string | null,
      targetAccountID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
    } | null,
    facebook?:  {
      caption: string,
      thumbnail?: string | null,
      targetPageID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
      storyURL?: string | null,
    } | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    postedToTiktok?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToYoutube?: boolean | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToTwitter?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledYoutube?: boolean | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledTwitter?: boolean | null,
    updatedAt: string,
  } | null,
};

export type UpdateSocialManPostMutationVariables = {
  input: UpdateSocialManPostInput,
  condition?: ModelSocialManPostConditionInput | null,
};

export type UpdateSocialManPostMutation = {
  updateSocialManPost?:  {
    id: string,
    title?: string | null,
    description?: string | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    userID: string,
    createdAt: string,
    scheduledAt?: string | null,
    postedAt?: string | null,
    status: SocialManPostStatus,
    tiktok?:  {
      title?: string | null,
      caption?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      contentDisclosureBrandedContent?: boolean | null,
      videoCoverTimestampMs?: number | null,
      postID?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      title?: string | null,
      description?: string | null,
      link?: string | null,
      thumbnailURL?: string | null,
      boardID?: string | null,
      pinID?: string | null,
    } | null,
    youtube?:  {
      title?: string | null,
      description?: string | null,
      tags?: string | null,
      thumbnailURL?: string | null,
      privacy?: string | null,
      category?: string | null,
      targetChannelID?: string | null,
      videoID?: string | null,
    } | null,
    instagram?:  {
      caption: string,
      thumbnail?: string | null,
      targetAccountID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
    } | null,
    facebook?:  {
      caption: string,
      thumbnail?: string | null,
      targetPageID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
      storyURL?: string | null,
    } | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    postedToTiktok?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToYoutube?: boolean | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToTwitter?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledYoutube?: boolean | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledTwitter?: boolean | null,
    updatedAt: string,
  } | null,
};

export type DeleteSocialManPostMutationVariables = {
  input: DeleteSocialManPostInput,
  condition?: ModelSocialManPostConditionInput | null,
};

export type DeleteSocialManPostMutation = {
  deleteSocialManPost?:  {
    id: string,
    title?: string | null,
    description?: string | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    userID: string,
    createdAt: string,
    scheduledAt?: string | null,
    postedAt?: string | null,
    status: SocialManPostStatus,
    tiktok?:  {
      title?: string | null,
      caption?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      contentDisclosureBrandedContent?: boolean | null,
      videoCoverTimestampMs?: number | null,
      postID?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      title?: string | null,
      description?: string | null,
      link?: string | null,
      thumbnailURL?: string | null,
      boardID?: string | null,
      pinID?: string | null,
    } | null,
    youtube?:  {
      title?: string | null,
      description?: string | null,
      tags?: string | null,
      thumbnailURL?: string | null,
      privacy?: string | null,
      category?: string | null,
      targetChannelID?: string | null,
      videoID?: string | null,
    } | null,
    instagram?:  {
      caption: string,
      thumbnail?: string | null,
      targetAccountID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
    } | null,
    facebook?:  {
      caption: string,
      thumbnail?: string | null,
      targetPageID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
      storyURL?: string | null,
    } | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    postedToTiktok?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToYoutube?: boolean | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToTwitter?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledYoutube?: boolean | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledTwitter?: boolean | null,
    updatedAt: string,
  } | null,
};

export type BatchGetUsersQueryVariables = {
  ids: Array< string | null >,
};

export type BatchGetUsersQuery = {
  batchGetUsers?:  Array< {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetUserFromUsernameQueryVariables = {
  username: string,
};

export type GetUserFromUsernameQuery = {
  getUserFromUsername?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetMLTasksWorkspaceQueryVariables = {
  id: string,
};

export type GetMLTasksWorkspaceQuery = {
  getMLTasksWorkspace?:  {
    id: string,
    name?: string | null,
    description?: string | null,
    userID: string,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMLTasksWorkspacesQueryVariables = {
  filter?: ModelMLTasksWorkspaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMLTasksWorkspacesQuery = {
  listMLTasksWorkspaces?:  {
    items:  Array< {
      id: string,
      name?: string | null,
      description?: string | null,
      userID: string,
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMLTasksWorkspaceByCreatedAtQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMLTasksWorkspaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMLTasksWorkspaceByCreatedAtQuery = {
  listMLTasksWorkspaceByCreatedAt?:  {
    items:  Array< {
      id: string,
      name?: string | null,
      description?: string | null,
      userID: string,
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModelInputPresetQueryVariables = {
  id: string,
};

export type GetModelInputPresetQuery = {
  getModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    cost?: number | null,
    usage?: number | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        val?: string | null,
        description?: string | null,
        presetOrder?: number | null,
      } | null > | null,
    } | null > | null,
    versionHistory?:  Array< {
      versionName?: string | null,
      cost?: number | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null > | null,
    versionName?: string | null,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    modelsID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListModelInputPresetsQueryVariables = {
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModelInputPresetsQuery = {
  listModelInputPresets?:  {
    items:  Array< {
      id: string,
      presetName?: string | null,
      description?: string | null,
      cost?: number | null,
      usage?: number | null,
      isPublic?: boolean | null,
      isOpenSource?: boolean | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
      versionHistory?:  Array< {
        versionName?: string | null,
        cost?: number | null,
      } | null > | null,
      versionName?: string | null,
      username?: string | null,
      taskName?: string | null,
      modelName?: string | null,
      modelsID: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ModelInputPresetsByModelsIDQueryVariables = {
  modelsID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ModelInputPresetsByModelsIDQuery = {
  modelInputPresetsByModelsID?:  {
    items:  Array< {
      id: string,
      presetName?: string | null,
      description?: string | null,
      cost?: number | null,
      usage?: number | null,
      isPublic?: boolean | null,
      isOpenSource?: boolean | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
      versionHistory?:  Array< {
        versionName?: string | null,
        cost?: number | null,
      } | null > | null,
      versionName?: string | null,
      username?: string | null,
      taskName?: string | null,
      modelName?: string | null,
      modelsID: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ModelInputPresetsByUserIDQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ModelInputPresetsByUserIDQuery = {
  modelInputPresetsByUserID?:  {
    items:  Array< {
      id: string,
      presetName?: string | null,
      description?: string | null,
      cost?: number | null,
      usage?: number | null,
      isPublic?: boolean | null,
      isOpenSource?: boolean | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
      versionHistory?:  Array< {
        versionName?: string | null,
        cost?: number | null,
      } | null > | null,
      versionName?: string | null,
      username?: string | null,
      taskName?: string | null,
      modelName?: string | null,
      modelsID: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaskReactionQueryVariables = {
  userID: string,
};

export type GetTaskReactionQuery = {
  getTaskReaction?:  {
    userID: string,
    type: TaskReactionType,
    mltaskexecutionresultID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTaskReactionsQueryVariables = {
  userID?: string | null,
  filter?: ModelTaskReactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTaskReactionsQuery = {
  listTaskReactions?:  {
    items:  Array< {
      userID: string,
      type: TaskReactionType,
      mltaskexecutionresultID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TaskReactionsByMltaskexecutionresultIDQueryVariables = {
  mltaskexecutionresultID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskReactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TaskReactionsByMltaskexecutionresultIDQuery = {
  taskReactionsByMltaskexecutionresultID?:  {
    items:  Array< {
      userID: string,
      type: TaskReactionType,
      mltaskexecutionresultID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMLTaskExecutionResultQueryVariables = {
  id: string,
};

export type GetMLTaskExecutionResultQuery = {
  getMLTaskExecutionResult?:  {
    id: string,
    userID: string,
    HEARTReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    SADReactionStats?: number | null,
    ANGRYReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    POOPReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        userID: string,
        type: TaskReactionType,
        mltaskexecutionresultID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
      metaData?:  {
        FILE_NAME?: string | null,
        FILE_TYPE?: string | null,
        FILE_SIZE_KB?: number | null,
        DURATION?: number | null,
        WIDTH?: number | null,
        HEIGHT?: number | null,
        STRING_LENGTH?: number | null,
        TOKENS_COUNT?: number | null,
      } | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
    presetUserInput?:  {
      presetID?: string | null,
      presetVersion?: string | null,
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    metadata?:  {
      LumaLabsGenerationID?: string | null,
      ReplicatePredictionID?: string | null,
      RunwayMLGenerationID?: string | null,
    } | null,
    createdAt: string,
    mltasksworkspaceID: string,
    modelsID: string,
    updatedAt: string,
  } | null,
};

export type ListMLTaskExecutionResultsQueryVariables = {
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMLTaskExecutionResultsQuery = {
  listMLTaskExecutionResults?:  {
    items:  Array< {
      id: string,
      userID: string,
      HEARTReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      SADReactionStats?: number | null,
      ANGRYReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      POOPReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      Reactions?:  {
        nextToken?: string | null,
      } | null,
      taskName?: string | null,
      modelName?: string | null,
      privacyLevel?: TaskPrivacyLevels | null,
      inputs?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
      output?:  {
        message?: string | null,
      } | null,
      creditsUsed?: number | null,
      status?: TaskResultStatus | null,
      presetUserInput?:  {
        presetID?: string | null,
        presetVersion?: string | null,
        presetName?: string | null,
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
      } | null,
      metadata?:  {
        LumaLabsGenerationID?: string | null,
        ReplicatePredictionID?: string | null,
        RunwayMLGenerationID?: string | null,
      } | null,
      createdAt: string,
      mltasksworkspaceID: string,
      modelsID: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMLTaskExecutionResultsByCreatedAtQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMLTaskExecutionResultsByCreatedAtQuery = {
  listMLTaskExecutionResultsByCreatedAt?:  {
    items:  Array< {
      id: string,
      userID: string,
      HEARTReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      SADReactionStats?: number | null,
      ANGRYReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      POOPReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      Reactions?:  {
        nextToken?: string | null,
      } | null,
      taskName?: string | null,
      modelName?: string | null,
      privacyLevel?: TaskPrivacyLevels | null,
      inputs?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
      output?:  {
        message?: string | null,
      } | null,
      creditsUsed?: number | null,
      status?: TaskResultStatus | null,
      presetUserInput?:  {
        presetID?: string | null,
        presetVersion?: string | null,
        presetName?: string | null,
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
      } | null,
      metadata?:  {
        LumaLabsGenerationID?: string | null,
        ReplicatePredictionID?: string | null,
        RunwayMLGenerationID?: string | null,
      } | null,
      createdAt: string,
      mltasksworkspaceID: string,
      modelsID: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MLTaskExecutionResultsByMltasksworkspaceIDQueryVariables = {
  mltasksworkspaceID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MLTaskExecutionResultsByMltasksworkspaceIDQuery = {
  mLTaskExecutionResultsByMltasksworkspaceID?:  {
    items:  Array< {
      id: string,
      userID: string,
      HEARTReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      SADReactionStats?: number | null,
      ANGRYReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      POOPReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      Reactions?:  {
        nextToken?: string | null,
      } | null,
      taskName?: string | null,
      modelName?: string | null,
      privacyLevel?: TaskPrivacyLevels | null,
      inputs?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
      output?:  {
        message?: string | null,
      } | null,
      creditsUsed?: number | null,
      status?: TaskResultStatus | null,
      presetUserInput?:  {
        presetID?: string | null,
        presetVersion?: string | null,
        presetName?: string | null,
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
      } | null,
      metadata?:  {
        LumaLabsGenerationID?: string | null,
        ReplicatePredictionID?: string | null,
        RunwayMLGenerationID?: string | null,
      } | null,
      createdAt: string,
      mltasksworkspaceID: string,
      modelsID: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MLTaskExecutionResultsByModelsIDQueryVariables = {
  modelsID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MLTaskExecutionResultsByModelsIDQuery = {
  mLTaskExecutionResultsByModelsID?:  {
    items:  Array< {
      id: string,
      userID: string,
      HEARTReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      SADReactionStats?: number | null,
      ANGRYReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      POOPReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      Reactions?:  {
        nextToken?: string | null,
      } | null,
      taskName?: string | null,
      modelName?: string | null,
      privacyLevel?: TaskPrivacyLevels | null,
      inputs?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
      output?:  {
        message?: string | null,
      } | null,
      creditsUsed?: number | null,
      status?: TaskResultStatus | null,
      presetUserInput?:  {
        presetID?: string | null,
        presetVersion?: string | null,
        presetName?: string | null,
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
      } | null,
      metadata?:  {
        LumaLabsGenerationID?: string | null,
        ReplicatePredictionID?: string | null,
        RunwayMLGenerationID?: string | null,
      } | null,
      createdAt: string,
      mltasksworkspaceID: string,
      modelsID: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCreditTransactionsHistoryQueryVariables = {
  id: string,
};

export type GetCreditTransactionsHistoryQuery = {
  getCreditTransactionsHistory?:  {
    id: string,
    eventID?: string | null,
    userID: string,
    cashAmountInCents?: number | null,
    creditAmount?: number | null,
    status?: CreditTransactionsHistoryStatus | null,
    transactionType?: CreditTransactionsHistoryType | null,
    context?:  {
      taskID?: string | null,
      presetID?: string | null,
      presetVersion?: string | null,
      subscriptionID?: string | null,
      priceID?: string | null,
      transactionID?: string | null,
      feedback?: string | null,
      comment?: string | null,
      reason?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCreditTransactionsHistoriesQueryVariables = {
  filter?: ModelCreditTransactionsHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCreditTransactionsHistoriesQuery = {
  listCreditTransactionsHistories?:  {
    items:  Array< {
      id: string,
      eventID?: string | null,
      userID: string,
      cashAmountInCents?: number | null,
      creditAmount?: number | null,
      status?: CreditTransactionsHistoryStatus | null,
      transactionType?: CreditTransactionsHistoryType | null,
      context?:  {
        taskID?: string | null,
        presetID?: string | null,
        presetVersion?: string | null,
        subscriptionID?: string | null,
        priceID?: string | null,
        transactionID?: string | null,
        feedback?: string | null,
        comment?: string | null,
        reason?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserCreditTransactionsHistoryByCreatedAtQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCreditTransactionsHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserCreditTransactionsHistoryByCreatedAtQuery = {
  listUserCreditTransactionsHistoryByCreatedAt?:  {
    items:  Array< {
      id: string,
      eventID?: string | null,
      userID: string,
      cashAmountInCents?: number | null,
      creditAmount?: number | null,
      status?: CreditTransactionsHistoryStatus | null,
      transactionType?: CreditTransactionsHistoryType | null,
      context?:  {
        taskID?: string | null,
        presetID?: string | null,
        presetVersion?: string | null,
        subscriptionID?: string | null,
        priceID?: string | null,
        transactionID?: string | null,
        feedback?: string | null,
        comment?: string | null,
        reason?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModelsQueryVariables = {
  id: string,
};

export type GetModelsQuery = {
  getModels?:  {
    id: string,
    isPublic: boolean,
    name?: string | null,
    usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
    inputs?:  {
      entries?:  Array< {
        type: ModelInputOutputEntryType,
        displayName?: string | null,
        backendName?: string | null,
        defaultValue?: string | null,
        optionType?: OptionType | null,
        optionStep?: number | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        isRequired?: boolean | null,
      } | null > | null,
    } | null,
    outputs?:  {
      entries?:  Array< {
        type?: ModelInputOutputEntryType | null,
        name?: string | null,
      } | null > | null,
    } | null,
    mltaskdataID: string,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListModelsQueryVariables = {
  filter?: ModelModelsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModelsQuery = {
  listModels?:  {
    items:  Array< {
      id: string,
      isPublic: boolean,
      name?: string | null,
      usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
      mltaskdataID: string,
      ModelInputPresets?:  {
        nextToken?: string | null,
      } | null,
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ModelsByMltaskdataIDQueryVariables = {
  mltaskdataID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModelsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ModelsByMltaskdataIDQuery = {
  modelsByMltaskdataID?:  {
    items:  Array< {
      id: string,
      isPublic: boolean,
      name?: string | null,
      usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
      mltaskdataID: string,
      ModelInputPresets?:  {
        nextToken?: string | null,
      } | null,
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMLTaskDataQueryVariables = {
  id: string,
};

export type GetMLTaskDataQuery = {
  getMLTaskData?:  {
    id: string,
    name?: string | null,
    category?: string | null,
    isPublic: boolean,
    Models?:  {
      items:  Array< {
        id: string,
        isPublic: boolean,
        name?: string | null,
        usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
        mltaskdataID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMLTaskDataQueryVariables = {
  filter?: ModelMLTaskDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMLTaskDataQuery = {
  listMLTaskData?:  {
    items:  Array< {
      id: string,
      name?: string | null,
      category?: string | null,
      isPublic: boolean,
      Models?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    items:  Array< {
      id: string,
      name?: string | null,
      username?: string | null,
      anthropicAPIKey?: string | null,
      elevenLabsAPIKey?: string | null,
      FalAPIKey?: string | null,
      lumaLabsAPIKey?: string | null,
      nimsAPIKey?: string | null,
      openAIAPIKey?: string | null,
      replicateAPIKey?: string | null,
      runwayMLAPIKey?: string | null,
      SAIAPIKey?: string | null,
      currentSubscriptionPlan?: SubscriptionPlan | null,
      periodEndOfLastSubscriptionCredits?: number | null,
      createdPresetCount?: number | null,
      completedTasksCount?: number | null,
      stripeCustomerID?: string | null,
      stripeConnectAccountID?: string | null,
      stripeConnectAccountSetupComplete?: boolean | null,
      email?: string | null,
      picture?: string | null,
      credits?: number | null,
      redeemableCredits?: number | null,
      SocialManPosts?:  {
        nextToken?: string | null,
      } | null,
      MLTaskExecutionResults?:  {
        nextToken?: string | null,
      } | null,
      MLTasksWorkspaces?:  {
        nextToken?: string | null,
      } | null,
      ModelInputPresets?:  {
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSocialManPostQueryVariables = {
  id: string,
};

export type GetSocialManPostQuery = {
  getSocialManPost?:  {
    id: string,
    title?: string | null,
    description?: string | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    userID: string,
    createdAt: string,
    scheduledAt?: string | null,
    postedAt?: string | null,
    status: SocialManPostStatus,
    tiktok?:  {
      title?: string | null,
      caption?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      contentDisclosureBrandedContent?: boolean | null,
      videoCoverTimestampMs?: number | null,
      postID?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      title?: string | null,
      description?: string | null,
      link?: string | null,
      thumbnailURL?: string | null,
      boardID?: string | null,
      pinID?: string | null,
    } | null,
    youtube?:  {
      title?: string | null,
      description?: string | null,
      tags?: string | null,
      thumbnailURL?: string | null,
      privacy?: string | null,
      category?: string | null,
      targetChannelID?: string | null,
      videoID?: string | null,
    } | null,
    instagram?:  {
      caption: string,
      thumbnail?: string | null,
      targetAccountID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
    } | null,
    facebook?:  {
      caption: string,
      thumbnail?: string | null,
      targetPageID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
      storyURL?: string | null,
    } | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    postedToTiktok?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToYoutube?: boolean | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToTwitter?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledYoutube?: boolean | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledTwitter?: boolean | null,
    updatedAt: string,
  } | null,
};

export type ListSocialManPostsQueryVariables = {
  filter?: ModelSocialManPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSocialManPostsQuery = {
  listSocialManPosts?:  {
    items:  Array< {
      id: string,
      title?: string | null,
      description?: string | null,
      s3Key?: string | null,
      s3KeyResized?: string | null,
      userID: string,
      createdAt: string,
      scheduledAt?: string | null,
      postedAt?: string | null,
      status: SocialManPostStatus,
      tiktok?:  {
        title?: string | null,
        caption?: string | null,
        privacy?: SocialManTiktokPrivacyStatus | null,
        usersCanComment?: boolean | null,
        usersCanDuet?: boolean | null,
        usersCanStitch?: boolean | null,
        contentDisclosureEnabled?: boolean | null,
        contentDisclosureYourBrand?: boolean | null,
        contentDisclosureBrandedContent?: boolean | null,
        videoCoverTimestampMs?: number | null,
        postID?: string | null,
      } | null,
      linkedin?:  {
        caption?: string | null,
        mediaDescription?: string | null,
        postID?: string | null,
      } | null,
      pinterest?:  {
        title?: string | null,
        description?: string | null,
        link?: string | null,
        thumbnailURL?: string | null,
        boardID?: string | null,
        pinID?: string | null,
      } | null,
      youtube?:  {
        title?: string | null,
        description?: string | null,
        tags?: string | null,
        thumbnailURL?: string | null,
        privacy?: string | null,
        category?: string | null,
        targetChannelID?: string | null,
        videoID?: string | null,
      } | null,
      instagram?:  {
        caption: string,
        thumbnail?: string | null,
        targetAccountID?: string | null,
        postToStory?: boolean | null,
        postID?: string | null,
        storyID?: string | null,
      } | null,
      facebook?:  {
        caption: string,
        thumbnail?: string | null,
        targetPageID?: string | null,
        postToStory?: boolean | null,
        postID?: string | null,
        storyID?: string | null,
        storyURL?: string | null,
      } | null,
      twitter?:  {
        caption: string,
        tweetID?: string | null,
      } | null,
      postedToTiktok?: boolean | null,
      postedToLinkedin?: boolean | null,
      postedToPinterest?: boolean | null,
      postedToYoutube?: boolean | null,
      postedToFacebook?: boolean | null,
      postedToInstagram?: boolean | null,
      postedToTwitter?: boolean | null,
      enabledTiktok?: boolean | null,
      enabledLinkedin?: boolean | null,
      enabledPinterest?: boolean | null,
      enabledYoutube?: boolean | null,
      enabledFacebook?: boolean | null,
      enabledInstagram?: boolean | null,
      enabledTwitter?: boolean | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSocialManPostsByCreatedAtQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSocialManPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSocialManPostsByCreatedAtQuery = {
  listSocialManPostsByCreatedAt?:  {
    items:  Array< {
      id: string,
      title?: string | null,
      description?: string | null,
      s3Key?: string | null,
      s3KeyResized?: string | null,
      userID: string,
      createdAt: string,
      scheduledAt?: string | null,
      postedAt?: string | null,
      status: SocialManPostStatus,
      tiktok?:  {
        title?: string | null,
        caption?: string | null,
        privacy?: SocialManTiktokPrivacyStatus | null,
        usersCanComment?: boolean | null,
        usersCanDuet?: boolean | null,
        usersCanStitch?: boolean | null,
        contentDisclosureEnabled?: boolean | null,
        contentDisclosureYourBrand?: boolean | null,
        contentDisclosureBrandedContent?: boolean | null,
        videoCoverTimestampMs?: number | null,
        postID?: string | null,
      } | null,
      linkedin?:  {
        caption?: string | null,
        mediaDescription?: string | null,
        postID?: string | null,
      } | null,
      pinterest?:  {
        title?: string | null,
        description?: string | null,
        link?: string | null,
        thumbnailURL?: string | null,
        boardID?: string | null,
        pinID?: string | null,
      } | null,
      youtube?:  {
        title?: string | null,
        description?: string | null,
        tags?: string | null,
        thumbnailURL?: string | null,
        privacy?: string | null,
        category?: string | null,
        targetChannelID?: string | null,
        videoID?: string | null,
      } | null,
      instagram?:  {
        caption: string,
        thumbnail?: string | null,
        targetAccountID?: string | null,
        postToStory?: boolean | null,
        postID?: string | null,
        storyID?: string | null,
      } | null,
      facebook?:  {
        caption: string,
        thumbnail?: string | null,
        targetPageID?: string | null,
        postToStory?: boolean | null,
        postID?: string | null,
        storyID?: string | null,
        storyURL?: string | null,
      } | null,
      twitter?:  {
        caption: string,
        tweetID?: string | null,
      } | null,
      postedToTiktok?: boolean | null,
      postedToLinkedin?: boolean | null,
      postedToPinterest?: boolean | null,
      postedToYoutube?: boolean | null,
      postedToFacebook?: boolean | null,
      postedToInstagram?: boolean | null,
      postedToTwitter?: boolean | null,
      enabledTiktok?: boolean | null,
      enabledLinkedin?: boolean | null,
      enabledPinterest?: boolean | null,
      enabledYoutube?: boolean | null,
      enabledFacebook?: boolean | null,
      enabledInstagram?: boolean | null,
      enabledTwitter?: boolean | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateMLTasksWorkspaceSubscriptionVariables = {
  filter?: ModelSubscriptionMLTasksWorkspaceFilterInput | null,
  userID?: string | null,
};

export type OnCreateMLTasksWorkspaceSubscription = {
  onCreateMLTasksWorkspace?:  {
    id: string,
    name?: string | null,
    description?: string | null,
    userID: string,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMLTasksWorkspaceSubscriptionVariables = {
  filter?: ModelSubscriptionMLTasksWorkspaceFilterInput | null,
  userID?: string | null,
};

export type OnUpdateMLTasksWorkspaceSubscription = {
  onUpdateMLTasksWorkspace?:  {
    id: string,
    name?: string | null,
    description?: string | null,
    userID: string,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMLTasksWorkspaceSubscriptionVariables = {
  filter?: ModelSubscriptionMLTasksWorkspaceFilterInput | null,
  userID?: string | null,
};

export type OnDeleteMLTasksWorkspaceSubscription = {
  onDeleteMLTasksWorkspace?:  {
    id: string,
    name?: string | null,
    description?: string | null,
    userID: string,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateModelInputPresetSubscriptionVariables = {
  filter?: ModelSubscriptionModelInputPresetFilterInput | null,
  userID?: string | null,
};

export type OnCreateModelInputPresetSubscription = {
  onCreateModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    cost?: number | null,
    usage?: number | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        val?: string | null,
        description?: string | null,
        presetOrder?: number | null,
      } | null > | null,
    } | null > | null,
    versionHistory?:  Array< {
      versionName?: string | null,
      cost?: number | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null > | null,
    versionName?: string | null,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    modelsID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateModelInputPresetSubscriptionVariables = {
  filter?: ModelSubscriptionModelInputPresetFilterInput | null,
  userID?: string | null,
};

export type OnUpdateModelInputPresetSubscription = {
  onUpdateModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    cost?: number | null,
    usage?: number | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        val?: string | null,
        description?: string | null,
        presetOrder?: number | null,
      } | null > | null,
    } | null > | null,
    versionHistory?:  Array< {
      versionName?: string | null,
      cost?: number | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null > | null,
    versionName?: string | null,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    modelsID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteModelInputPresetSubscriptionVariables = {
  filter?: ModelSubscriptionModelInputPresetFilterInput | null,
  userID?: string | null,
};

export type OnDeleteModelInputPresetSubscription = {
  onDeleteModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    cost?: number | null,
    usage?: number | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        val?: string | null,
        description?: string | null,
        presetOrder?: number | null,
      } | null > | null,
    } | null > | null,
    versionHistory?:  Array< {
      versionName?: string | null,
      cost?: number | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null > | null,
    versionName?: string | null,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    modelsID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTaskReactionSubscriptionVariables = {
  filter?: ModelSubscriptionTaskReactionFilterInput | null,
};

export type OnCreateTaskReactionSubscription = {
  onCreateTaskReaction?:  {
    userID: string,
    type: TaskReactionType,
    mltaskexecutionresultID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTaskReactionSubscriptionVariables = {
  filter?: ModelSubscriptionTaskReactionFilterInput | null,
};

export type OnUpdateTaskReactionSubscription = {
  onUpdateTaskReaction?:  {
    userID: string,
    type: TaskReactionType,
    mltaskexecutionresultID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTaskReactionSubscriptionVariables = {
  filter?: ModelSubscriptionTaskReactionFilterInput | null,
};

export type OnDeleteTaskReactionSubscription = {
  onDeleteTaskReaction?:  {
    userID: string,
    type: TaskReactionType,
    mltaskexecutionresultID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMLTaskExecutionResultSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnCreateMLTaskExecutionResultSubscription = {
  onCreateMLTaskExecutionResult?:  {
    id: string,
    userID: string,
    HEARTReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    SADReactionStats?: number | null,
    ANGRYReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    POOPReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        userID: string,
        type: TaskReactionType,
        mltaskexecutionresultID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
      metaData?:  {
        FILE_NAME?: string | null,
        FILE_TYPE?: string | null,
        FILE_SIZE_KB?: number | null,
        DURATION?: number | null,
        WIDTH?: number | null,
        HEIGHT?: number | null,
        STRING_LENGTH?: number | null,
        TOKENS_COUNT?: number | null,
      } | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
    presetUserInput?:  {
      presetID?: string | null,
      presetVersion?: string | null,
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    metadata?:  {
      LumaLabsGenerationID?: string | null,
      ReplicatePredictionID?: string | null,
      RunwayMLGenerationID?: string | null,
    } | null,
    createdAt: string,
    mltasksworkspaceID: string,
    modelsID: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMLTaskExecutionResultSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnUpdateMLTaskExecutionResultSubscription = {
  onUpdateMLTaskExecutionResult?:  {
    id: string,
    userID: string,
    HEARTReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    SADReactionStats?: number | null,
    ANGRYReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    POOPReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        userID: string,
        type: TaskReactionType,
        mltaskexecutionresultID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
      metaData?:  {
        FILE_NAME?: string | null,
        FILE_TYPE?: string | null,
        FILE_SIZE_KB?: number | null,
        DURATION?: number | null,
        WIDTH?: number | null,
        HEIGHT?: number | null,
        STRING_LENGTH?: number | null,
        TOKENS_COUNT?: number | null,
      } | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
    presetUserInput?:  {
      presetID?: string | null,
      presetVersion?: string | null,
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    metadata?:  {
      LumaLabsGenerationID?: string | null,
      ReplicatePredictionID?: string | null,
      RunwayMLGenerationID?: string | null,
    } | null,
    createdAt: string,
    mltasksworkspaceID: string,
    modelsID: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMLTaskExecutionResultSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnDeleteMLTaskExecutionResultSubscription = {
  onDeleteMLTaskExecutionResult?:  {
    id: string,
    userID: string,
    HEARTReactionStats?: number | null,
    CUTEReactionStats?: number | null,
    FUNNYReactionStats?: number | null,
    WOWReactionStats?: number | null,
    SADReactionStats?: number | null,
    ANGRYReactionStats?: number | null,
    SCARYReactionStats?: number | null,
    POOPReactionStats?: number | null,
    GROSSReactionStats?: number | null,
    Reactions?:  {
      items:  Array< {
        userID: string,
        type: TaskReactionType,
        mltaskexecutionresultID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
      metaData?:  {
        FILE_NAME?: string | null,
        FILE_TYPE?: string | null,
        FILE_SIZE_KB?: number | null,
        DURATION?: number | null,
        WIDTH?: number | null,
        HEIGHT?: number | null,
        STRING_LENGTH?: number | null,
        TOKENS_COUNT?: number | null,
      } | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
    presetUserInput?:  {
      presetID?: string | null,
      presetVersion?: string | null,
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
      } | null > | null,
    } | null,
    metadata?:  {
      LumaLabsGenerationID?: string | null,
      ReplicatePredictionID?: string | null,
      RunwayMLGenerationID?: string | null,
    } | null,
    createdAt: string,
    mltasksworkspaceID: string,
    modelsID: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCreditTransactionsHistorySubscriptionVariables = {
  filter?: ModelSubscriptionCreditTransactionsHistoryFilterInput | null,
  userID?: string | null,
};

export type OnCreateCreditTransactionsHistorySubscription = {
  onCreateCreditTransactionsHistory?:  {
    id: string,
    eventID?: string | null,
    userID: string,
    cashAmountInCents?: number | null,
    creditAmount?: number | null,
    status?: CreditTransactionsHistoryStatus | null,
    transactionType?: CreditTransactionsHistoryType | null,
    context?:  {
      taskID?: string | null,
      presetID?: string | null,
      presetVersion?: string | null,
      subscriptionID?: string | null,
      priceID?: string | null,
      transactionID?: string | null,
      feedback?: string | null,
      comment?: string | null,
      reason?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCreditTransactionsHistorySubscriptionVariables = {
  filter?: ModelSubscriptionCreditTransactionsHistoryFilterInput | null,
  userID?: string | null,
};

export type OnUpdateCreditTransactionsHistorySubscription = {
  onUpdateCreditTransactionsHistory?:  {
    id: string,
    eventID?: string | null,
    userID: string,
    cashAmountInCents?: number | null,
    creditAmount?: number | null,
    status?: CreditTransactionsHistoryStatus | null,
    transactionType?: CreditTransactionsHistoryType | null,
    context?:  {
      taskID?: string | null,
      presetID?: string | null,
      presetVersion?: string | null,
      subscriptionID?: string | null,
      priceID?: string | null,
      transactionID?: string | null,
      feedback?: string | null,
      comment?: string | null,
      reason?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCreditTransactionsHistorySubscriptionVariables = {
  filter?: ModelSubscriptionCreditTransactionsHistoryFilterInput | null,
  userID?: string | null,
};

export type OnDeleteCreditTransactionsHistorySubscription = {
  onDeleteCreditTransactionsHistory?:  {
    id: string,
    eventID?: string | null,
    userID: string,
    cashAmountInCents?: number | null,
    creditAmount?: number | null,
    status?: CreditTransactionsHistoryStatus | null,
    transactionType?: CreditTransactionsHistoryType | null,
    context?:  {
      taskID?: string | null,
      presetID?: string | null,
      presetVersion?: string | null,
      subscriptionID?: string | null,
      priceID?: string | null,
      transactionID?: string | null,
      feedback?: string | null,
      comment?: string | null,
      reason?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateModelsSubscriptionVariables = {
  filter?: ModelSubscriptionModelsFilterInput | null,
};

export type OnCreateModelsSubscription = {
  onCreateModels?:  {
    id: string,
    isPublic: boolean,
    name?: string | null,
    usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
    inputs?:  {
      entries?:  Array< {
        type: ModelInputOutputEntryType,
        displayName?: string | null,
        backendName?: string | null,
        defaultValue?: string | null,
        optionType?: OptionType | null,
        optionStep?: number | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        isRequired?: boolean | null,
      } | null > | null,
    } | null,
    outputs?:  {
      entries?:  Array< {
        type?: ModelInputOutputEntryType | null,
        name?: string | null,
      } | null > | null,
    } | null,
    mltaskdataID: string,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateModelsSubscriptionVariables = {
  filter?: ModelSubscriptionModelsFilterInput | null,
};

export type OnUpdateModelsSubscription = {
  onUpdateModels?:  {
    id: string,
    isPublic: boolean,
    name?: string | null,
    usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
    inputs?:  {
      entries?:  Array< {
        type: ModelInputOutputEntryType,
        displayName?: string | null,
        backendName?: string | null,
        defaultValue?: string | null,
        optionType?: OptionType | null,
        optionStep?: number | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        isRequired?: boolean | null,
      } | null > | null,
    } | null,
    outputs?:  {
      entries?:  Array< {
        type?: ModelInputOutputEntryType | null,
        name?: string | null,
      } | null > | null,
    } | null,
    mltaskdataID: string,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteModelsSubscriptionVariables = {
  filter?: ModelSubscriptionModelsFilterInput | null,
};

export type OnDeleteModelsSubscription = {
  onDeleteModels?:  {
    id: string,
    isPublic: boolean,
    name?: string | null,
    usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
    inputs?:  {
      entries?:  Array< {
        type: ModelInputOutputEntryType,
        displayName?: string | null,
        backendName?: string | null,
        defaultValue?: string | null,
        optionType?: OptionType | null,
        optionStep?: number | null,
        description?: string | null,
        examples?: Array< string | null > | null,
        isRequired?: boolean | null,
      } | null > | null,
    } | null,
    outputs?:  {
      entries?:  Array< {
        type?: ModelInputOutputEntryType | null,
        name?: string | null,
      } | null > | null,
    } | null,
    mltaskdataID: string,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMLTaskDataSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskDataFilterInput | null,
};

export type OnCreateMLTaskDataSubscription = {
  onCreateMLTaskData?:  {
    id: string,
    name?: string | null,
    category?: string | null,
    isPublic: boolean,
    Models?:  {
      items:  Array< {
        id: string,
        isPublic: boolean,
        name?: string | null,
        usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
        mltaskdataID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMLTaskDataSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskDataFilterInput | null,
};

export type OnUpdateMLTaskDataSubscription = {
  onUpdateMLTaskData?:  {
    id: string,
    name?: string | null,
    category?: string | null,
    isPublic: boolean,
    Models?:  {
      items:  Array< {
        id: string,
        isPublic: boolean,
        name?: string | null,
        usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
        mltaskdataID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMLTaskDataSubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskDataFilterInput | null,
};

export type OnDeleteMLTaskDataSubscription = {
  onDeleteMLTaskData?:  {
    id: string,
    name?: string | null,
    category?: string | null,
    isPublic: boolean,
    Models?:  {
      items:  Array< {
        id: string,
        isPublic: boolean,
        name?: string | null,
        usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
        mltaskdataID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    runwayMLAPIKey?: string | null,
    SAIAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
        longUploadsStatus?: YoutubeChannelDataLongUploadStatus | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
    SocialManPosts?:  {
      items:  Array< {
        id: string,
        title?: string | null,
        description?: string | null,
        s3Key?: string | null,
        s3KeyResized?: string | null,
        userID: string,
        createdAt: string,
        scheduledAt?: string | null,
        postedAt?: string | null,
        status: SocialManPostStatus,
        postedToTiktok?: boolean | null,
        postedToLinkedin?: boolean | null,
        postedToPinterest?: boolean | null,
        postedToYoutube?: boolean | null,
        postedToFacebook?: boolean | null,
        postedToInstagram?: boolean | null,
        postedToTwitter?: boolean | null,
        enabledTiktok?: boolean | null,
        enabledLinkedin?: boolean | null,
        enabledPinterest?: boolean | null,
        enabledYoutube?: boolean | null,
        enabledFacebook?: boolean | null,
        enabledInstagram?: boolean | null,
        enabledTwitter?: boolean | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      items:  Array< {
        id: string,
        userID: string,
        HEARTReactionStats?: number | null,
        CUTEReactionStats?: number | null,
        FUNNYReactionStats?: number | null,
        WOWReactionStats?: number | null,
        SADReactionStats?: number | null,
        ANGRYReactionStats?: number | null,
        SCARYReactionStats?: number | null,
        POOPReactionStats?: number | null,
        GROSSReactionStats?: number | null,
        taskName?: string | null,
        modelName?: string | null,
        privacyLevel?: TaskPrivacyLevels | null,
        creditsUsed?: number | null,
        status?: TaskResultStatus | null,
        createdAt: string,
        mltasksworkspaceID: string,
        modelsID: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        description?: string | null,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      items:  Array< {
        id: string,
        presetName?: string | null,
        description?: string | null,
        cost?: number | null,
        usage?: number | null,
        isPublic?: boolean | null,
        isOpenSource?: boolean | null,
        versionName?: string | null,
        username?: string | null,
        taskName?: string | null,
        modelName?: string | null,
        modelsID: string,
        userID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSocialManPostSubscriptionVariables = {
  filter?: ModelSubscriptionSocialManPostFilterInput | null,
  userID?: string | null,
};

export type OnCreateSocialManPostSubscription = {
  onCreateSocialManPost?:  {
    id: string,
    title?: string | null,
    description?: string | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    userID: string,
    createdAt: string,
    scheduledAt?: string | null,
    postedAt?: string | null,
    status: SocialManPostStatus,
    tiktok?:  {
      title?: string | null,
      caption?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      contentDisclosureBrandedContent?: boolean | null,
      videoCoverTimestampMs?: number | null,
      postID?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      title?: string | null,
      description?: string | null,
      link?: string | null,
      thumbnailURL?: string | null,
      boardID?: string | null,
      pinID?: string | null,
    } | null,
    youtube?:  {
      title?: string | null,
      description?: string | null,
      tags?: string | null,
      thumbnailURL?: string | null,
      privacy?: string | null,
      category?: string | null,
      targetChannelID?: string | null,
      videoID?: string | null,
    } | null,
    instagram?:  {
      caption: string,
      thumbnail?: string | null,
      targetAccountID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
    } | null,
    facebook?:  {
      caption: string,
      thumbnail?: string | null,
      targetPageID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
      storyURL?: string | null,
    } | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    postedToTiktok?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToYoutube?: boolean | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToTwitter?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledYoutube?: boolean | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledTwitter?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateSocialManPostSubscriptionVariables = {
  filter?: ModelSubscriptionSocialManPostFilterInput | null,
  userID?: string | null,
};

export type OnUpdateSocialManPostSubscription = {
  onUpdateSocialManPost?:  {
    id: string,
    title?: string | null,
    description?: string | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    userID: string,
    createdAt: string,
    scheduledAt?: string | null,
    postedAt?: string | null,
    status: SocialManPostStatus,
    tiktok?:  {
      title?: string | null,
      caption?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      contentDisclosureBrandedContent?: boolean | null,
      videoCoverTimestampMs?: number | null,
      postID?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      title?: string | null,
      description?: string | null,
      link?: string | null,
      thumbnailURL?: string | null,
      boardID?: string | null,
      pinID?: string | null,
    } | null,
    youtube?:  {
      title?: string | null,
      description?: string | null,
      tags?: string | null,
      thumbnailURL?: string | null,
      privacy?: string | null,
      category?: string | null,
      targetChannelID?: string | null,
      videoID?: string | null,
    } | null,
    instagram?:  {
      caption: string,
      thumbnail?: string | null,
      targetAccountID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
    } | null,
    facebook?:  {
      caption: string,
      thumbnail?: string | null,
      targetPageID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
      storyURL?: string | null,
    } | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    postedToTiktok?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToYoutube?: boolean | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToTwitter?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledYoutube?: boolean | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledTwitter?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteSocialManPostSubscriptionVariables = {
  filter?: ModelSubscriptionSocialManPostFilterInput | null,
  userID?: string | null,
};

export type OnDeleteSocialManPostSubscription = {
  onDeleteSocialManPost?:  {
    id: string,
    title?: string | null,
    description?: string | null,
    s3Key?: string | null,
    s3KeyResized?: string | null,
    userID: string,
    createdAt: string,
    scheduledAt?: string | null,
    postedAt?: string | null,
    status: SocialManPostStatus,
    tiktok?:  {
      title?: string | null,
      caption?: string | null,
      privacy?: SocialManTiktokPrivacyStatus | null,
      usersCanComment?: boolean | null,
      usersCanDuet?: boolean | null,
      usersCanStitch?: boolean | null,
      contentDisclosureEnabled?: boolean | null,
      contentDisclosureYourBrand?: boolean | null,
      contentDisclosureBrandedContent?: boolean | null,
      videoCoverTimestampMs?: number | null,
      postID?: string | null,
    } | null,
    linkedin?:  {
      caption?: string | null,
      mediaDescription?: string | null,
      postID?: string | null,
    } | null,
    pinterest?:  {
      title?: string | null,
      description?: string | null,
      link?: string | null,
      thumbnailURL?: string | null,
      boardID?: string | null,
      pinID?: string | null,
    } | null,
    youtube?:  {
      title?: string | null,
      description?: string | null,
      tags?: string | null,
      thumbnailURL?: string | null,
      privacy?: string | null,
      category?: string | null,
      targetChannelID?: string | null,
      videoID?: string | null,
    } | null,
    instagram?:  {
      caption: string,
      thumbnail?: string | null,
      targetAccountID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
    } | null,
    facebook?:  {
      caption: string,
      thumbnail?: string | null,
      targetPageID?: string | null,
      postToStory?: boolean | null,
      postID?: string | null,
      storyID?: string | null,
      storyURL?: string | null,
    } | null,
    twitter?:  {
      caption: string,
      tweetID?: string | null,
    } | null,
    postedToTiktok?: boolean | null,
    postedToLinkedin?: boolean | null,
    postedToPinterest?: boolean | null,
    postedToYoutube?: boolean | null,
    postedToFacebook?: boolean | null,
    postedToInstagram?: boolean | null,
    postedToTwitter?: boolean | null,
    enabledTiktok?: boolean | null,
    enabledLinkedin?: boolean | null,
    enabledPinterest?: boolean | null,
    enabledYoutube?: boolean | null,
    enabledFacebook?: boolean | null,
    enabledInstagram?: boolean | null,
    enabledTwitter?: boolean | null,
    updatedAt: string,
  } | null,
};

export type updateUsernameMutationVariables = {
  userID: string,
  username: string,
};

export type updateUsernameMutation = {
  updateUserUsername?:  {
    id: string,
    username?: string | null,
  } | null,
};

export type UpdateUserCustomMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserCustomMutation = {
  updateUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    MLTaskExecutionResults?:  {
      nextToken?: string | null,
    } | null,
    MLTasksWorkspaces?:  {
      nextToken?: string | null,
    } | null,
    ModelInputPresets?:  {
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserRemoveNetworkMutationVariables = {
  input: UpdateUserInput,
};

export type UpdateUserRemoveNetworkMutation = {
  updateUser?:  {
    facebook?:  {
      instagramsData:  Array< {
        accessToken?: string | null,
        biography?: string | null,
        connectedPageID?: string | null,
        expiresOn?: number | null,
        followersCount?: number | null,
        followsCount?: number | null,
        id?: string | null,
        igID?: string | null,
        mediaCount?: number | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
      } | null >,
      pagesData:  Array< {
        accessToken?: string | null,
        category?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      } | null >,
      userData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        id?: string | null,
        name?: string | null,
        picture?: string | null,
      },
    } | null,
    google?:  {
      channelsData:  Array< {
        customUrl?: string | null,
        description?: string | null,
        id?: string | null,
        thumbnail?: string | null,
        title?: string | null,
      } | null >,
      tokenData:  {
        accessToken?: string | null,
        expiresOn?: number | null,
        idToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
      },
      userData:  {
        email?: string | null,
        familyName?: string | null,
        givenName?: string | null,
        id?: string | null,
        locale?: string | null,
        name?: string | null,
        picture?: string | null,
        verifiedEmail?: boolean | null,
      },
    } | null,
    id: string,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    pinterest?:  {
      boardsData?:  Array< {
        createdAt?: string | null,
        description?: string | null,
        followerCount?: number | null,
        id?: string | null,
        imageCoverUrl?: string | null,
        name?: string | null,
        pinCount?: number | null,
        privacy?: string | null,
      } | null > | null,
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        about?: string | null,
        accountType?: string | null,
        boardCount?: number | null,
        businessName?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        id?: string | null,
        monthlyViews?: number | null,
        pinCount?: number | null,
        profileImage?: string | null,
        username?: string | null,
        websiteUrl?: string | null,
      },
    } | null,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        refreshTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        avatarLargeURL?: string | null,
        avatarURL?: string | null,
        avatarURL100?: string | null,
        bioDescription?: string | null,
        commentDisabled?: boolean | null,
        displayName?: string | null,
        duetDisabled?: boolean | null,
        followerCount?: number | null,
        followingCount?: number | null,
        isVerified?: boolean | null,
        likesCount?: number | null,
        maxVideoPostDuration_sec?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        profileDeepLink?: string | null,
        stitchDisabled?: boolean | null,
        username?: string | null,
        videoCount?: number | null,
      },
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        codeVerifier?: string | null,
        expiresOn?: number | null,
        oauthTokenSecret?: string | null,
        refreshToken?: string | null,
      },
      userData:  {
        description?: string | null,
        id?: string | null,
        name?: string | null,
        profilePictureURL?: string | null,
        username?: string | null,
        verifiedType?: TwitterVerifiedType | null,
      },
    } | null,
  } | null,
};

export type CustomCreateModelsMutationVariables = {
  input: CreateModelsInput,
  condition?: ModelModelsConditionInput | null,
};

export type CustomCreateModelsMutation = {
  createModels?:  {
    isPublic: boolean,
    name?: string | null,
    usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
    inputs?:  {
    } | null,
    outputs?:  {
    } | null,
    mltaskdataID: string,
    ModelInputPresets?:  {
      nextToken?: string | null,
    } | null,
    MLTaskExecutionResults?:  {
      nextToken?: string | null,
    } | null,
  } | null,
};

export type listSocialManPostsMinifiedDataByCreatedAtQueryVariables = {
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelSocialManPostFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userID: string,
};

export type listSocialManPostsMinifiedDataByCreatedAtQuery = {
  listSocialManPostsByCreatedAt?:  {
    items:  Array< {
      createdAt: string,
      scheduledAt?: string | null,
      description?: string | null,
      id: string,
      s3Key?: string | null,
      s3KeyResized?: string | null,
      status: SocialManPostStatus,
      title?: string | null,
      updatedAt: string,
      userID: string,
      facebook?:  {
        thumbnail?: string | null,
      } | null,
      youtube?:  {
        thumbnailURL?: string | null,
      } | null,
      instagram?:  {
        thumbnail?: string | null,
      } | null,
      pinterest?:  {
        thumbnailURL?: string | null,
      } | null,
      postedAt?: string | null,
      postedToFacebook?: boolean | null,
      postedToInstagram?: boolean | null,
      postedToPinterest?: boolean | null,
      postedToTiktok?: boolean | null,
      postedToTwitter?: boolean | null,
      postedToYoutube?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type getUserFromUsernameQueryVariables = {
  username: string,
};

export type getUserFromUsernameQuery = {
  getUserFromUsername?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserBasicQueryVariables = {
  id: string,
};

export type GetUserBasicQuery = {
  getUser?:  {
    id: string,
    name?: string | null,
    username?: string | null,
    openAIAPIKey?: string | null,
    FalAPIKey?: string | null,
    nimsAPIKey?: string | null,
    SAIAPIKey?: string | null,
    anthropicAPIKey?: string | null,
    replicateAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    currentSubscriptionPlan?: SubscriptionPlan | null,
    periodEndOfLastSubscriptionCredits?: number | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    stripeCustomerID?: string | null,
    stripeConnectAccountID?: string | null,
    stripeConnectAccountSetupComplete?: boolean | null,
    email?: string | null,
    picture?: string | null,
    credits?: number | null,
    redeemableCredits?: number | null,
    createdAt: string,
    updatedAt: string,
    tiktok?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        avatarURL?: string | null,
        avatarURL100?: string | null,
        avatarLargeURL?: string | null,
        displayName?: string | null,
        bioDescription?: string | null,
        profileDeepLink?: string | null,
        isVerified?: boolean | null,
        username?: string | null,
        followerCount?: number | null,
        followingCount?: number | null,
        likesCount?: number | null,
        videoCount?: number | null,
        privacyLevelOptions?: Array< string | null > | null,
        commentDisabled?: boolean | null,
        duetDisabled?: boolean | null,
        stitchDisabled?: boolean | null,
        maxVideoPostDuration_sec?: number | null,
      },
    } | null,
    pinterest?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        refreshToken: string,
        scope: string,
        refreshTokenExpirationDate: number,
      },
      userData:  {
        accountType?: string | null,
        id?: string | null,
        profileImage?: string | null,
        websiteUrl?: string | null,
        username?: string | null,
        about?: string | null,
        businessName?: string | null,
        boardCount?: number | null,
        pinCount?: number | null,
        followerCount?: number | null,
        followingCount?: number | null,
        monthlyViews?: number | null,
      },
      boardsData?:  Array< {
        id?: string | null,
        name?: string | null,
        pinCount?: number | null,
        followerCount?: number | null,
        imageCoverUrl?: string | null,
        createdAt?: string | null,
        description?: string | null,
        privacy?: string | null,
      } | null > | null,
    } | null,
    linkedin?:  {
      tokenData:  {
        accessToken: string,
        accessTokenExpirationDate: number,
        scope: string,
      },
      userData:  {
        email?: string | null,
        name?: string | null,
        picture?: string | null,
        sub?: string | null,
      },
    } | null,
    google?:  {
      tokenData:  {
        accessToken?: string | null,
        refreshToken?: string | null,
        scope?: string | null,
        tokenType?: string | null,
        idToken?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        email?: string | null,
        verifiedEmail?: boolean | null,
        name?: string | null,
        givenName?: string | null,
        familyName?: string | null,
        picture?: string | null,
        locale?: string | null,
      },
      channelsData:  Array< {
        id?: string | null,
        customUrl?: string | null,
        title?: string | null,
        description?: string | null,
        thumbnail?: string | null,
      } | null >,
    } | null,
    twitter?:  {
      tokenData:  {
        accessToken?: string | null,
        accessTokenSecret?: string | null,
        refreshToken?: string | null,
        codeVerifier?: string | null,
        oauthTokenSecret?: string | null,
        expiresOn?: number | null,
      },
      userData:  {
        id?: string | null,
        name?: string | null,
        username?: string | null,
        description?: string | null,
        verifiedType?: TwitterVerifiedType | null,
        profilePictureURL?: string | null,
      },
    } | null,
    facebook?:  {
      userData:  {
        id?: string | null,
        name?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      },
      pagesData:  Array< {
        id?: string | null,
        name?: string | null,
        category?: string | null,
        picture?: string | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
      instagramsData:  Array< {
        connectedPageID?: string | null,
        id?: string | null,
        igID?: string | null,
        biography?: string | null,
        name?: string | null,
        username?: string | null,
        profilePictureURL?: string | null,
        followsCount?: number | null,
        followersCount?: number | null,
        mediaCount?: number | null,
        accessToken?: string | null,
        expiresOn?: number | null,
      } | null >,
    } | null,
  } | null,
};

export type BatchGetUsersBasicQueryVariables = {
  ids: Array< string | null >,
};

export type BatchGetUsersBasicQuery = {
  batchGetUsers?:  Array< {
    id: string,
    name?: string | null,
    username?: string | null,
    createdPresetCount?: number | null,
    completedTasksCount?: number | null,
    picture?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetModelsTaskIDQueryVariables = {
  id: string,
};

export type GetModelsTaskIDQuery = {
  getModels?:  {
    id: string,
    mltaskdataID: string,
  } | null,
};

export type GetModelInputPresetFullQueryVariables = {
  id: string,
};

export type GetModelInputPresetFullQuery = {
  getModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    modelsID: string,
    userID: string,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    cost?: number | null,
    createdAt: string,
    updatedAt: string,
    usage?: number | null,
    versionHistory?:  Array< {
      cost?: number | null,
      versionName?: string | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
        presetEntryDatas?:  Array< {
          entryType?: InputPresetEntryType | null,
          parameterName?: string | null,
          description?: string | null,
          val?: string | null,
          presetOrder?: number | null,
          metaData?:  {
            FILE_NAME?: string | null,
            FILE_TYPE?: string | null,
            FILE_SIZE_KB?: number | null,
            DURATION?: number | null,
            WIDTH?: number | null,
            HEIGHT?: number | null,
            STRING_LENGTH?: number | null,
            TOKENS_COUNT?: number | null,
          } | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    versionName?: string | null,
    entries?:  Array< {
      modelInputName?: string | null,
      modelInputType?: ModelInputOutputEntryType | null,
      presetEntryDatas?:  Array< {
        entryType?: InputPresetEntryType | null,
        parameterName?: string | null,
        description?: string | null,
        val?: string | null,
        presetOrder?: number | null,
        metaData?:  {
          FILE_NAME?: string | null,
          FILE_TYPE?: string | null,
          FILE_SIZE_KB?: number | null,
          DURATION?: number | null,
          WIDTH?: number | null,
          HEIGHT?: number | null,
          STRING_LENGTH?: number | null,
          TOKENS_COUNT?: number | null,
        } | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetModelInputPresetMiniQueryVariables = {
  id: string,
};

export type GetModelInputPresetMiniQuery = {
  getModelInputPreset?:  {
    id: string,
    presetName?: string | null,
    description?: string | null,
    isPublic?: boolean | null,
    isOpenSource?: boolean | null,
    modelsID: string,
    userID: string,
    username?: string | null,
    taskName?: string | null,
    modelName?: string | null,
    cost?: number | null,
    createdAt: string,
    updatedAt: string,
    usage?: number | null,
    versionName?: string | null,
  } | null,
};

export type ModelInputPresetsByUserIDCustomQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ModelInputPresetsByUserIDCustomQuery = {
  modelInputPresetsByUserID?:  {
    items:  Array< {
      id: string,
      presetName?: string | null,
      description?: string | null,
      isPublic?: boolean | null,
      isOpenSource?: boolean | null,
      modelsID: string,
      userID: string,
      username?: string | null,
      taskName?: string | null,
      modelName?: string | null,
      cost?: number | null,
      createdAt: string,
      updatedAt: string,
      usage?: number | null,
      versionHistory?:  Array< {
        cost?: number | null,
        versionName?: string | null,
        entries?:  Array< {
          modelInputName?: string | null,
          modelInputType?: ModelInputOutputEntryType | null,
          presetEntryDatas?:  Array< {
            entryType?: InputPresetEntryType | null,
            parameterName?: string | null,
            description?: string | null,
            val?: string | null,
            presetOrder?: number | null,
            metaData?:  {
              FILE_NAME?: string | null,
              FILE_TYPE?: string | null,
              FILE_SIZE_KB?: number | null,
              DURATION?: number | null,
              WIDTH?: number | null,
              HEIGHT?: number | null,
              STRING_LENGTH?: number | null,
              TOKENS_COUNT?: number | null,
            } | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
      versionName?: string | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
        presetEntryDatas?:  Array< {
          entryType?: InputPresetEntryType | null,
          parameterName?: string | null,
          description?: string | null,
          val?: string | null,
          presetOrder?: number | null,
          metaData?:  {
            FILE_NAME?: string | null,
            FILE_TYPE?: string | null,
            FILE_SIZE_KB?: number | null,
            WIDTH?: number | null,
            HEIGHT?: number | null,
            STRING_LENGTH?: number | null,
            TOKENS_COUNT?: number | null,
          } | null,
        } | null > | null,
      } | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ModelInputPresetsByModelsIDCustomQueryVariables = {
  modelsID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelModelInputPresetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ModelInputPresetsByModelsIDCustomQuery = {
  modelInputPresetsByModelsID?:  {
    items:  Array< {
      id: string,
      presetName?: string | null,
      description?: string | null,
      isPublic?: boolean | null,
      isOpenSource?: boolean | null,
      modelsID: string,
      userID: string,
      username?: string | null,
      taskName?: string | null,
      modelName?: string | null,
      cost?: number | null,
      createdAt: string,
      updatedAt: string,
      usage?: number | null,
      versionHistory?:  Array< {
        cost?: number | null,
        versionName?: string | null,
        entries?:  Array< {
          modelInputName?: string | null,
          modelInputType?: ModelInputOutputEntryType | null,
          presetEntryDatas?:  Array< {
            entryType?: InputPresetEntryType | null,
            parameterName?: string | null,
            description?: string | null,
            val?: string | null,
            presetOrder?: number | null,
            metaData?:  {
              FILE_NAME?: string | null,
              FILE_TYPE?: string | null,
              FILE_SIZE_KB?: number | null,
              DURATION?: number | null,
              WIDTH?: number | null,
              HEIGHT?: number | null,
              STRING_LENGTH?: number | null,
              TOKENS_COUNT?: number | null,
            } | null,
          } | null > | null,
        } | null > | null,
      } | null > | null,
      versionName?: string | null,
      entries?:  Array< {
        modelInputName?: string | null,
        modelInputType?: ModelInputOutputEntryType | null,
        presetEntryDatas?:  Array< {
          entryType?: InputPresetEntryType | null,
          parameterName?: string | null,
          description?: string | null,
          val?: string | null,
          presetOrder?: number | null,
          metaData?:  {
            FILE_NAME?: string | null,
            FILE_TYPE?: string | null,
            FILE_SIZE_KB?: number | null,
            WIDTH?: number | null,
            HEIGHT?: number | null,
            STRING_LENGTH?: number | null,
            TOKENS_COUNT?: number | null,
          } | null,
        } | null > | null,
      } | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnUpdateMLTaskExecutionResult2SubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnUpdateMLTaskExecutionResult2Subscription = {
  onUpdateMLTaskExecutionResult?:  {
    id: string,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
    } | null > | null,
    presetUserInput?:  {
      presetName?: string | null,
      presetCreatorName?: string | null,
      presetCreatorUID?: string | null,
      presetValues?:  Array< {
        modelInputName?: string | null,
        presetEntryDatas?:  Array< {
          val?: string | null,
          presetOrder?: number | null,
          parameterName?: string | null,
        } | null > | null,
      } | null > | null,
    } | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    creditsUsed?: number | null,
    status?: TaskResultStatus | null,
  } | null,
};

export type OnCreateMLTaskExecutionResult2SubscriptionVariables = {
  filter?: ModelSubscriptionMLTaskExecutionResultFilterInput | null,
  userID?: string | null,
};

export type OnCreateMLTaskExecutionResult2Subscription = {
  onCreateMLTaskExecutionResult?:  {
    id: string,
    userID: string,
    taskName?: string | null,
    modelName?: string | null,
    privacyLevel?: TaskPrivacyLevels | null,
    inputs?:  Array< {
      name?: string | null,
      value?: string | null,
      type?: ModelInputOutputEntryType | null,
    } | null > | null,
    output?:  {
      message?: string | null,
      entries?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
    } | null,
    status?: TaskResultStatus | null,
  } | null,
};

export type getMLTaskDataQueryQueryVariables = {
  id: string,
};

export type getMLTaskDataQueryQuery = {
  getMLTaskData?:  {
    id: string,
    name?: string | null,
    category?: string | null,
    isPublic: boolean,
    Models?:  {
      items:  Array< {
        id: string,
        name?: string | null,
        usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
        mltaskdataID: string,
        outputs?:  {
          entries?:  Array< {
            name?: string | null,
            type?: ModelInputOutputEntryType | null,
          } | null > | null,
        } | null,
        inputs?:  {
          entries?:  Array< {
            defaultValue?: string | null,
            description?: string | null,
            examples?: Array< string | null > | null,
            displayName?: string | null,
            backendName?: string | null,
            optionType?: OptionType | null,
            options?:  Array< {
              name?: string | null,
              value?: string | null,
              description?: string | null,
            } | null > | null,
            optionStep?: number | null,
            type: ModelInputOutputEntryType,
            constraints?:  Array< {
              type?: ModelInputConstraintType | null,
              value?: string | null,
            } | null > | null,
          } | null > | null,
        } | null,
      } | null >,
    } | null,
  } | null,
};

export type ListTasksQueryVariables = {
  filter?: ModelMLTaskDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTasksQuery = {
  listMLTaskData?:  {
    nextToken?: string | null,
    items:  Array< {
      id: string,
      name?: string | null,
      category?: string | null,
      isPublic: boolean,
      Models?:  {
        items:  Array< {
          id: string,
          name?: string | null,
          usesAPIKeys?: Array< ThirdPartyAPIKeysNames | null > | null,
          mltaskdataID: string,
          outputs?:  {
            entries?:  Array< {
              name?: string | null,
              type?: ModelInputOutputEntryType | null,
            } | null > | null,
          } | null,
          inputs?:  {
            entries?:  Array< {
              defaultValue?: string | null,
              description?: string | null,
              examples?: Array< string | null > | null,
              displayName?: string | null,
              backendName?: string | null,
              optionType?: OptionType | null,
              isRequired?: boolean | null,
              cost?:  {
                tokenizerModel?: TokenizerModel | null,
                costPerToken?: number | null,
                costPerCharacter?: number | null,
                costPerValue?: number | null,
                costPerWord?: number | null,
                costInterpolateMin?: number | null,
                costInterpolateMax?: number | null,
                costPerFileSize?: number | null,
                costPerFileDuration?: number | null,
                flatFee?: number | null,
                isMultiplier?: boolean | null,
              } | null,
              costWithAPIKey?:  {
                tokenizerModel?: TokenizerModel | null,
                costPerToken?: number | null,
                costPerCharacter?: number | null,
                costPerValue?: number | null,
                costPerWord?: number | null,
                costInterpolateMin?: number | null,
                costInterpolateMax?: number | null,
                costPerFileSize?: number | null,
                costPerFileDuration?: number | null,
                flatFee?: number | null,
                isMultiplier?: boolean | null,
              } | null,
              options?:  Array< {
                name?: string | null,
                value?: string | null,
                description?: string | null,
                cost?: number | null,
                costWithAPIKey?: number | null,
              } | null > | null,
              optionStep?: number | null,
              type: ModelInputOutputEntryType,
              constraints?:  Array< {
                type?: ModelInputConstraintType | null,
                value?: string | null,
              } | null > | null,
            } | null > | null,
          } | null,
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type getUserExecutedTasksQueryVariables = {
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  userID: string,
};

export type getUserExecutedTasksQuery = {
  listMLTaskExecutionResultsByCreatedAt?:  {
    items:  Array< {
      ANGRYReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      HEARTReactionStats?: number | null,
      POOPReactionStats?: number | null,
      SADReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      id: string,
      mltasksworkspaceID: string,
      userID: string,
      createdAt: string,
      status?: TaskResultStatus | null,
      creditsUsed?: number | null,
      modelName?: string | null,
      privacyLevel?: TaskPrivacyLevels | null,
      modelsID: string,
      taskName?: string | null,
      inputs?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
      output?:  {
        message?: string | null,
        entries?:  Array< {
          name?: string | null,
          type?: ModelInputOutputEntryType | null,
          value?: string | null,
        } | null > | null,
      } | null,
      presetUserInput?:  {
        presetID?: string | null,
        presetName?: string | null,
        presetVersion?: string | null,
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
        presetValues?:  Array< {
          modelInputName?: string | null,
          presetEntryDatas?:  Array< {
            parameterName?: string | null,
            val?: string | null,
            presetOrder?: number | null,
          } | null > | null,
        } | null > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListMLTaskExecutionResultsQueryVariables = {
  filter?: ModelMLTaskExecutionResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListMLTaskExecutionResultsQuery = {
  listMLTaskExecutionResults?:  {
    items:  Array< {
      id: string,
      mltasksworkspaceID: string,
      ANGRYReactionStats?: number | null,
      CUTEReactionStats?: number | null,
      FUNNYReactionStats?: number | null,
      GROSSReactionStats?: number | null,
      HEARTReactionStats?: number | null,
      POOPReactionStats?: number | null,
      SADReactionStats?: number | null,
      SCARYReactionStats?: number | null,
      WOWReactionStats?: number | null,
      userID: string,
      createdAt: string,
      status?: TaskResultStatus | null,
      creditsUsed?: number | null,
      modelName?: string | null,
      privacyLevel?: TaskPrivacyLevels | null,
      modelsID: string,
      taskName?: string | null,
      inputs?:  Array< {
        name?: string | null,
        value?: string | null,
        type?: ModelInputOutputEntryType | null,
      } | null > | null,
      output?:  {
        message?: string | null,
        entries?:  Array< {
          name?: string | null,
          type?: ModelInputOutputEntryType | null,
          value?: string | null,
        } | null > | null,
      } | null,
      presetUserInput?:  {
        presetID?: string | null,
        presetName?: string | null,
        presetCreatorName?: string | null,
        presetCreatorUID?: string | null,
        presetValues?:  Array< {
          modelInputName?: string | null,
          presetEntryDatas?:  Array< {
            parameterName?: string | null,
            val?: string | null,
            presetOrder?: number | null,
          } | null > | null,
        } | null > | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserCreditsQueryVariables = {
  id: string,
};

export type GetUserCreditsQuery = {
  getUser?:  {
    id: string,
    credits?: number | null,
  } | null,
};
