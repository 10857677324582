"use client";
import SimpleLoadingSpinner from "@/components/ui/Loading/SimpleLoadingSpinner";
import ProfileSVG from "@/components/ui/SVGIcons/ProfileSVG";
import { cn } from "@/lib/utils";
import BackendManager from "@/utils/BackendManager";
import {
  getCreatePresetPath,
  getSettingsPath,
  getUserPath,
  socialManHomePath,
} from "@/utils/NavigationUtils";
import { Menu, Transition } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import Link from "next/link";
import { Fragment, useState } from "react";
import { RiAddCircleFill } from "react-icons/ri";
import LogoString from "../Theming/LogoString";
import ProfileImage from "../User/ProfileImage";
type Props = { usernameOrEmail: string; ppURL: string };

export default function NavigationProfileDropdown({
  usernameOrEmail,
  ppURL,
}: Props) {
  const [loggingOut, setLoggingOut] = useState(false);
  const menuOffset = "mt-4";
  const queryClient = useQueryClient();
  function logOutUser() {
    setLoggingOut(true);
    BackendManager.signUserOut();
  }
  function renderClearQueries() {
    if (process.env.NODE_ENV !== "development") return;
    return (
      <Menu.Item>
        {({ active }) => (
          <Link
            onClick={() => {
              queryClient.removeQueries();
            }}
            className={cn(
              active ? "bg-base-content bg-opacity-10 " : "",
              "flex w-full flex-row rounded-md px-4 py-2 text-sm text-base-content"
            )}
            href="/"
          >
            Clear Queries
          </Link>
        )}
      </Menu.Item>
    );
  }

  function renderLogoutItem() {
    return (
      <div className="py-1">
        {/* <div className="divider m-0 p-0"></div> */}
        {renderClearQueries()}
        <Menu.Item disabled={loggingOut}>
          {({ active }) => (
            <Link
              onClick={() => {
                logOutUser();
              }}
              className={cn(
                active ? "bg-base-content bg-opacity-10 " : "",
                "flex w-full flex-row rounded-md px-4 py-2 text-sm text-base-content"
              )}
              href="/"
            >
              Logout
              {loggingOut && (
                <div className="my-auto ml-2">
                  <SimpleLoadingSpinner size={4} />
                </div>
              )}
            </Link>
          )}
        </Menu.Item>
      </div>
    );
  }
  function renderProfileItem() {
    return (
      <div className="py-1">
        <Menu.Item>
          {({ active }) => (
            <Link
              className={cn(
                active ? "bg-base-content bg-opacity-10 " : "",
                "block rounded-md px-4 py-2 text-sm text-base-content"
              )}
              href={getUserPath()}
            >
              Profile
            </Link>
          )}
        </Menu.Item>
      </div>
    );
  }
  function renderCreatePresetItem() {
    return (
      <div className="py-1">
        <Menu.Item>
          {({ active }) => (
            <Link
              className={cn(
                active ? "bg-base-content bg-opacity-10 " : "",
                "block rounded-md px-4 py-2 text-sm text-base-content"
              )}
              href={getCreatePresetPath()}
            >
              <div className="flex flex-row items-center justify-between space-x-1">
                <span>Preset</span>
                <RiAddCircleFill className="text-success" />
              </div>
            </Link>
          )}
        </Menu.Item>
      </div>
    );
  }
  function renderGoToSocialManItem() {
    return (
      <div className="py-1">
        <Menu.Item>
          {({ active }) => (
            <Link
              className={cn(
                active ? "bg-base-content bg-opacity-10 " : "",
                "block rounded-md px-4 py-2 text-sm text-base-content"
              )}
              href={socialManHomePath()}
            >
              <LogoString logoString={"SocialMan"} isSocialMan={true} />
            </Link>
          )}
        </Menu.Item>
      </div>
    );
  }
  function renderSettingsItem() {
    return (
      <div className="py-1">
        <Menu.Item>
          {({ active }) => (
            <Link
              className={cn(
                active ? "bg-base-content bg-opacity-10 " : "",
                "block rounded-md px-4 py-2 text-sm text-base-content"
              )}
              href={getSettingsPath()}
            >
              Settings
            </Link>
          )}
        </Menu.Item>
      </div>
    );
  }
  function renderRoundProfileButton() {
    return (
      <Menu.Button className="inline-flex w-full justify-center rounded-full">
        <div
          className={`flex rounded-full bg-base-400 bg-secondary text-center text-sm font-medium text-base-400 transition-all duration-200 hover:bg-opacity-40 hover:p-1 hover:text-accent`}
        >
          {ppURL?.length != 0 && (
            <div className="pointer-events-none mx-auto aspect-square h-7 w-7 overflow-hidden rounded-full bg-secondary">
              <div className="my-auto flex h-full w-full overflow-hidden rounded-full p-px">
                <div className="flex h-full w-full overflow-hidden rounded-full">
                  <ProfileImage
                    isPriority={true}
                    quality={100}
                    alt="Your profile picture"
                    startupLink={ppURL}
                  />
                </div>
              </div>
            </div>
          )}
          {ppURL?.length == 0 && (
            <div>
              <ProfileSVG size={5} className="m-1 fill-current" />
            </div>
          )}
        </div>
      </Menu.Button>
    );
  }

  function renderUserNameOrEmail() {
    return (
      <div className="text-s px-2 py-3">
        <div>{usernameOrEmail}</div>
      </div>
    );
  }
  function renderActions() {
    return (
      <div className="flex flex-col">
        {renderCreatePresetItem()}
        {renderGoToSocialManItem()}
      </div>
    );
  }
  function renderProfileAndSettings() {
    return (
      <div className="flex flex-col">
        {renderProfileItem()}
        {renderSettingsItem()}
      </div>
    );
  }
  function newMenu() {
    return (
      <Menu as="div" className="relative text-left">
        <div className="my-auto flex ">{renderRoundProfileButton()}</div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute right-0 z-10 ${menuOffset}  origin-top-right divide-y divide-base-content divide-opacity-20 rounded-md bg-base-200 shadow-lg `}
          >
            {renderUserNameOrEmail()}
            {renderActions()}
            {renderProfileAndSettings()}
            {renderLogoutItem()}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  return <div>{newMenu()}</div>;
}
