export function getCreateModelPath() {
  return `/admin/createmodel`;
}
export function getUpdateModelPath() {
  return `/admin/updatemodel`;
}
export function getCreateTaskPath() {
  return `/admin/createtask`;
}
export function getUpdateTaskPath() {
  return `/admin/updatetask`;
}
export function getExploreTaskExecutionResultPath(taskID: string) {
  return `/admin/debugtaskresult/${taskID}`;
}
export function getExploreUserPath(userID: string) {
  return `/admin/debuguser/${userID}`;
}
export function getExploreSocialManPostPath(taskID: string) {
  return `/admin/debugsocialmanpost/${taskID}`;
}
export function getBasePath() {
  if (typeof window !== "undefined")
    return `${window.location.protocol}//${window.location.host}`;
  return "";
}
export function getLoginPath() {
  return `/login`;
}
export function getSignupPath() {
  return `/signup`;
}
export function getHomePath() {
  return `/home`;
}

export function getUserPath(userID: string | undefined = undefined) {
  if (userID == undefined) return "/user";
  return `/user/${userID}`;
}
export function getSettingsPath() {
  return "/user/settings";
}
export function getUserComfyUIPath() {
  return `${getSettingsPath()}/comfyui`;
}
export function getUserSettingsSocialNetworks() {
  return `${getSettingsPath()}/socialNetworks`;
}
export function getPresetPath(presetID: string) {
  return `${getHomePath()}/preset/${presetID}`;
}
export function getCreatePresetPath() {
  return `/preset/create`;
}
export function getCreatePresetPathWithTargetInput(targetModelID: string) {
  return `/preset/create?modelid=${targetModelID}`;
}
export function getShareblePresetPath(presetID: string) {
  return `/preset/${presetID}`;
}
export function subscriptionPricingPath() {
  return `/pricing`;
}
export function socialBaseManPath() {
  return `/socialman`;
}
export function socialManHomePath() {
  return `${socialBaseManPath()}/home`;
}
export function socialManPostViewPath(postID: string) {
  return `${socialManHomePath()}/${postID}`;
}
export function socialManPostReViewPath(postID: string) {
  return `${socialManHomePath()}/${postID}/review`;
}
