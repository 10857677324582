// import awsExports from "../aws-exports";
import {
  LocalSocialManPost,
  LocalSocialManTiktokPrivacyStatus,
  PagedLocalSocialManPost,
  SocialManNetworks,
} from "@/app/SocialManmodeltypings";
import {
  LocalModelInputPreset,
  LocalModelInputPresetEntryInput,
  LocalReactionType,
  LocalTaskPrivacyLevels,
  LocalUser,
  MLTaskPropertyEntryData,
  MLTasksData,
  ModelInput,
  ModelsInfo,
  PagedLocalModelInputPresetResults,
} from "@/modeltypings";
import { PagedTaskResults, TaskExecutionCardProps } from "@/typings";
import Constants from "@/utils/Constants";
import * as converter from "@/utils/graphql/convertionLayer";
import { GraphQLAuthMode } from "@aws-amplify/core/internals/utils";
import { PresetErrorCodes } from "@mltask/core/ErrorHandling/PresetErrors";
import { UserErrorCodes } from "@mltask/core/ErrorHandling/UserErrors";
import { dateIn1HourFromNow } from "@mltask/core/Utils/DateTimeHelper";
import { countTokens } from "@mltask/core/Utils/QuotaHelper";
import {
  BatchGetUsersQuery,
  CreateMLTaskDataMutationVariables,
  CreateModelInputPresetMutation,
  CreateModelInputPresetMutationVariables,
  CreateModelsMutation,
  CreateModelsMutationVariables,
  CreateSocialManPostInput,
  CreateSocialManPostMutation,
  CreateSocialManPostMutationVariables,
  CreateTaskReactionInput,
  CreateTaskReactionMutation,
  DeleteMLTaskExecutionResultMutation,
  DeleteMLTasksWorkspaceMutation,
  DeleteModelInputPresetMutation,
  DeleteModelsMutation,
  DeleteSocialManPostMutation,
  DeleteSocialManPostMutationVariables,
  GetMLTaskDataQuery,
  GetMLTaskDataQueryVariables,
  GetMLTaskExecutionResultQuery,
  GetMLTaskExecutionResultQueryVariables,
  GetModelInputPresetQuery,
  GetSocialManPostQuery,
  GetUserCreditsQuery,
  GetUserFromUsernameQuery,
  GetUserQuery,
  InputPresetEntryType,
  ListMLTaskExecutionResultsQuery,
  ListSocialManPostsByCreatedAtQuery,
  ListTasksQuery,
  MLTaskData,
  ModelInputOutputEntryType,
  ModelInputPreset,
  ModelInputPresetEntryInput,
  ModelInputPresetsByModelsIDCustomQuery,
  ModelInputPresetsByUserIDCustomQuery,
  ModelInputPresetsByUserIDQueryVariables,
  ModelMLTaskDataFilterInput,
  ModelSortDirection,
  ModelSubscriptionMLTaskExecutionResultFilterInput,
  ModelSubscriptionSocialManPostFilterInput,
  OnCreateMLTaskExecutionResultSubscription,
  OnUpdateMLTaskExecutionResultSubscription,
  OnUpdateMLTaskExecutionResultSubscriptionVariables,
  OnUpdateSocialManPostSubscription,
  OnUpdateSocialManPostSubscriptionVariables,
  OnUpdateUserSubscription,
  OnUpdateUserSubscriptionVariables,
  SocialManPost,
  SocialManPostStatus,
  SocialManTiktokPrivacyStatus,
  ThirdPartyAPIKeysNames,
  TokenizerModel,
  UpdateMLTaskDataMutation,
  UpdateMLTaskDataMutationVariables,
  UpdateMLTaskExecutionResultMutation,
  UpdateMLTaskExecutionResultMutationVariables,
  UpdateModelInputPresetMutation,
  UpdateModelsMutation,
  UpdateModelsMutationVariables,
  UpdateSocialManPostInput,
  UpdateSocialManPostMutation,
  UpdateSocialManPostMutationVariables,
  UpdateUserCustomMutation,
  UpdateUserInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
  getUserExecutedTasksQuery,
} from "@mltask/graphql/Codegen/API";
import {
  createMLTaskData,
  createModelInputPreset,
  createSocialManPost,
  createTaskReaction,
  deleteMLTaskExecutionResult,
  deleteMLTasksWorkspace,
  deleteModels,
  deleteSocialManPost,
  updateMLTaskData,
  updateMLTaskExecutionResult,
  updateModelInputPreset,
  updateModels,
  updateSocialManPost,
} from "@mltask/graphql/Codegen/mutations";
import {
  getMLTaskExecutionResult,
  getModelInputPreset,
  getSocialManPost,
  listSocialManPostsByCreatedAt,
} from "@mltask/graphql/Codegen/queries";
import { onUpdateSocialManPost } from "@mltask/graphql/Codegen/subscriptions";
import {
  createModels,
  updateUser,
  updateUserRemoveNetworkMutation,
  updateUserUsername,
} from "@mltask/graphql/CustomGQL/custom-mutations";
import {
  batchGetUsersBasic,
  getAllMLTaskDataById,
  getAllUserExecutedTasks,
  getModelsTaskID,
  getUserBasic,
  getUserCredits,
  getUserFromUsername,
  listAllMLTaskData,
  listPublicMLTaskExecutionResults,
  listSocialManPostsMinifiedDataByCreatedAt,
  modelInputPresetsByModelsID,
  modelInputPresetsByUserID,
  onCreateMLTaskExecutionResult,
  onFixedUpdateMLTaskExecutionResult,
} from "@mltask/graphql/CustomGQL/custom-queries";
import {
  GraphQLQuery,
  GraphQLResult,
  GraphQLSubscription,
  generateClient,
} from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { Cache, ConsoleLogger } from "aws-amplify/utils";
import { v4 as uuidv4 } from "uuid";
import AWSCognitoManager from "./AWSCognitoManager";
import AWSManagerCommon from "./AWSManagerCommon";
import AWSS3Manager from "./AWSS3Manager";

export class AWSGQLManager {
  protected static man: AWSGQLManager;
  public static get Instance() {
    if (!this.man) {
      this.man = new AWSGQLManager();
    }
    return this.man;
  }

  protected api() {
    return generateClient({
      headers: async (requestOptions) => {
        let sesh = await fetchAuthSession();
        let tokens = sesh.tokens?.idToken;
        return {
          Authorization: tokens?.toString() ?? "public",
        };
      },
    });
  }

  async fetchModelTaskIDFromModelID(
    modelID: string
  ): Promise<string | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<{
            getModels?: {
              id: string;
              mltaskdataID: string;
            } | null;
          }>
        >({
          query: getModelsTaskID,
          variables: {
            id: modelID,
            // $sortDirection: ModelSortDirection
            // $filter: ModelModelInputPresetFilterInput
            // $limit: Int
            // $nextToken: String
          },
          authMode: "userPool",
        });
        if (results.data?.getModels != null) {
          resolve(results.data!.getModels.mltaskdataID);
          return;
        }
        resolve(undefined);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async fetchModelPresetByID(
    presetID: string
  ): Promise<LocalModelInputPreset | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<GetModelInputPresetQuery>
        >({
          query: getModelInputPreset,
          variables: {
            id: presetID,
            // $sortDirection: ModelSortDirection
            // $filter: ModelModelInputPresetFilterInput
            // $limit: Int
            // $nextToken: String
          },
          authMode: "userPool",
        });
        if (results.data?.getModelInputPreset != null) {
          resolve(
            converter.convertModelInputPresetToLocal(
              results.data!.getModelInputPreset!
            )
          );
          return;
        }

        resolve(undefined);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async updateUserUsername(username: string): Promise<LocalUser | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          reject(new Error("No Logged In User"));
          return;
        }
        const results = await this.api().graphql<
          GraphQLQuery<{
            updateUserUsername: {
              id: string;
              username: string;
            };
          }>
        >({
          query: updateUserUsername,
          variables: {
            userID: finalUserId,
            username,
          },
          authMode: "userPool",
        });
        resolve({
          id: results.data?.updateUserUsername.id,
          username: results.data?.updateUserUsername.username,
        } as LocalUser);
      } catch (error) {
        let converted = error as GraphQLResult<CreateModelInputPresetMutation>;
        reject(converted.errors);
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        // reject(error);
      }
    });
  }

  async updateUserThirdPartyKey(
    userID: string,
    anthropicAPIKey?: string | null,
    elevenLabsAPIKey?: string | null,
    FalAPIKey?: string | null,
    lumaLabsAPIKey?: string | null,
    nimsAPIKey?: string | null,
    openAIAPIKey?: string | null,
    replicateAPIKey?: string | null,
    SAIAPIKey?: string | null
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      var keyQueryUpdateString = "";
      try {
        const updateUserInputData: UpdateUserMutationVariables = {
          input: {
            id: userID,
          },
        };

        if (anthropicAPIKey != undefined) {
          updateUserInputData.input.anthropicAPIKey =
            anthropicAPIKey.length == 0 ? null : anthropicAPIKey;
          keyQueryUpdateString += "anthropicAPIKey\n";
        }

        if (elevenLabsAPIKey != undefined) {
          updateUserInputData.input.elevenLabsAPIKey =
            elevenLabsAPIKey.length == 0 ? null : elevenLabsAPIKey;
          keyQueryUpdateString += "elevenLabsAPIKey\n";
        }

        if (FalAPIKey != undefined) {
          updateUserInputData.input.FalAPIKey =
            FalAPIKey.length == 0 ? null : FalAPIKey;
          keyQueryUpdateString += "FalAPIKey\n";
        }

        if (lumaLabsAPIKey != undefined) {
          updateUserInputData.input.lumaLabsAPIKey =
            lumaLabsAPIKey.length == 0 ? null : lumaLabsAPIKey;
          keyQueryUpdateString += "lumaLabsAPIKey\n";
        }

        if (nimsAPIKey != undefined) {
          updateUserInputData.input.nimsAPIKey =
            nimsAPIKey.length == 0 ? null : nimsAPIKey;
          keyQueryUpdateString += "nimsAPIKey\n";
        }

        if (openAIAPIKey != undefined) {
          updateUserInputData.input.openAIAPIKey =
            openAIAPIKey.length == 0 ? null : openAIAPIKey;
          keyQueryUpdateString += "openAIAPIKey\n";
        }

        if (replicateAPIKey != undefined) {
          updateUserInputData.input.replicateAPIKey =
            replicateAPIKey.length == 0 ? null : replicateAPIKey;
          keyQueryUpdateString += "replicateAPIKey\n";
        }

        if (SAIAPIKey != undefined) {
          updateUserInputData.input.SAIAPIKey =
            SAIAPIKey.length == 0 ? null : SAIAPIKey;
          keyQueryUpdateString += "SAIAPIKey\n";
        }

        const customUpdateUserKeys = /* GraphQL */ `
          mutation UpdateUser($input: UpdateUserInput!) {
            updateUser(input: $input) {
              id
              ${keyQueryUpdateString}
            }
          }
        `;
        await this.api().graphql<GraphQLQuery<UpdateUserMutation>>({
          query: customUpdateUserKeys,
          variables: updateUserInputData,
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log(`failed to update ${keyQueryUpdateString} key with error`);
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async updateUserRemoveNetworkConnection(
    network: SocialManNetworks
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          reject(new Error("No Logged In User"));
          return;
        }
        const updateUserInputData: UpdateUserInput = {
          id: finalUserId,
        };
        if (network == "TIKTOK") {
          updateUserInputData.tiktok = null;
        }
        if (network == "YOUTUBE") {
          updateUserInputData.google = null;
        }
        if (network == "FACEBOOK" || network == "INSTAGRAM") {
          updateUserInputData.facebook = null;
        }
        if (network == "TWITTER") {
          updateUserInputData.twitter = null;
        }
        if (network == "LINKEDIN") {
          updateUserInputData.linkedin = null;
        }
        if (network == "PINTEREST") {
          updateUserInputData.pinterest = null;
        }
        await this.api().graphql<GraphQLQuery<UpdateUserMutation>>({
          query: updateUserRemoveNetworkMutation,
          variables: { input: updateUserInputData },
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log("failed to disconnect network with error:");
        console.error(error);
        reject(error);
      }
    });
  }
  async updateUserStripeConnectAccountID(
    userID: string,
    connectAccountID: string
  ): Promise<string | null | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<UpdateUserCustomMutation>
        >({
          query: updateUser,
          variables: {
            input: { id: userID, stripeConnectAccountID: connectAccountID },
          },
          authMode: "userPool",
        });
        resolve(results.data.updateUser?.stripeConnectAccountID);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async setUserStripeAccountSetupComplete(userID: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.api().graphql<GraphQLQuery<UpdateUserCustomMutation>>({
          query: updateUser,
          variables: {
            input: { id: userID, stripeConnectAccountSetupComplete: true },
          },
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async fetchModelPresetByModelID(
    modelID: string,
    nextToken?: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<PagedLocalModelInputPresetResults | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          reject(new Error("No Logged In User"));
          return;
        }

        const results = await this.api().graphql<
          GraphQLQuery<ModelInputPresetsByModelsIDCustomQuery>
        >({
          query: modelInputPresetsByModelsID,
          variables: {
            modelsID: modelID,
            limit: Constants.PRESET_PAGE_SIZE,
            sortDirection: "DESC",
            nextToken,
          },
          authMode,
        });

        const modelPresets =
          await converter.convertModelInputPresetByModelIDToLocal(results);
        resolve({
          presets: modelPresets,
          nextToken: results.data?.modelInputPresetsByModelsID?.nextToken,
        } as PagedLocalModelInputPresetResults);
      } catch (error) {
        // console.log("failed to fetch model input preset with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async fetchCurrentUserModelPresets(): Promise<
    PagedLocalModelInputPresetResults | undefined
  > {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          resolve(undefined);
          return;
        }
        const final = await this.fetchModelPresetByUserID(finalUserId!);
        resolve(final);
      } catch (error) {
        reject(error);
      }
    });
  }
  async fetchModelPresetByUserID(
    userID: string,
    nextToken?: string,
    authMode: GraphQLAuthMode = "userPool",
    limit: number = Constants.PRESET_PAGE_SIZE
  ): Promise<PagedLocalModelInputPresetResults | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        let variables: ModelInputPresetsByUserIDQueryVariables = {
          userID: userID,
          sortDirection: ModelSortDirection.DESC,
          // $filter: ModelModelInputPresetFilterInput
          limit: limit,
          nextToken: nextToken != null ? nextToken : undefined,
        };
        const results = await this.api().graphql<
          GraphQLQuery<ModelInputPresetsByUserIDCustomQuery>
        >({
          query: modelInputPresetsByUserID,
          variables,
          authMode,
        });
        const modelPresets =
          converter.convertModelInputPresetByUserIDToLocal(results);
        resolve({
          presets: modelPresets,
          nextToken: results.data?.modelInputPresetsByUserID?.nextToken,
        } as PagedLocalModelInputPresetResults);
      } catch (error) {
        console.log("failed to fetch model input preset with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async createUserPresetForModel(
    modelsID: string,
    presetName: string,
    description: string,
    taskName: string,
    modelInputs: ModelInput,
    modelName: string,
    isPublic: boolean,
    isOpenSource: boolean,
    cost: number,
    entries: LocalModelInputPresetEntryInput[],
    progressCallback: (progress: any) => void
  ): Promise<LocalModelInputPreset> {
    return new Promise(async (resolve, reject) => {
      var currentUser = await this.fetchCurrentUser();
      if (currentUser?.id == null) {
        reject(new Error("No logged in User"));
        return;
      }
      if (currentUser?.username == null) {
        let noUsernameErrors = [{ message: PresetErrorCodes.NO_USERNAME }];
        reject(noUsernameErrors);
        return;
      }

      //entries
      //for every item with url we have to upload it to s3 then create the preset
      const preset_id = uuidv4();
      // TODO: move this to lambda
      // while this works, I shouldn't trust the client with either the id or the cost calculation
      for (let index = 0; index < entries.length; index++) {
        const entry = entries[index];
        let correspondingInput = modelInputs.entries.find(
          (x) => x.backendName == entries[index].modelInputName
        );
        if (entry.modelInputType == "TEXT") {
          for (let j = 0; j < entries[index].presetEntryDatas.length; j++) {
            if (
              correspondingInput?.cost.costPerToken &&
              correspondingInput?.cost.tokenizerModel
            ) {
              const tokensLength = countTokens(
                entries[index].presetEntryDatas[j].val ?? "",
                TokenizerModel[correspondingInput?.cost.tokenizerModel]
              );
              entries[index].presetEntryDatas[j].metaData = {
                ...(entries[index].presetEntryDatas[j].metaData ?? {}),
                TOKENS_COUNT: tokensLength,
              };
            }
          }
        }

        if (
          entry.modelInputType == "URL_IMAGE" ||
          entry.modelInputType == "URL_AUDIO" ||
          entry.modelInputType == "URL_VIDEO"
        ) {
          const uploadedResult = await AWSS3Manager.uploadUserContentToS3(
            `presets/${preset_id}`,
            entries[index].presetEntryDatas[0].val,
            isPublic ? "guest" : "private",
            undefined,
            null,
            null,
            progressCallback
          );
          entries[index].presetEntryDatas[0].val = uploadedResult;
        }
      }
      let mappedEntries = entries.map(
        (entry) =>
          ({
            modelInputName: entry.modelInputName,
            modelInputType: entry.modelInputType as ModelInputOutputEntryType,
            presetEntryDatas: entry.presetEntryDatas,
          } as ModelInputPresetEntryInput)
      );
      var queryVars: CreateModelInputPresetMutationVariables = {
        input: {
          id: preset_id,
          userID: currentUser!.id,
          modelsID,
          versionName: "1.0.0",
          versionHistory: [],
          presetName,
          username: currentUser!.username,
          taskName,
          modelName,
          description,
          isPublic,
          isOpenSource,
          cost,
          usage: 0,
          entries: mappedEntries,
        },
      };
      try {
        const results = await this.api().graphql<
          GraphQLQuery<CreateModelInputPresetMutation>
        >({
          query: createModelInputPreset,
          variables: queryVars,
          authMode: "userPool",
        });

        const jsonResult = results.data
          ?.createModelInputPreset as ModelInputPreset;
        const ret = converter.convertModelInputPresetToLocal(jsonResult);
        resolve(ret);
      } catch (error) {
        console.error(error);
        let converted = error as GraphQLResult<CreateModelInputPresetMutation>;
        reject(converted.errors);
      }
    });
  }
  async updateUserPresetForModel(
    originalPreset: LocalModelInputPreset,
    presetID: string,
    presetName: string,
    description?: string,
    isPublic?: boolean,
    isOpenSource?: boolean,
    cost?: number,
    entries?: LocalModelInputPresetEntryInput[]
  ): Promise<LocalModelInputPreset> {
    return new Promise(async (resolve, reject) => {
      var userID = (await AWSCognitoManager.getCurrentCognitoUser())?.id;

      if (userID == null) {
        reject(new Error("No logged in User"));
        return;
      }

      var queryVars: { [key: string]: any } = {
        input: {
          id: presetID,
        },
        condition: {
          userID: { eq: userID },
        },
      };

      if (presetName != undefined) queryVars.input.presetName = presetName;
      if (description != undefined) queryVars.input.description = description;
      if (isPublic != undefined) {
        //moving files to private folder if applicable
        queryVars.input.isPublic = isPublic;
        if (entries == undefined) entries = [...originalPreset.entries];
        for (let index = 0; index < entries!.length; index++) {
          const entry = entries![index];
          if (
            entry.modelInputType == "URL_IMAGE" ||
            entry.modelInputType == "URL_AUDIO" ||
            entry.modelInputType == "URL_VIDEO"
          ) {
            //if we decide to go back to using user content upload
            // const fromLevel = isPublic == true ? "private" : "public";
            // const toLevel = isPublic == true ? "public" : "private";
            // const fromFileKey = entries[index].presetEntryDatas[0].val!;
            // const toFileKey = entries[index].presetEntryDatas[0].val!.replace(
            //   fromLevel,
            //   toLevel
            // );
            // await AWSS3Manager.moveUserContentOnS3(
            //   fromFileKey,
            //   AWSManagerCommon.getAccessLevelFromPublicBoolean(!isPublic),
            //   toFileKey,
            //   AWSManagerCommon.getAccessLevelFromPublicBoolean(isPublic)
            // );
            var fromFileKey, toFileKey;
            // var cognitoID: string | undefined =
            //   await AWSCognitoManager.fetchCurrentIdentityID();

            if (isPublic == false) {
              // going to private
              fromFileKey = entries[index].presetEntryDatas[0].val!;
              toFileKey = `${entries[index].presetEntryDatas[0].val!.replace(
                `public/${userID}/`,
                `private/${userID}/`
              )}`;
            } else {
              fromFileKey = entries[index].presetEntryDatas[0].val!;
              toFileKey = `${entries[index].presetEntryDatas[0].val!.replace(
                `private/${userID}/`,
                `public/${userID}/`
              )}`;
            }
            await AWSS3Manager.moveFileOnS3(fromFileKey, toFileKey);
            entries[index].presetEntryDatas[0].val = toFileKey;
          }
        }
      }
      if (isOpenSource != undefined)
        queryVars.input.isOpenSource = isOpenSource;
      if (cost != undefined) queryVars.input.cost = cost;
      if (entries != undefined) queryVars.input.entries = entries;
      try {
        const results = await this.api().graphql<
          GraphQLQuery<UpdateModelInputPresetMutation>
        >({
          query: updateModelInputPreset,
          variables: queryVars,
          authMode: "userPool",
        });
        const jsonResult = results.data
          ?.updateModelInputPreset as ModelInputPreset;
        const ret = converter.convertModelInputPresetToLocal(jsonResult);
        resolve(ret);
      } catch (error) {
        reject(error);
      }
    });
  }
  deleteUserPresetWithPresetID(presetID: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      var userID = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
      if (userID == null) {
        reject(new Error("No logged in User"));
        return;
      }
      var queryVars: { [key: string]: any } = {
        input: {
          id: presetID,
        },
      };
      try {
        const results = await this.api().graphql<
          GraphQLQuery<DeleteModelInputPresetMutation>
        >({
          query: `
          mutation DeleteModelInputPreset(
            $input: DeleteModelInputPresetInput!
            $condition: ModelModelInputPresetConditionInput
          ) {
            deleteModelInputPreset(input: $input, condition: $condition) {
              id
            }
          }
        `,
          variables: queryVars,
          authMode: "userPool",
        });

        // const jsonResult = results.data
        //   ?.deleteModelInputPreset as ModelInputPreset;
        // const ret = converter.convertModelInputPresetToLocal(jsonResult);
        resolve(presetID);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  async fetchCurrentUserTaskExecutionResults(
    userId?: string,
    nextToken?: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<PagedTaskResults | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId =
          userId ?? (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          reject(new Error("No Logged In User"));
          return;
        }

        const results = await this.api().graphql<
          GraphQLQuery<getUserExecutedTasksQuery>
        >({
          query: getAllUserExecutedTasks,
          variables: {
            userID: finalUserId,
            limit: Constants.TASK_PAGE_SIZE,
            nextToken: nextToken != null ? nextToken : undefined,
            sortDirection: "DESC",
          },
          authMode: authMode,
        });
        const taskCardsData =
          converter.convertTaskExecutionResultsToLocal(results);
        resolve({
          tasks: taskCardsData,
          nextToken:
            results.data?.listMLTaskExecutionResultsByCreatedAt?.nextToken,
        } as PagedTaskResults);
      } catch (error) {
        console.log(
          "failed to fetchCurrentUserTaskExecutionResults graphql with error",
          error
        );
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async deleteCurrentUserTaskWorkspace(
    workspaceID: string,
    userId?: string
  ): Promise<boolean | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId =
          userId ?? (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          reject(new Error("No Logged In User"));
          return;
        }

        const results = await this.api().graphql<
          GraphQLQuery<DeleteMLTasksWorkspaceMutation>
        >({
          query: deleteMLTasksWorkspace,
          variables: {
            input: { id: workspaceID },
            condition: { userID: { eq: finalUserId } },
          },
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        // console.log("failed to fetch graphql with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async getTaskExecutionResult(
    taskID: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<TaskExecutionCardProps> {
    const vars: GetMLTaskExecutionResultQueryVariables = { id: taskID };
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<GetMLTaskExecutionResultQuery>
        >({
          query: getMLTaskExecutionResult,
          variables: vars,
          authMode,
        });
        const convertedTask = converter.convertTaskExecutionEntryToLocal(
          results.data.getMLTaskExecutionResult!
        );
        resolve(convertedTask);
      } catch (error) {
        console.log("failed to get task with error");
        console.error(error);
        reject(error);
      }
    });
  }
  async updateTaskExecutionResultsPrivacy(
    taskID: string,
    targetPrivacyLevel: LocalTaskPrivacyLevels
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        var userID = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (userID == null) {
          // no logged in user
          reject(new Error("No Logged In User"));
          return;
        }
        const taskData = await this.getTaskExecutionResult(taskID);
        const { inputs } = taskData.taskExecutionData;
        const { outputs } = taskData.taskExecutionOutput;
        var linksToMove = [];
        for (let key in inputs) {
          const currentVal = inputs[key];
          const { value, type } = currentVal;
          if (type.startsWith("URL_")) {
            linksToMove.push(value);
          }
        }
        for (let key in outputs) {
          const currentVal = outputs[key];
          const { value, type } = currentVal;
          if (type.startsWith("URL_")) {
            linksToMove.push(value);
          }
        }
        for (let linkToMove of linksToMove) {
          const targetAccessLevel =
            AWSManagerCommon.getAccessLevelFromLocalTaskPrivacy(
              targetPrivacyLevel
            );

          await AWSS3Manager.moveUserFileOnS3ForPrivacyChange(
            userID,
            linkToMove,
            targetAccessLevel
          );
        }

        const vars: UpdateMLTaskExecutionResultMutationVariables = {
          input: {
            id: taskID,
            privacyLevel:
              converter.convertLocalPrivacyToAWSPrivacy(targetPrivacyLevel),
          },
          condition: { userID: { eq: userID } },
        };
        await this.api().graphql<
          GraphQLQuery<UpdateMLTaskExecutionResultMutation>
        >({
          query: updateMLTaskExecutionResult,
          variables: vars,
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        console.log("failed to update task with error");
        console.error(error);
        reject(error);
      }
    });
  }
  async addUserReactionToTask(
    userID: string,
    taskID: string,
    localReactionType: LocalReactionType
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const reactionType =
          converter.convertLocalReactionToAWSReaction(localReactionType);
        var input: CreateTaskReactionInput = {
          mltaskexecutionresultID: taskID,
          type: reactionType,
          userID,
        };
        await this.api().graphql<GraphQLQuery<CreateTaskReactionMutation>>({
          query: createTaskReaction,
          variables: { input },
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        console.log("failed to add reaction to task with error");
        console.error(error);
        reject(error);
      }
    });
  }

  async deleteCurrentUserTaskExecutionResult(
    taskID: string
  ): Promise<boolean | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          reject(new Error("No Logged In User"));
          return;
        }

        const results = await this.api().graphql<
          GraphQLQuery<DeleteMLTaskExecutionResultMutation>
        >({
          query: deleteMLTaskExecutionResult,
          variables: {
            input: { id: taskID },
            condition: { userID: { eq: finalUserId } },
          },
          authMode: "userPool",
        });
        resolve(true);
      } catch (error) {
        console.log("failed to delete task with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async batchFetchAndCacheUsersByIds(
    idsToFetch: string[],
    authMode: GraphQLAuthMode
  ) {
    if (idsToFetch.length == 0) return;
    const results = await this.api().graphql<GraphQLQuery<BatchGetUsersQuery>>({
      query: batchGetUsersBasic,
      variables: { ids: idsToFetch },
      authMode: authMode,
    });

    let localUsers = results.data.batchGetUsers?.map((x) =>
      converter.convertUserToLocal(x as User)
    );
    if (localUsers) {
      for (const x of localUsers) {
        let cacheKey = `${x.id}`;
        await Cache.setItem(cacheKey, x, {
          expires: dateIn1HourFromNow().getTime(),
        });
      }
    }
  }
  async fetchPublicTasks(
    nextToken?: string,
    authMode: GraphQLAuthMode = "userPool",
    limit: number = Constants.TASK_PAGE_SIZE
  ): Promise<PagedTaskResults | undefined> {
    try {
      const publicTasksResults = await this.api().graphql<
        GraphQLQuery<ListMLTaskExecutionResultsQuery>
      >({
        query: listPublicMLTaskExecutionResults,
        variables: {
          filter: {
            status: { eq: "FINISHED" },
          },
          limit: limit,
          nextToken: nextToken,
        },
        authMode: authMode,
      });
      const taskCardsData =
        converter.convertTaskExecutionResultsToLocal(publicTasksResults);

      return {
        tasks: taskCardsData,
        nextToken:
          publicTasksResults.data?.listMLTaskExecutionResults?.nextToken,
      } as PagedTaskResults;
    } catch (error) {
      console.log("failed to fetch public tasks with error");
      console.log(error);
      return undefined;
    }
  }
  async createModel(
    modelData: ModelsInfo,
    isPublic: boolean
  ): Promise<ModelsInfo> {
    // var convertedRequiredLocalToAWS = [...modelData.inputs.minimumRequired].map((requiredInput => {
    //   requiredInput.optionType
    //   var convered: ModelInputEntryInput = {...requiredInput, optionType: OptionType.DROP_DOWN, type: ModelInputOutputEntryType.NUMBER }
    //   return convered

    // }));

    return new Promise(async (resolve, reject) => {
      try {
        const input: CreateModelsMutationVariables = {
          input: {
            isPublic: isPublic,
            name: modelData.name,
            usesAPIKeys:
              modelData.usesAPIKey as unknown as ThirdPartyAPIKeysNames[],
            inputs: {
              entries: converter.convertModelInputEntryToAWS(
                modelData.inputs.entries
              ),
            },
            outputs: modelData.outputs,
            mltaskdataID: modelData.taskID,
          },
        };
        const results = await this.api().graphql<
          GraphQLQuery<CreateModelsMutation>
        >({
          query: createModels,
          variables: input,
          authMode: "userPool",
        });
        resolve({ ...modelData, id: results.data?.createModels?.id! });
      } catch (error) {
        console.log("Failed to create model:");
        console.log(error);
        reject(error);
      }
    });
  }
  async updateModel(modelData: ModelsInfo): Promise<ModelsInfo> {
    return new Promise(async (resolve, reject) => {
      try {
        const input: UpdateModelsMutationVariables = {
          input: {
            id: modelData.id,
            name: modelData.name,
            isPublic: modelData.isPublic,
            usesAPIKeys:
              modelData.usesAPIKey as unknown as ThirdPartyAPIKeysNames[],
            inputs: {
              entries: converter.convertModelInputEntryToAWS(
                modelData.inputs.entries
              ),
            },
            outputs: modelData.outputs,
            mltaskdataID: modelData.taskID,
          },
        };
        const results = await this.api().graphql<
          GraphQLQuery<UpdateModelsMutation>
        >({
          query: updateModels,
          variables: input,
          authMode: "userPool",
        });
        resolve({ ...modelData, id: results.data?.updateModels?.id! });
      } catch (error) {
        console.log("Failed to create model:");
        console.log(error);
        reject(error);
      }
    });
  }

  async createTaskData(
    name?: string,
    category?: string,
    isPublic?: boolean
  ): Promise<void> {
    const taskDataID = uuidv4();
    return new Promise(async (resolve, reject) => {
      try {
        const input: CreateMLTaskDataMutationVariables = {
          input: {
            id: taskDataID,
            name: name,
            category: category,
            isPublic: isPublic ?? true,
          },
        };
        await this.api().graphql<
          GraphQLQuery<CreateMLTaskDataMutationVariables>
        >({
          query: createMLTaskData,
          variables: input,
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log("Failed to create task:");
        console.log(error);
        reject(error);
      }
    });
  }
  async updateTaskData(
    taskID: string,
    name?: string,
    category?: string,
    isPublic?: boolean
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const input: UpdateMLTaskDataMutationVariables = {
          input: {
            id: taskID,
          },
        };
        if (name) input.input.name = name;
        if (category) input.input.category = category;
        if (isPublic != undefined) input.input.isPublic = isPublic;
        console.log(input);
        await this.api().graphql<GraphQLQuery<UpdateMLTaskDataMutation>>({
          query: updateMLTaskData,
          variables: input,
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log("Failed to update task:");
        console.log(error);
        reject(error);
      }
    });
  }

  async deleteModel(modelID: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const input: UpdateModelsMutationVariables = {
          input: {
            id: modelID,
          },
        };
        const results = await this.api().graphql<
          GraphQLQuery<DeleteModelsMutation>
        >({
          query: deleteModels,
          variables: input,
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log("Failed to create model:");
        console.log(error);
        reject(error);
      }
    });
  }

  async fetchCurrentUser(): Promise<LocalUser | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await AWSCognitoManager.getCurrentCognitoUser();
        const results = await this.api().graphql<GraphQLQuery<GetUserQuery>>({
          query: getUserBasic,
          variables: { id: user?.id },
          authMode: "userPool",
        });
        const final = converter.convertUserResultsToLocal(results);
        resolve(final);
      } catch (error) {
        console.error("Failed to fetch current user with error", error);
        reject(error);
      }
    });
  }

  async fetchUserByID(
    userID: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<LocalUser | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<GraphQLQuery<GetUserQuery>>({
          query: getUserBasic,
          variables: { id: userID },
          authMode: authMode,
        });
        const final = converter.convertUserResultsToLocal(results);
        resolve(final);
      } catch (error) {
        console.error("failed to fetch user with error");
        console.log(JSON.stringify(error, null, 4));
        reject(error);
      }
    });
  }

  async fetchUserByUsername(
    username: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<LocalUser | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<GetUserFromUsernameQuery>
        >({
          query: getUserFromUsername,
          variables: { username: username },
          authMode: authMode,
        });
        const final = converter.convertUserResultsToLocal(results);
        resolve(final);
      } catch (error) {
        console.log("failed to fetch graphql with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }

  async batchGetUsersByIDs(
    ids: string[],
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<LocalUser[] | undefined> {
    // const cachedUser = await Cache.getItem(cacheKey);
    // if (cachedUser) {
    //   return new Promise(async (resolve, reject) => {
    //     resolve(cachedUser);
    //   });
    // }
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<BatchGetUsersQuery>
        >({
          query: batchGetUsersBasic,
          variables: { ids },
          authMode: authMode,
        });
        let localUsers = results.data.batchGetUsers?.map((x) =>
          converter.convertUserToLocal(x as User)
        );

        // Cache.setItem(cacheKey, final, {
        //   expires: dateIn1HourFromNow().getTime(),
        // });
        resolve(localUsers);
      } catch (error) {
        console.log("failed to fetch graphql with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async subscribeToUserCreditUpdate(
    userId: string | undefined,
    updatesCallback: (credits: number | null | undefined) => void,
    errorsCallback: (error: any) => void
  ) {
    userId = userId ?? (await AWSCognitoManager.getCurrentCognitoUser())?.id;
    // userId = "98d16320-30f1-705a-f284-3e94b8aae0ba";
    // console.log("subscribing to user with id " + userId);

    // const filter: ModelSubscriptionUserFilterInput = {
    //   stripeCustomerID: { eq: "cus_O71QTHDkiMqT5E" },
    // };

    const variables: OnUpdateUserSubscriptionVariables = {
      id: userId,
    };

    try {
      this.api()
        .graphql<GraphQLSubscription<OnUpdateUserSubscription>>({
          query: `
            subscription OnUpdateUser(
              $id: String
            ) {
              onUpdateUser(id: $id) {
                credits
              }
            }
          `,
          variables: variables,
          authMode: "userPool",
        })
        .subscribe({
          next: async ({ data }) => {
            updatesCallback?.(data!.onUpdateUser!.credits);
          },
          error: (resp) => {
            console.error("Failed to listen to user updates with errors:");
            console.error(resp);
            errorsCallback(resp);
            // reject(error.errors[0]);
          },
        });
    } catch (error) {
      console.error("Failed to subscribe to user with error");
      console.error(error);
    }
  }
  async fetchCurrentUserCredits(): Promise<number | null | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await AWSCognitoManager.getCurrentCognitoUser();
        const results = await this.api().graphql<
          GraphQLQuery<GetUserCreditsQuery>
        >({
          query: getUserCredits,
          variables: { id: user?.id },
          authMode: "userPool",
        });
        resolve(results.data!.getUser!.credits);
      } catch (error) {
        // console.log("failed to fetch graphql with error");
        // console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  subscriptions: { [key: string]: any } = {};
  unsubscribeToTaskExecutionUpdates(
    userId: string | undefined,
    taskId: string | undefined
  ) {
    this.subscriptions[`${userId}_${taskId}`]?.unsubscribe();
  }
  async subscribeToTaskExecutionUpdates(
    userId: string | undefined,
    taskId: string | undefined,
    updatesCallback: (updatedTask: TaskExecutionCardProps) => void,
    errorsCallback: (error: any) => void
  ) {
    const filter: ModelSubscriptionMLTaskExecutionResultFilterInput = {
      id: { eq: taskId },
    };

    const variables: OnUpdateMLTaskExecutionResultSubscriptionVariables = {
      filter: filter,
      userID: userId,
    };
    const ret = this.api()
      .graphql<GraphQLSubscription<OnUpdateMLTaskExecutionResultSubscription>>({
        query: onFixedUpdateMLTaskExecutionResult,
        variables: variables,
        authMode: "userPool",
      })
      .subscribe({
        next: ({ data }) => {
          const converted = converter.convertTaskExecutionEntryToLocal(
            data!.onUpdateMLTaskExecutionResult!
          );
          updatesCallback(converted);
        },
        error: ({ errors }) => {
          console.error("Failed to subscribe to task updates with errors:");
          console.error(errors);
          errorsCallback(errors);
        },
      });
    this.subscriptions[`${userId}_${taskId}`] = ret;
  }

  async getTasksGQL(): Promise<MLTasksData | undefined> {
    return new Promise(async (resolve, reject) => {
      var queryVars: { [key: string]: any } = {
        filter: { isPublic: { eq: true } } as ModelMLTaskDataFilterInput,
        nextToken: undefined,
        // nextToken: nextToken != null ? nextToken : undefined,
      };

      try {
        const results = await this.api().graphql<GraphQLQuery<ListTasksQuery>>({
          query: listAllMLTaskData,
          variables: queryVars,
          authMode: "userPool",
        });
        resolve(converter.convertListMLTaskDataToLocal(results));
      } catch (error) {
        console.log("failed to fetch tasks with error");
        console.error(error);
        reject(error);
      }
    });
    // const results = await API.get(process.env.NEXT_PUBLIC_API_NAME!, "/getCaller", {});
  }
  async getTasksByIDGQL(
    taskID: string
  ): Promise<MLTaskPropertyEntryData | undefined> {
    return new Promise(async (resolve, reject) => {
      var queryVars: GetMLTaskDataQueryVariables = {
        id: taskID,
      };

      try {
        const results = await this.api().graphql<
          GraphQLQuery<GetMLTaskDataQuery>
        >({
          query: getAllMLTaskDataById,
          variables: queryVars,
          authMode: "userPool",
        });
        resolve(
          converter.convertMLTaskDataToLocal(
            results.data?.getMLTaskData as MLTaskData
          )
        );
      } catch (error) {
        console.log("failed to fetch tasks with error");
        console.log(error);
        reject(error);
      }
    });
    // const results = await API.get(process.env.NEXT_PUBLIC_API_NAME!, "/getCaller", {});
  }

  async createSocialManPost(
    postInput: CreateSocialManPostInput,
    fileData: string,
    progressCallback?: (progress: any) => void
  ): Promise<LocalSocialManPost> {
    return new Promise(async (resolve, reject) => {
      try {
        // const metadata = fileMetadata?.reduce((result, item) => {
        //   result[item.key] = item.value.toString();
        //   return result;
        // }, {} as { [key: string]: string });
        // console.log("metadata =");
        // console.log(JSON.stringify(metadata, null, 4));
        const postID = uuidv4();
        var fileKey = await AWSS3Manager.uploadUserContentToS3(
          `Apps/SocialMan/${postID}`,
          fileData,
          "private",
          undefined,
          null,
          null,
          (progress) => {
            const adjustedProgress = Math.floor(progress * 100);
            progressCallback?.(adjustedProgress);
          },
          (error) => {
            console.error("Failed to upload file with error", error);
            reject(error);
          }
        );
        //removing everything before apps/ we will reconstruct the full path on the backend
        const index = fileKey!.indexOf("Apps/");
        const finalKey = fileKey!.substring(index) ?? fileKey;
        const input: CreateSocialManPostMutationVariables = {
          input: {
            ...postInput,
            id: postID,
            s3Key: finalKey,
          },
        };
        const results = await this.api().graphql<
          GraphQLQuery<CreateSocialManPostMutation>
        >({
          query: createSocialManPost,
          variables: input,
          authMode: "userPool",
        });
        const converted = converter.convertSocialManPostToLocal(
          results.data?.createSocialManPost as SocialManPost
        );
        resolve(converted);
      } catch (error) {
        console.log("Failed to create social man post:");
        console.log(error);
        reject(error);
      }
    });
  }
  async updateSocialManPost(
    localSocialManPost: LocalSocialManPost
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      var postUpdateInput: UpdateSocialManPostInput = {
        id: localSocialManPost.id,
      };
      if (localSocialManPost.title) {
        postUpdateInput.title = localSocialManPost.title;
      }
      if (localSocialManPost.description) {
        postUpdateInput.description = localSocialManPost.description;
      }
      if (localSocialManPost.instagram) {
        postUpdateInput.instagram = {
          thumbnail: localSocialManPost.instagram?.thumbnail,
          caption: localSocialManPost.instagram?.caption ?? "",
          targetAccountID: localSocialManPost.instagram.targetAccountID,
          postToStory: localSocialManPost.instagram.postToStory,
        };
      }
      if (localSocialManPost.pinterest) {
        postUpdateInput.pinterest = {
          thumbnailURL: localSocialManPost.pinterest?.thumbnailURL,
          title: localSocialManPost.pinterest?.title ?? "",
          description: localSocialManPost.pinterest.description,
          link: localSocialManPost.pinterest.link,
          boardID: localSocialManPost.pinterest.boardID,
        };
      }
      if (localSocialManPost.facebook) {
        postUpdateInput.facebook = {
          thumbnail: localSocialManPost.facebook?.thumbnail,
          caption: localSocialManPost.facebook?.caption ?? "",
          targetPageID: localSocialManPost.facebook.targetPageID,
          postToStory: localSocialManPost.facebook.postToStory,
        };
      }
      if (localSocialManPost.tiktok) {
        postUpdateInput.tiktok = {
          ...localSocialManPost.tiktok,
          privacy: converter.localTiktokPrivactToGQL(
            localSocialManPost.tiktok.privacy ??
              LocalSocialManTiktokPrivacyStatus.PUBLIC_TO_EVERYONE
          ),
        };
      }
      if (localSocialManPost.youtube) {
        postUpdateInput.youtube = localSocialManPost.youtube;
      }
      if (localSocialManPost.twitter) {
        postUpdateInput.twitter = localSocialManPost.twitter;
      }
      if (localSocialManPost.linkedin) {
        postUpdateInput.linkedin = localSocialManPost.linkedin;
      }
      postUpdateInput.enabledFacebook = localSocialManPost.enabledFacebook;
      postUpdateInput.enabledPinterest = localSocialManPost.enabledPinterest;
      postUpdateInput.enabledInstagram = localSocialManPost.enabledInstagram;
      postUpdateInput.enabledTiktok = localSocialManPost.enabledTiktok;
      postUpdateInput.enabledTwitter = localSocialManPost.enabledTwitter;
      postUpdateInput.enabledYoutube = localSocialManPost.enabledYoutube;
      postUpdateInput.enabledLinkedin = localSocialManPost.enabledLinkedin;
      try {
        const input: UpdateSocialManPostMutationVariables = {
          input: postUpdateInput,
        };
        await this.api().graphql<GraphQLQuery<UpdateSocialManPostMutation>>({
          query: updateSocialManPost,
          variables: input,
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log("Failed to update social man post:");
        console.log(error);
        reject(error);
      }
    });
  }
  async deleteSocialManPost(postID: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const input: DeleteSocialManPostMutationVariables = {
          input: {
            id: postID,
          },
        };

        await this.api().graphql<GraphQLQuery<DeleteSocialManPostMutation>>({
          query: deleteSocialManPost,
          variables: input,
          authMode: "userPool",
        });
        resolve();
      } catch (error) {
        console.log("Failed to delete social man post:");
        console.log(error);
        reject(error);
      }
    });
  }
  async fetchCurrentUserSocialManPostByID(
    postID: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<LocalSocialManPost | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await this.api().graphql<
          GraphQLQuery<GetSocialManPostQuery>
        >({
          query: getSocialManPost,
          variables: {
            id: postID,
          },
          authMode: authMode,
        });
        const post = converter.convertSocialManPostToLocal(
          results.data.getSocialManPost as SocialManPost
        );
        resolve(post as LocalSocialManPost);
      } catch (error) {
        console.log("failed to fetch current user social man posts with error");
        console.log(JSON.stringify(error));
        reject(error);
      }
    });
  }
  async fetchCurrentUserSocialManPosts(
    userId?: string,
    nextToken?: string,
    authMode: GraphQLAuthMode = "userPool"
  ): Promise<PagedLocalSocialManPost | undefined> {
    return new Promise(async (resolve, reject) => {
      try {
        var finalUserId = userId;
        if (finalUserId == undefined)
          finalUserId = (await AWSCognitoManager.getCurrentCognitoUser())?.id;
        if (finalUserId == null) {
          // no logged in user
          throw new Error("No Logged In User");
        }
        const results = await this.api().graphql<
          GraphQLQuery<ListSocialManPostsByCreatedAtQuery>
        >({
          query: listSocialManPostsMinifiedDataByCreatedAt,
          variables: {
            userID: finalUserId,
            limit: Constants.SOCIAL_MAN_POSTS_PAGE_SIZE,
            nextToken: nextToken,
            sortDirection: "DESC",
          },
          authMode: authMode,
        });
        const posts = converter.convertSocialManPostsToLocal(results);
        resolve({
          posts,
          nextToken: results.data?.listSocialManPostsByCreatedAt?.nextToken,
        } as PagedLocalSocialManPost);
      } catch (error) {
        console.log("failed to fetch current user social man posts with error");
        console.error(error);
        reject(error);
      }
    });
  }
  userCreditSubscriptions: { [key: string]: any } = {};
  unsubscribeFromUserCreditUpdate(userID: string) {
    if (userID in this.userCreditSubscriptions)
      this.userCreditSubscriptions[userID]?.unsubscribe();
  }
  socialManPostSubscriptions: { [key: string]: any } = {};
  unsubscribeFromSocialManPostUpdates(
    userID: string | undefined,
    postID: string | undefined
  ) {
    this.socialManPostSubscriptions[`${userID}_${postID}`]?.unsubscribe();
  }
  async subscribeToSocialManPostUpdates(
    userID: string | undefined,
    postID: string | undefined,
    updatesCallback: (updatedPost: LocalSocialManPost) => void,
    errorsCallback: (error: any) => void
  ) {
    const filter: ModelSubscriptionSocialManPostFilterInput = {
      id: { eq: postID },
    };

    const variables: OnUpdateSocialManPostSubscriptionVariables = {
      filter: filter,
      userID: userID,
    };

    const ret = this.api()
      .graphql<GraphQLSubscription<OnUpdateSocialManPostSubscription>>({
        query: onUpdateSocialManPost,
        variables: variables,
        authMode: "userPool",
      })
      .subscribe({
        next: ({ data }) => {
          // console.log("received updates ");
          // console.log(JSON.stringify(data!.onUpdateSocialManPost!));
          const converted = converter.convertSocialManPostToLocal(
            data!.onUpdateSocialManPost!
          );
          updatesCallback(converted);
        },
        error: ({ error }) => {
          console.log("Failed to subscribe to task updates with errors:");
          console.log(JSON.stringify(error));
          // reject(error.errors[0]);
          errorsCallback(error);
        },
      });
    this.socialManPostSubscriptions[`${userID}_${postID}`] = ret;
  }
}

export default AWSGQLManager.Instance;
