import Link from "next/link";
import React from "react";

type Props = { text: string; href: string };

export default function SimpleLink({ text, href }: Props) {
  return (
    <Link
      className="block w-full animate-shimmer rounded-md bg-[linear-gradient(110deg,oklch(var(--p)),45%,oklch(var(--s)),50%,oklch(var(--p)))] bg-[length:200%_100%]  px-12 py-3 text-center text-sm font-medium text-primary-content shadow transition-colors hover:animate-shimmer hover:bg-[color-mix(in_oklab,oklch(var(--p)),black_7%)] focus:outline-none focus:ring  sm:w-auto"
      href={href}
    >
      <span>{text}</span>
    </Link>
  );
}
