//amplify api gql-compile
//amplify codegen models
//amplify codegen types
//amplify codegen statements
import {
  LocalSocialManPost,
  LocalSocialManTiktokPrivacyStatus,
} from "@/app/SocialManmodeltypings";
import {
  ClientTokenizerModel,
  CloudAccessLevels,
  LocalModelInputEntryCost,
  LocalModelInputPreset,
  LocalModelInputPresetEntryDataInput,
  LocalModelInputPresetEntryInput,
  LocalModelInputPresetEntryInputUserValue,
  LocalModelInputPresetInputUserValue,
  LocalReactionType,
  LocalSubscriptionPlan,
  LocalTaskPrivacyLevels,
  LocalThirdPartyAPIKeysNames,
  LocalTwitterVerifiedType,
  LocalUser,
  LocalUserThirdPartyKeys,
  MLTaskPropertyEntryData,
  MLTasksData,
  ModelInput,
  ModelInputConstraint,
  ModelInputConstraintType,
  ModelInputEntry,
  ModelInputOptionsDataInput,
  ModelInputOutputEntryType,
  ModelOutput,
  ModelOutputEntry,
  ModelsInfo,
  OptionType,
  TaskExecutionData,
  TaskExecutionInput,
  TaskExecutionInputData,
  TaskExecutionOutput,
  TaskExecutionOutputEntry,
  TaskResultStatus,
} from "@/modeltypings";
import {
  InputConstraint,
  InputConstraintType,
  TaskExecutionCardProps,
  TaskExecutionReactionDataProps,
} from "@/typings";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import * as gqlapi from "@mltask/graphql/Codegen/API";

export function convertLocalPrivacyToCloudAccessLevel(
  level: LocalTaskPrivacyLevels
): CloudAccessLevels {
  if (level == LocalTaskPrivacyLevels.PRIVATE) return "private";
  if (level == LocalTaskPrivacyLevels.PUBLIC) return "guest";
  return "guest";
}
export function convertCloudAccessLevelToS3Folder(
  level: CloudAccessLevels
): string {
  if (level == "guest") return "public";
  return level;
}
export function convertLocalPrivacyToAWSPrivacy(
  taskPrivacy: LocalTaskPrivacyLevels
): gqlapi.TaskPrivacyLevels {
  if (taskPrivacy == LocalTaskPrivacyLevels.PRIVATE) {
    return gqlapi.TaskPrivacyLevels.private;
  }
  if (taskPrivacy == LocalTaskPrivacyLevels.PUBLIC) {
    return gqlapi.TaskPrivacyLevels.public;
  }
  return gqlapi.TaskPrivacyLevels.public;
}

export function convertLocalReactionToAWSReaction(
  reactionType: LocalReactionType
): gqlapi.TaskReactionType {
  switch (reactionType) {
    case "HEART":
      return gqlapi.TaskReactionType.HEART;
    case "HEART":
      return gqlapi.TaskReactionType.HEART;
    case "CUTE":
      return gqlapi.TaskReactionType.CUTE;
    case "FUNNY":
      return gqlapi.TaskReactionType.FUNNY;
    case "WOW":
      return gqlapi.TaskReactionType.WOW;
    case "SAD":
      return gqlapi.TaskReactionType.SAD;
    case "ANGRY":
      return gqlapi.TaskReactionType.ANGRY;
    case "SCARY":
      return gqlapi.TaskReactionType.SCARY;
    case "POOP":
      return gqlapi.TaskReactionType.POOP;
    case "GROSS":
      return gqlapi.TaskReactionType.GROSS;
  }
  return gqlapi.TaskReactionType.HEART;
}
export function convertModelInputEntryToAWS(
  inputs: ModelInputEntry[]
): Array<gqlapi.ModelInputEntryInput | null> | null {
  return inputs.map((input) => ({
    ...input,
    cost: convertLocalCostToAWS(input.cost),
    costWithAPIKey: convertLocalCostToAWS(input.costWithAPIKey),
  }));
}
export function convertLocalCostToAWS(
  cost: LocalModelInputEntryCost
): gqlapi.ModelInputEntryCost {
  return {
    ...cost,
    tokenizerModel: cost.tokenizerModel as gqlapi.TokenizerModel | undefined,
  };
}

export function convertInputCostToLocal(
  cost?: gqlapi.ModelInputEntryCost | null
): LocalModelInputEntryCost {
  if (cost == undefined) return {};
  return {
    tokenizerModel: cost.tokenizerModel as ClientTokenizerModel | undefined,
    costPerToken: cost.costPerToken as number | undefined,
    costPerCharacter: cost.costPerCharacter as number | undefined,
    costPerWord: cost.costPerWord as number | undefined,
    costPerValue: cost.costPerValue as number | undefined,
    costInterpolateMin: cost.costInterpolateMin as number | undefined,
    costInterpolateMax: cost.costInterpolateMax as number | undefined,
    costPerFileSize: cost.costPerFileSize as number | undefined,
    costPerFileDuration: cost.costPerFileDuration as number | undefined,
    flatFee: cost.flatFee as number | undefined,
    isMultiplier: cost.isMultiplier as boolean | undefined,
  };
}

export function convertSingleServerInputToLocal(
  x: gqlapi.ModelInputEntry | null | undefined
): ModelInputEntry {
  return {
    type: x!.type as ModelInputOutputEntryType,
    displayName: x!.displayName as string,
    backendName: x!.backendName as string,
    defaultValue: x?.defaultValue as any,
    options: x!.options as ModelInputOptionsDataInput[],
    optionType: x!.optionType as OptionType,
    optionStep: x!.optionStep as number,
    description: x!.description as string,
    examples: x!.examples as string[],
    constraints: x!.constraints as ModelInputConstraint[],
    cost: convertInputCostToLocal(x!.cost),
    costWithAPIKey: convertInputCostToLocal(x!.costWithAPIKey),
    isRequired: (x?.isRequired as boolean) ?? false,
  };
}

export function convertServerInputToLocal(
  input: (gqlapi.ModelInputEntry | null)[] | null | undefined
): ModelInputEntry[] {
  return input!.map((x) => {
    return convertSingleServerInputToLocal(x);
  });
}

export function convertCognitoModelInputsToLocalModelInput(
  modelInput: gqlapi.ModelInput | null
): ModelInput {
  return {
    entries: convertServerInputToLocal(modelInput!.entries),
  } as ModelInput;
}

export function convertCognitoModelOutputsToLocalModelInput(
  modelOutput: gqlapi.ModelOutput | null
): ModelOutput {
  const convertedEntries: ModelOutputEntry[] = modelOutput!.entries!.map(
    (x) => {
      const convertedType = x!.type;
      return { name: x!.name, type: convertedType } as ModelOutputEntry;
    }
  );
  return {
    entries: convertedEntries,
  } as ModelOutput;
}

function convertTaskExecutionResultInputToLocal(
  taskExecutionResultInput: gqlapi.TaskExecutionResultInput | null
) {
  var value = taskExecutionResultInput?.value;
  return {
    name: taskExecutionResultInput!.name,
    type: taskExecutionResultInput?.type,
    value: value,
  } as TaskExecutionInputData;
}
// GraphQLResult<GraphQLSubscription<MLTaskExecutionResult>>.data?: GraphQLSubscription<MLTaskExecutionResult>
function convertTaskPrivacyToLocal(
  privacy: gqlapi.TaskPrivacyLevels | null | undefined
): LocalTaskPrivacyLevels | undefined {
  if (privacy == gqlapi.TaskPrivacyLevels.private) {
    return LocalTaskPrivacyLevels.PRIVATE;
  }
  if (privacy == gqlapi.TaskPrivacyLevels.public) {
    return LocalTaskPrivacyLevels.PUBLIC;
  }
}
export function convertReactionDataToLocal(
  entry: gqlapi.MLTaskExecutionResult
): TaskExecutionReactionDataProps {
  return {
    HEART: entry.HEARTReactionStats ?? 0,
    CUTE: entry.CUTEReactionStats ?? 0,
    FUNNY: entry.FUNNYReactionStats ?? 0,
    WOW: entry.WOWReactionStats ?? 0,
    SAD: entry.SADReactionStats ?? 0,
    ANGRY: entry.ANGRYReactionStats ?? 0,
    SCARY: entry.SCARYReactionStats ?? 0,
    POOP: entry.POOPReactionStats ?? 0,
    GROSS: entry.GROSSReactionStats ?? 0,
  };
}
export function convertTaskExecutionEntryToLocal(
  entry: gqlapi.MLTaskExecutionResult
): TaskExecutionCardProps {
  const reducedInputs = (entry.inputs || []).reduce((sum, current) => {
    sum[current!.name as string] =
      convertTaskExecutionResultInputToLocal(current);
    return sum;
  }, {} as TaskExecutionInput);

  const taskExecutionData: TaskExecutionData = {
    modelID: entry.modelsID,
    task: entry.taskName as string,
    model: entry.modelName as string,
    taskMetadata: {
      LumaLabsGenerationID: entry.metadata?.LumaLabsGenerationID,
      ReplicatePredictionID: entry.metadata?.ReplicatePredictionID,
      RunwayMLGenerationID: entry.metadata?.RunwayMLGenerationID,
    },
    privacyLevel:
      convertTaskPrivacyToLocal(entry.privacyLevel) ??
      LocalTaskPrivacyLevels.PUBLIC,
    inputs: reducedInputs ?? {},
    presetUserInput:
      entry.presetUserInput as LocalModelInputPresetInputUserValue,
  };

  const mappedOutputs = (entry.output?.entries || []).map((x) => {
    let value = x?.value;
    // if (x?.type?.startsWith("URL_")) {
    //   const key = x!.value!;
    // }

    return {
      type: x?.type,
      name: x?.name,
      value: value,
    } as TaskExecutionOutputEntry;
  });
  // console.log(entry.status as TaskResultStatus);
  const taskExecutionOutput: TaskExecutionOutput = {
    // taskId: entry.id,
    outputs: mappedOutputs ?? [],
    message: entry.output?.message as string,
    creditsUsed: entry.creditsUsed ?? 0,
    createdAt: entry.createdAt ?? new Date().toISOString(),
  };
  // console.log("**************");
  // console.log(taskExecutionData);
  // console.log(taskExecutionOutput);
  // console.log("**************");
  const reactionData = convertReactionDataToLocal(entry);
  return {
    ownerID: entry.userID,
    workspaceID: entry.mltasksworkspaceID,
    taskId: entry.id,
    modelID: entry.modelsID,
    status: entry.status as TaskResultStatus,
    taskExecutionData,
    taskExecutionOutput,
    reactionData,
  } as TaskExecutionCardProps;
}

export function convertUserResultsToLocal(
  result: GraphQLResult<
    GraphQLQuery<gqlapi.GetUserQuery | gqlapi.getUserFromUsernameQuery>
  >
): LocalUser {
  const data = result.data!;
  const gqlUser =
    (data as gqlapi.GetUserQuery).getUser ??
    (data as gqlapi.getUserFromUsernameQuery).getUserFromUsername!;
  return convertUserToLocal(gqlUser as gqlapi.User) as LocalUser;
}

export function convertSubscriptionPlanToLocalSubscription(
  subscriptionPlan?: gqlapi.SubscriptionPlan | null
): LocalSubscriptionPlan {
  if (subscriptionPlan == null) return LocalSubscriptionPlan.NO_PLAN;
  if (subscriptionPlan == gqlapi.SubscriptionPlan.NO_PLAN) {
    return LocalSubscriptionPlan.NO_PLAN;
  }
  if (subscriptionPlan == gqlapi.SubscriptionPlan.SUBSCRIPTION_100000) {
    return LocalSubscriptionPlan.SUBSCRIPTION_100000;
  }
  if (subscriptionPlan == gqlapi.SubscriptionPlan.SUBSCRIPTION_15000) {
    return LocalSubscriptionPlan.SUBSCRIPTION_15000;
  }
  if (subscriptionPlan == gqlapi.SubscriptionPlan.SUBSCRIPTION_5000) {
    return LocalSubscriptionPlan.SUBSCRIPTION_5000;
  }
  return LocalSubscriptionPlan.NO_PLAN;
}
function convertTwitterVerifiedTypeToLocal(
  verifiedType: gqlapi.TwitterVerifiedType | null | undefined
): LocalTwitterVerifiedType | undefined {
  if (verifiedType == undefined) return undefined;
  switch (verifiedType) {
    case gqlapi.TwitterVerifiedType.BLUE:
      return LocalTwitterVerifiedType.BLUE;
    case gqlapi.TwitterVerifiedType.BUSINESS:
      return LocalTwitterVerifiedType.BUSINESS;
    case gqlapi.TwitterVerifiedType.GOVERNMENT:
      return LocalTwitterVerifiedType.GOVERNMENT;
    case gqlapi.TwitterVerifiedType.UNVERIFIED:
      return LocalTwitterVerifiedType.UNVERIFIED;
  }
}
function convertThirdPartyKeysToLocal(
  user: gqlapi.User | null | undefined
): LocalUserThirdPartyKeys | undefined {
  if (user == undefined) return undefined;
  return {
    anthropic: user.anthropicAPIKey ?? undefined,
    elevenLabs: user.elevenLabsAPIKey ?? undefined,
    Fal: user.FalAPIKey ?? undefined,
    lumaLabs: user.lumaLabsAPIKey ?? undefined,
    nims: user.nimsAPIKey ?? undefined,
    openAI: user.openAIAPIKey ?? undefined,
    replicate: user.replicateAPIKey ?? undefined,
    SAI: user.SAIAPIKey ?? undefined,
  };
}
export function convertUserToLocal(gqlUser: gqlapi.User): LocalUser {
  const ret: LocalUser = {
    id: gqlUser?.id,
    email: gqlUser?.email,
    name: gqlUser?.name,
    username: gqlUser?.username,
    hiddenKeys: convertThirdPartyKeysToLocal(gqlUser),
    currentPlan: convertSubscriptionPlanToLocalSubscription(
      gqlUser?.currentSubscriptionPlan
    ),
    createdPresetCount: gqlUser?.createdPresetCount,
    completedTasksCount: gqlUser?.completedTasksCount,
    picture: gqlUser?.picture,
    stripeID: gqlUser?.stripeCustomerID,
    stripeConnectAccountID: gqlUser?.stripeConnectAccountID,
    stripeConnectAccountSetupComplete:
      gqlUser?.stripeConnectAccountSetupComplete ?? false,
    credits: gqlUser?.credits,
    redeemableCredits: gqlUser?.redeemableCredits ?? 0,
    tiktok: {
      refreshTokenExpirationDate:
        gqlUser?.tiktok?.tokenData.refreshTokenExpirationDate,
      avatarURL: gqlUser?.tiktok?.userData.avatarURL,
      username: gqlUser?.tiktok?.userData.username,
      privacyLevelOptions: gqlUser?.tiktok?.userData.privacyLevelOptions,
      commentDisabled: gqlUser?.tiktok?.userData.commentDisabled,
      duetDisabled: gqlUser?.tiktok?.userData.duetDisabled,
      stitchDisabled: gqlUser?.tiktok?.userData.stitchDisabled,
      maxVideoPostDuration_sec:
        gqlUser?.tiktok?.userData.maxVideoPostDuration_sec,
    },
    linkedin: {
      picture: gqlUser?.linkedin?.userData.picture,
      name: gqlUser?.linkedin?.userData.name,
      tokenExpirationDate:
        gqlUser?.linkedin?.tokenData.accessTokenExpirationDate,
    },
    google: {
      name: gqlUser?.google?.userData.name,
      picture: gqlUser?.google?.userData.picture,
      username: gqlUser?.google?.userData.givenName,
      channels: gqlUser?.google?.channelsData,
    },
    twitter: {
      id: gqlUser?.twitter?.userData.id,
      username: gqlUser?.twitter?.userData.username,
      profilePictureURL: gqlUser?.twitter?.userData.profilePictureURL,
      verifiedType: convertTwitterVerifiedTypeToLocal(
        gqlUser?.twitter?.userData.verifiedType
      ),
    },
    pinterest: {
      refreshTokenExpirationDate:
        gqlUser?.pinterest?.tokenData.refreshTokenExpirationDate,
      profileImage: gqlUser?.pinterest?.userData.profileImage,
      username: gqlUser?.pinterest?.userData.username,
      boardsInfo: gqlUser?.pinterest?.boardsData,
    },
    facebook: {
      id: gqlUser?.facebook?.userData.id,
      name: gqlUser?.facebook?.userData.name,
      picture: gqlUser?.facebook?.userData.picture,
      tokenExpiresOn: gqlUser.facebook?.userData.expiresOn,
      pages: gqlUser?.facebook?.pagesData.map((x) => ({
        id: x?.id,
        tokenExpiresOn: x?.expiresOn,
        name: x?.name,
        picture: x?.picture,
        category: x?.category,
      })),
    },
    instagram: {
      accounts: gqlUser?.facebook?.instagramsData.map((x) => ({
        id: x?.id,
        igID: x?.igID,
        biography: x?.biography,
        name: x?.name,
        username: x?.username,
        profilePictureURL: x?.profilePictureURL,
        followsCount: x?.followsCount,
        followersCount: x?.followersCount,
        mediaCount: x?.mediaCount,
        tokenExpiresOn: x?.expiresOn,
      })),
    },
  } as LocalUser;
  if (gqlUser?.tiktok == undefined) {
    ret.tiktok = undefined;
  }
  if (gqlUser?.google == undefined) {
    ret.google = undefined;
  }
  if (gqlUser?.facebook == undefined) {
    ret.facebook = undefined;
  }
  if (gqlUser?.facebook?.instagramsData == undefined) {
    ret.instagram = undefined;
  }
  return ret;
}

export function convertTaskExecutionResultsToLocal(
  result: GraphQLResult<
    GraphQLQuery<
      gqlapi.getUserExecutedTasksQuery | gqlapi.ListMLTaskExecutionResultsQuery
    >
  >
) {
  let potentialResutls =
    (result.data! as gqlapi.getUserExecutedTasksQuery)
      .listMLTaskExecutionResultsByCreatedAt ??
    (result.data! as gqlapi.ListMLTaskExecutionResultsQuery)
      .listMLTaskExecutionResults;
  return potentialResutls!.items!.map((entry) => {
    return convertTaskExecutionEntryToLocal(
      entry as gqlapi.MLTaskExecutionResult
    );
  });
}

export function convertSocialManPostToLocal(
  entry: gqlapi.SocialManPost
): LocalSocialManPost {
  return {
    id: entry.id,
    userID: entry.userID,
    title: entry.title,
    description: entry.description,
    s3Key: entry.s3Key,
    s3KeyResized: entry.s3KeyResized,
    createdAt: entry.createdAt,
    scheduledAt: entry.scheduledAt,
    postedAt: entry.postedAt,
    postedToTiktok: entry.postedToTiktok,
    postedToYoutube: entry.postedToYoutube,
    postedToFacebook: entry.postedToFacebook,
    postedToInstagram: entry.postedToInstagram,
    postedToPinterest: entry.postedToPinterest,
    postedToTwitter: entry.postedToTwitter,
    postedToLinkedin: entry.postedToLinkedin,
    enabledFacebook: entry.enabledFacebook,
    enabledInstagram: entry.enabledInstagram,
    enabledTiktok: entry.enabledTiktok,
    enabledPinterest: entry.enabledPinterest,
    enabledTwitter: entry.enabledTwitter,
    enabledYoutube: entry.enabledYoutube,
    enabledLinkedin: entry.enabledLinkedin,
    tiktok: entry.tiktok,
    linkedin: entry.linkedin,
    pinterest: entry.pinterest,
    youtube: entry.youtube,
    instagram: entry.instagram,
    facebook: entry.facebook,
    twitter: entry.twitter,
    status: entry.status,
  } as LocalSocialManPost;
}

export function convertSocialManPostsToLocal(
  result: GraphQLResult<GraphQLQuery<gqlapi.ListSocialManPostsByCreatedAtQuery>>
) {
  let potentialResutls = (
    result.data! as gqlapi.ListSocialManPostsByCreatedAtQuery
  ).listSocialManPostsByCreatedAt;
  return (potentialResutls?.items ?? []).map((entry) => {
    return convertSocialManPostToLocal(entry as gqlapi.SocialManPost);
  });
}
function convertPresetEntriesToLocal(
  entries?: (gqlapi.ModelInputPresetEntry | null)[] | null | undefined
): LocalModelInputPresetEntryInput[] {
  if (entries == undefined) return [];
  return entries?.map((x) => {
    return {
      modelInputName: x?.modelInputName,
      modelInputType: x?.modelInputType,
      presetEntryDatas: x?.presetEntryDatas?.map((y) => {
        return {
          entryType:
            y?.entryType == gqlapi.InputPresetEntryType.PRESET_INPUT
              ? "PRESET_INPUT"
              : "USER_INPUT",
          parameterName: y?.parameterName,
          val: y?.val,
          description: y?.description,
          presetOrder: y?.presetOrder,
          metaData: y?.metaData,
        } as LocalModelInputPresetEntryDataInput;
      }),
    } as LocalModelInputPresetEntryInput;
  });
}
export function convertModelInputPresetToLocal(
  entry: gqlapi.ModelInputPreset | null
) {
  const ret: LocalModelInputPreset = {
    presetID: entry?.id ?? "",
    createdAt: entry?.createdAt ?? "N/A",
    username: entry?.username ?? "",
    taskName: entry?.taskName as string,
    modelName: entry?.modelName as string,
    versionName: entry?.versionName ?? "",
    versionHistory:
      entry?.versionHistory?.map((x) => ({
        versionName: x?.versionName ?? "1.0.0",
        cost: x?.cost ?? 0,
        entries: convertPresetEntriesToLocal(x?.entries),
      })) ?? [],
    presetName: entry?.presetName ?? "",
    description: entry?.description ?? "",
    isPublic: entry?.isPublic ?? false,
    isOpenSource: entry?.isOpenSource ?? false,
    cost: entry?.cost ?? 0,
    entries: convertPresetEntriesToLocal(entry?.entries),
    modelsID: entry?.modelsID ?? "",
    userID: entry?.userID ?? "",
    usage: entry?.usage ?? 0,
  };
  return ret;
}

export function convertModelInputPresetsToLocal(
  items: (gqlapi.ModelInputPreset | null)[]
) {
  return items!.map((entry) => {
    return convertModelInputPresetToLocal(entry);
  });
}
export function convertModelInputPresetByUserIDToLocal(
  result: GraphQLResult<
    GraphQLQuery<gqlapi.ModelInputPresetsByUserIDCustomQuery>
  >
) {
  return convertModelInputPresetsToLocal(
    result.data!.modelInputPresetsByUserID!.items!
  );
}

export function convertModelInputPresetByModelIDToLocal(
  result: GraphQLResult<
    GraphQLQuery<gqlapi.ModelInputPresetsByModelsIDCustomQuery>
  >
) {
  return convertModelInputPresetsToLocal(
    result.data!.modelInputPresetsByModelsID!.items!
  );
}

export function convertModelDataToLocal(y: gqlapi.Models | null): ModelsInfo {
  const name = y!.name!;
  const inputs = convertCognitoModelInputsToLocalModelInput(
    y!.inputs as gqlapi.ModelInput
  );
  const outputs = convertCognitoModelOutputsToLocalModelInput(
    y!.outputs as gqlapi.ModelOutput
  );
  const localUsesAPI: LocalThirdPartyAPIKeysNames[] = (
    y?.usesAPIKeys ?? []
  ).map((x) => x! as unknown as LocalThirdPartyAPIKeysNames);
  return {
    id: y?.id as string,
    taskID: y?.mltaskdataID as string,
    usesAPIKey: localUsesAPI,
    name: name,
    isPublic: y?.isPublic ?? false,
    inputs: inputs,
    outputs: outputs,
  };
}

export function convertMLTaskDataToLocal(
  task: gqlapi.MLTaskData
): MLTaskPropertyEntryData {
  const taskName = task!.name;
  const taskCategory = task!.category;
  const taskModels = task!.Models?.items!.map((y) => {
    return convertModelDataToLocal(y);
  }) as ModelsInfo[];
  return {
    id: task?.id,
    name: taskName,
    isPublic: task.isPublic,
    category: taskCategory,
    modelsInfo: taskModels,
  } as MLTaskPropertyEntryData;
}

export function convertListMLTaskDataToLocal(
  x: GraphQLResult<GraphQLQuery<gqlapi.ListTasksQuery>>
): MLTasksData {
  const convertedTasks: MLTaskPropertyEntryData[] =
    x.data!.listMLTaskData!.items.map((task) => {
      return convertMLTaskDataToLocal(task as gqlapi.MLTaskData);
    });
  const sortedCategories = convertedTasks.sort((x, y) => {
    const categoryComparison = x.category.localeCompare(y.category);
    return categoryComparison == 0
      ? x.name.localeCompare(y.name)
      : categoryComparison;
  });
  return { entries: sortedCategories } as MLTasksData;
}
export function convertModelInputConstraintToLocalInputConstraint(
  modelInputConstraintType: ModelInputConstraintType
): InputConstraintType {
  if (modelInputConstraintType == ModelInputConstraintType.MAX_STRING_LENGTH)
    return InputConstraintType.MAX_STRING_LENGTH;
  if (modelInputConstraintType == ModelInputConstraintType.MAX_TOKENS)
    return InputConstraintType.MAX_TOKENS;
  if (modelInputConstraintType == ModelInputConstraintType.REGEX)
    return InputConstraintType.REGEX;
  if (modelInputConstraintType == ModelInputConstraintType.MAX_VALUE)
    return InputConstraintType.MAX_VALUE;
  if (modelInputConstraintType == ModelInputConstraintType.MIN_VALUE)
    return InputConstraintType.MIN_VALUE;
  if (modelInputConstraintType == ModelInputConstraintType.IMAGE_RATIO)
    return InputConstraintType.IMAGE_RATIO;
  if (modelInputConstraintType == ModelInputConstraintType.IMAGE_WIDTH)
    return InputConstraintType.IMAGE_WIDTH;
  if (modelInputConstraintType == ModelInputConstraintType.IMAGE_HEIGHT)
    return InputConstraintType.IMAGE_HEIGHT;
  if (modelInputConstraintType == ModelInputConstraintType.MAX_FILE_SIZE_MB)
    return InputConstraintType.MAX_FILE_SIZE_MB;
  throw new Error(
    `Unsupported input constraint: ${modelInputConstraintType}, please implement`
  );
}
export function convertModelInputConstraintsToLocalInputConstraints(
  modelInputConstraint: ModelInputConstraint
): InputConstraint {
  return {
    type: convertModelInputConstraintToLocalInputConstraint(
      modelInputConstraint.type
    ),
    value: modelInputConstraint.value,
    resolutionMessage: modelInputConstraint.resolutionMessage,
  };
}
export function localTiktokPrivactToGQL(
  localPrivacy: LocalSocialManTiktokPrivacyStatus
): gqlapi.SocialManTiktokPrivacyStatus {
  switch (localPrivacy) {
    case LocalSocialManTiktokPrivacyStatus.PUBLIC_TO_EVERYONE:
      return gqlapi.SocialManTiktokPrivacyStatus.PUBLIC_TO_EVERYONE;
    case LocalSocialManTiktokPrivacyStatus.FOLLOWER_OF_CREATOR:
      return gqlapi.SocialManTiktokPrivacyStatus.FOLLOWER_OF_CREATOR;
    case LocalSocialManTiktokPrivacyStatus.MUTUAL_FOLLOW_FRIENDS:
      return gqlapi.SocialManTiktokPrivacyStatus.MUTUAL_FOLLOW_FRIENDS;
    case LocalSocialManTiktokPrivacyStatus.SELF_ONLY:
      return gqlapi.SocialManTiktokPrivacyStatus.SELF_ONLY;
  }
  console.error(
    "tiktok privacy convertion failed, Not impelement for:",
    localPrivacy
  );
  return gqlapi.SocialManTiktokPrivacyStatus.SELF_ONLY;
}
