"use client";
import {
  LocalModelInputPreset,
  LocalReactionType,
  LocalSubscriptionPlan,
  LocalTaskPrivacyLevels,
  LocalUser,
  PagedLocalModelInputPresetResults,
} from "@/modeltypings";
import { PagedTaskResults, TaskExecutionCardProps } from "@/typings";
import {
  InfiniteData,
  QueryClient,
  useQueryClient,
} from "@tanstack/react-query";
import {
  fetchCurrentUserQueryKey,
  fetchCurrentUserTaskResultsQueryKey,
  fetchModelPresetByUserIDQueryKey,
  getTasksDataQueryKey,
} from "./ReactQueryWrapperHooksKeys";

export function removeTasksData(queryClient?: QueryClient) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.removeQueries({
    queryKey: getTasksDataQueryKey(),
  });
}

export function updateUserPresetCachedData(
  updatedPreset: LocalModelInputPreset
) {
  const queryClient = useQueryClient();
  queryClient.setQueryData<
    | InfiniteData<PagedLocalModelInputPresetResults | undefined, unknown>
    | undefined
  >(
    fetchModelPresetByUserIDQueryKey(updatedPreset.userID),
    (
      cachedData:
        | InfiniteData<PagedLocalModelInputPresetResults | undefined, unknown>
        | undefined
    ) => {
      if (cachedData == undefined) return cachedData;
      let adjustedPages = cachedData.pages.map((page) => ({
        nextToken: page?.nextToken,
        presets: page?.presets.map((preset) =>
          preset.presetID == updatedPreset.presetID ? updatedPreset : preset
        ),
      }));
      let adjustedPresets = {
        pageParams: cachedData.pageParams,
        pages: adjustedPages,
      };
      return adjustedPresets as InfiniteData<PagedLocalModelInputPresetResults>;
    }
  );
}

export function removeUserPresetByIDCachedData(
  userID: string,
  presetID: string,
  queryClient?: QueryClient
) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.setQueryData<
    | InfiniteData<PagedLocalModelInputPresetResults | undefined, unknown>
    | undefined
  >(
    fetchModelPresetByUserIDQueryKey(userID),
    (
      cachedData:
        | InfiniteData<PagedLocalModelInputPresetResults | undefined, unknown>
        | undefined
    ) => {
      if (cachedData == undefined) return cachedData;
      let adjustedPages = cachedData.pages.map((page) => ({
        nextToken: page?.nextToken,
        presets: page?.presets.filter((preset) => preset.presetID != presetID),
      }));
      let adjustedPresets = {
        pageParams: cachedData.pageParams,
        pages: adjustedPages,
      };
      return adjustedPresets as InfiniteData<PagedLocalModelInputPresetResults>;
    }
  );
}

export function removeCurrentUserCachedData(queryClient?: QueryClient) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.removeQueries({ queryKey: fetchCurrentUserQueryKey() });
}

export function updateCurrentUserCachedData(
  updatedUser: LocalUser,
  queryClient?: QueryClient
) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.setQueryData<LocalUser | undefined>(
    fetchCurrentUserQueryKey(),
    (cachedData: LocalUser | undefined) => {
      return { ...cachedData, ...updatedUser };
    }
  );
}

export function updateCurrentUserSubscriptionPlan(
  newSubscriptionPlan: LocalSubscriptionPlan,
  queryClient?: QueryClient
) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.setQueryData<LocalUser | undefined>(
    fetchCurrentUserQueryKey(),
    (cachedData: LocalUser | undefined) => {
      if (cachedData == undefined) return cachedData;
      return {
        ...cachedData,
        currentPlan: newSubscriptionPlan,
      };
    }
  );
}

export function addNewTaskCachedDataForUserID(
  userID: string,
  newTask: TaskExecutionCardProps,
  queryClient?: QueryClient
) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.setQueryData<
    InfiniteData<PagedTaskResults | undefined, unknown> | undefined
  >(
    fetchCurrentUserTaskResultsQueryKey(userID),
    (
      cachedData:
        | InfiniteData<PagedTaskResults | undefined, unknown>
        | undefined
    ) => {
      if (cachedData == undefined) return cachedData;
      let adjustedPages = cachedData.pages.map((page, index) => ({
        nextToken: page?.nextToken,
        tasks: index == 0 ? [newTask, ...(page?.tasks ?? [])] : page?.tasks,
      }));
      let adjustedTasks = {
        pageParams: cachedData.pageParams,
        pages: adjustedPages,
      };
      return adjustedTasks as InfiniteData<PagedTaskResults>;
    }
  );
}

export function updateTaskCachedDataForUserID(
  userID: string,
  updatedTask: TaskExecutionCardProps,
  queryClient?: QueryClient
) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.setQueryData<
    InfiniteData<PagedTaskResults | undefined, unknown> | undefined
  >(
    fetchCurrentUserTaskResultsQueryKey(userID),
    (
      cachedData:
        | InfiniteData<PagedTaskResults | undefined, unknown>
        | undefined
    ) => {
      if (cachedData == undefined) return cachedData;
      let adjustedPages = cachedData.pages.map((page, index) => ({
        nextToken: page?.nextToken,
        tasks: page?.tasks.map((task) =>
          task.taskId == updatedTask.taskId ? updatedTask : task
        ),
      }));
      let adjustedTasks = {
        pageParams: cachedData.pageParams,
        pages: adjustedPages,
      };
      return adjustedTasks as InfiniteData<PagedTaskResults>;
    }
  );
}

export function updateTaskPrivacyCachedDataForUserID(
  userID: string,
  taskID: string,
  updatedPrivacy: LocalTaskPrivacyLevels,
  queryClient?: QueryClient
) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.setQueryData<
    InfiniteData<PagedTaskResults | undefined, unknown> | undefined
  >(
    fetchCurrentUserTaskResultsQueryKey(userID),
    (
      cachedData:
        | InfiniteData<PagedTaskResults | undefined, unknown>
        | undefined
    ) => {
      if (cachedData == undefined) return cachedData;
      let adjustedPages = cachedData.pages.map((page, index) => ({
        nextToken: page?.nextToken,
        tasks: page?.tasks.map((task) =>
          task.taskId == taskID
            ? {
                ...task,
                taskExecutionData: {
                  ...task.taskExecutionData,
                  privacyLevel: updatedPrivacy,
                },
              }
            : task
        ),
      }));
      let adjustedTasks = {
        pageParams: cachedData.pageParams,
        pages: adjustedPages,
      };
      return adjustedTasks as InfiniteData<PagedTaskResults>;
    }
  );
}

export function updateTaskReactionCachedDataForUserID(
  userID: string,
  taskID: string,
  // TODO somehow include current user old reaction
  // oldReaction: LocalReactionType,
  newReaction: LocalReactionType,
  queryClient?: QueryClient
) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.setQueryData<
    InfiniteData<PagedTaskResults | undefined, unknown> | undefined
  >(
    fetchCurrentUserTaskResultsQueryKey(userID),
    (
      cachedData:
        | InfiniteData<PagedTaskResults | undefined, unknown>
        | undefined
    ) => {
      if (cachedData == undefined) return cachedData;
      let adjustedPages = cachedData.pages.map((page, index) => ({
        nextToken: page?.nextToken,
        tasks: page?.tasks.map((task) => {
          if (task.taskId == taskID) {
            let adjustedReactionData = {
              ...task.reactionData,
            };
            // adjustedReactionData[oldReaction] -= 1;
            adjustedReactionData[newReaction] += 1;
            return {
              ...task,
              reactionData: adjustedReactionData,
            };
          }

          return task;
        }),
      }));
      let adjustedTasks = {
        pageParams: cachedData.pageParams,
        pages: adjustedPages,
      };
      return adjustedTasks as InfiniteData<PagedTaskResults>;
    }
  );
}

export function removeUserTaskExecutionResultCachedData(
  userID: string,
  taskID: string,
  queryClient?: QueryClient
) {
  if (queryClient == undefined) queryClient = useQueryClient();
  queryClient.setQueryData<
    InfiniteData<PagedTaskResults | undefined, unknown> | undefined
  >(
    fetchCurrentUserTaskResultsQueryKey(userID),
    (
      cachedData:
        | InfiniteData<PagedTaskResults | undefined, unknown>
        | undefined
    ) => {
      if (cachedData == undefined) return cachedData;
      let adjustedPages = cachedData.pages.map((page) => ({
        nextToken: page?.nextToken,
        tasks: page?.tasks.filter((task) => task.taskId != taskID),
      }));
      let adjustedTasks = {
        pageParams: cachedData.pageParams,
        pages: adjustedPages,
      };
      return adjustedTasks as InfiniteData<PagedTaskResults>;
    }
  );
}
