import { LocalSubscriptionPlan } from "@/modeltypings";
import {
  BasePricingBlockProps,
  InternalCreditsPriceID,
  InternalSubscriptionPriceID,
} from "@/typings";
import CoreConstants from "@mltask/core/CoreConstants";
// import localFont from "next/font/local";

// export const sevenSegmentFont = localFont({
//   src: "../public/fonts/Dotrice-Regular.otf",
// });

namespace Constants {
  export const maxTextAreaHeightBeforeScrolling = 250;
  export const defaultBGColor = "bg-base-300";
  export const TASK_PAGE_SIZE = 10;
  export const PRESET_PAGE_SIZE = 10;
  export const SOCIAL_MAN_POSTS_PAGE_SIZE = 5;

  export const contactUsErrorMessage =
    "Something went wrong, please contact us here https://mltask.com/contact";

  export const contactUsError = new Error(contactUsErrorMessage);

  export const allSizesStyleText =
    "transition-all text-[12px] xs:text-xs sm:text-sm md:text-md lg:text-lg";
  export const allSizesStyleMDText =
    "transition-all text-[12px] xs:text-xs sm:text-sm md:text-md";

  export const payAsYouGoOptions: BasePricingBlockProps[] = [
    {
      cost: CoreConstants.CreditsTiersCostUSDCents.Credits_1000 / 100,
      name: "Explorer",
      badgeText: "",
      credit: CoreConstants.CreditsTiersRewards.Credits_1000,
      themeColor: "accent",
      bulletPoints: ["One time payment"],
      internalPriceID: InternalCreditsPriceID.Credits_1000,
      CTA: "Buy Now",
    },
    {
      cost: CoreConstants.CreditsTiersCostUSDCents.Credits_7000 / 100,
      name: "Entrepreneur",
      badgeText: "Most Popular",
      credit: CoreConstants.CreditsTiersRewards.Credits_7000,
      themeColor: "primary",
      bulletPoints: ["One time payment"],
      internalPriceID: InternalCreditsPriceID.Credits_7000,
      CTA: "Buy Now",
    },
    {
      cost: CoreConstants.CreditsTiersCostUSDCents.Credits_20000 / 100,
      name: "Pro",
      badgeText: "",
      credit: CoreConstants.CreditsTiersRewards.Credits_20000,
      themeColor: "info",
      bulletPoints: ["One time payment"],
      internalPriceID: InternalCreditsPriceID.Credits_20000,
      CTA: "Buy Now",
    },
  ];
  ///SOCIALMAN PRICING//////
  export const socialManMonthlyPricingOptionsDict: Map<
    InternalSubscriptionPriceID,
    BasePricingBlockProps
  > = new Map<InternalSubscriptionPriceID, BasePricingBlockProps>([
    [
      InternalSubscriptionPriceID.Subscription_5000,
      {
        cost:
          CoreConstants.SubscriptionsTiersCostUSDCents.SUBSCRIPTION_5000 / 100,
        name: "Starter",
        badgeText: "",
        credit: CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_5000,
        themeColor: "accent",
        bulletPoints: [
          "Unlimited number of Posts",
          "Connect all your social networks",
          `Get ${CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_5000} monthly credits to use ai to optimize your posts (10% discount)`,
          "Get all Starter features for MLTask",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_5000,
        CTA: "Subscribe Now",
      },
    ],
    [
      InternalSubscriptionPriceID.Subscription_15000,
      {
        cost:
          CoreConstants.SubscriptionsTiersCostUSDCents.SUBSCRIPTION_15000 / 100,
        name: "Pro",
        badgeText: "",
        // badgeText: "Most Popular",
        credit: CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_5000,
        themeColor: "primary",
        bulletPoints: [
          "Unlimited number of Posts",
          "Connect all your social networks",
          `Get ${CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_5000} monthly credits to use ai to optimize your posts (20% discount)`,
          "Get all Pro features for MLTask",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_15000,
        CTA: "Subscribe Now",
      },
    ],
    [
      InternalSubscriptionPriceID.Subscription_100000,
      {
        cost:
          CoreConstants.SubscriptionsTiersCostUSDCents.SUBSCRIPTION_100000 /
          100,
        name: "SUPER",
        badgeText: "",
        credit: CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_5000,
        themeColor: "info",
        bulletPoints: [
          `Get ${CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_5000} credits monthly (20% discount)`,
          "Ad Free Browsing",
          "Private Tasks",
          "Monetiezable presets",
          "15% cheaper task cost",
          "Special badge, customizations as a valued customer",
          "Get custom links for your preset names",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_100000,
        CTA: "Subscribe Now",
      },
    ],
  ]);
  export const socialManMonthlyPricingOptions: BasePricingBlockProps[] = [
    socialManMonthlyPricingOptionsDict.get(
      InternalSubscriptionPriceID.Subscription_5000
    )!,
    socialManMonthlyPricingOptionsDict.get(
      InternalSubscriptionPriceID.Subscription_15000
    )!,
    // monthlyPricingOptionsDict.get("Subscription_100000")!,
  ];
  ///SOCIALMAN PRICING//////
  export const monthlyPricingOptionsDict: Map<
    InternalSubscriptionPriceID,
    BasePricingBlockProps
  > = new Map<InternalSubscriptionPriceID, BasePricingBlockProps>([
    [
      InternalSubscriptionPriceID.Subscription_5000,
      {
        cost:
          CoreConstants.SubscriptionsTiersCostUSDCents.SUBSCRIPTION_5000 / 100,
        name: "Starter",
        badgeText: "",
        credit: CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_5000,
        themeColor: "accent",
        bulletPoints: [
          `Get ${CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_5000} credits monthly (10% discount)`,
          "Private Tasks",
          "Monetiezable presets",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_5000,
        CTA: "Subscribe Now",
      },
    ],
    [
      InternalSubscriptionPriceID.Subscription_15000,
      {
        cost:
          CoreConstants.SubscriptionsTiersCostUSDCents.SUBSCRIPTION_15000 / 100,
        name: "Pro",
        badgeText: "Most Popular",
        credit: CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_15000,
        themeColor: "primary",
        bulletPoints: [
          `Get ${CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_15000} credits monthly (20% discount)`,
          // "Ad Free Browsing",
          "Private Tasks",
          "Monetiezable presets",
          // "10% cheaper task cost",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_15000,
        CTA: "Subscribe Now",
      },
    ],
    [
      InternalSubscriptionPriceID.Subscription_100000,
      {
        cost:
          CoreConstants.SubscriptionsTiersCostUSDCents.SUBSCRIPTION_15000 / 100,
        name: "SUPER",
        badgeText: "",
        credit: CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_100000,
        themeColor: "info",
        bulletPoints: [
          `Get ${CoreConstants.SubscriptionsTiersRewards.SUBSCRIPTION_100000} credits monthly (20% discount)`,
          "Ad Free Browsing",
          "Private Tasks",
          "Monetiezable presets",
          "15% cheaper task cost",
          "Special badge, customizations as a valued customer",
          "Get custom links for your preset names",
        ],
        internalPriceID: InternalSubscriptionPriceID.Subscription_100000,
        CTA: "Subscribe Now",
      },
    ],
  ]);
  export const monthlyPricingOptions: BasePricingBlockProps[] = [
    monthlyPricingOptionsDict.get(
      InternalSubscriptionPriceID.Subscription_5000
    )!,
    monthlyPricingOptionsDict.get(
      InternalSubscriptionPriceID.Subscription_15000
    )!,
    // monthlyPricingOptionsDict.get("Subscription_100000")!,
  ];
  export function localSubPlanToPriceID(
    localSubscriptionPlan: LocalSubscriptionPlan
  ): InternalSubscriptionPriceID | undefined {
    if (localSubscriptionPlan == LocalSubscriptionPlan.NO_PLAN)
      return undefined;
    if (localSubscriptionPlan == LocalSubscriptionPlan.SUBSCRIPTION_5000)
      return InternalSubscriptionPriceID.Subscription_5000;
    if (localSubscriptionPlan == LocalSubscriptionPlan.SUBSCRIPTION_15000)
      return InternalSubscriptionPriceID.Subscription_15000;
    if (localSubscriptionPlan == LocalSubscriptionPlan.SUBSCRIPTION_100000)
      return InternalSubscriptionPriceID.Subscription_100000;
  }
  export function priceIDToLocalSubPlan(
    localSubscriptionPlan: InternalSubscriptionPriceID
  ): LocalSubscriptionPlan {
    if (localSubscriptionPlan == InternalSubscriptionPriceID.Subscription_5000)
      return LocalSubscriptionPlan.SUBSCRIPTION_5000;
    if (localSubscriptionPlan == InternalSubscriptionPriceID.Subscription_15000)
      return LocalSubscriptionPlan.SUBSCRIPTION_15000;
    if (
      localSubscriptionPlan == InternalSubscriptionPriceID.Subscription_100000
    )
      return LocalSubscriptionPlan.SUBSCRIPTION_100000;
    return LocalSubscriptionPlan.SUBSCRIPTION_5000;
  }
}

export default Constants;
