import { ComfyTokenData, TaskExecutionData } from "@/modeltypings";
import { TaskExecutionCardProps } from "@/typings";
import * as converter from "@/utils/graphql/convertionLayer";
import { get, post } from "aws-amplify/api";
import AWSManagerCommon from "./AWSManagerCommon";
import AWSS3Manager from "./AWSS3Manager";

export class AWSAPIManager {
  protected static man: AWSAPIManager;
  public static get Instance() {
    if (!this.man) {
      this.man = new AWSAPIManager();
    }
    return this.man;
  }
  //API
  async addToWaitlist(email: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const postInit = {
          body: {
            email,
          },
        };
        await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/add-to-waitlist",
          options: postInit,
        }).response;
        resolve();
      } catch (error) {
        console.error("Failed to save user identity with error: ", error);
        reject(error);
      }
    });
  }
  async postSocialManPostToEverywhere(
    userID: string,
    postID: string
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const postInit = {
          body: {
            userID,
            postID,
          },
        };
        await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/socialman/post-starter",
          options: postInit,
        }).response;
        resolve();
      } catch (error) {
        console.error("Failed to post everywher with error: ", error);
        reject(error);
      }
    });
  }
  async scheduleSocialManPost(
    userID: string,
    postID: string,
    scheduleTime: string
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const postInit = {
          body: {
            userID,
            postID,
            scheduleTime,
          },
        };
        await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/socialman/schedule-post",
          options: postInit,
        }).response;
        resolve();
      } catch (error) {
        console.error("Failed to schedule post with error: ", error);
        reject(error);
      }
    });
  }
  async cancelScheduledSocialManPost(
    userID: string,
    postID: string
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const postInit = {
          body: {
            userID,
            postID,
          },
        };
        await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/socialman/cancel-scheduled-post",
          options: postInit,
        }).response;
        resolve();
      } catch (error) {
        console.error("Failed to schedule post with error: ", error);
        reject(error);
      }
    });
  }

  async getFacebookLoginURL(
    userID: string,
    scopes: string[],
    redirectData?: Record<string, string>
  ): Promise<string> {
    const queryParams: Record<string, string> = {
      userID,
      scopes: scopes.join(","),
    };
    if (redirectData) {
      Object.assign(queryParams, redirectData);
    }
    const postInit = {
      queryParams,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const result = await get({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/social-networks/facebook/get-login-url",
          options: postInit,
        }).response;
        const responseBodyJSON = (await result.body.json()) as {
          [prop: string]: any;
        };
        const parsedBody = JSON.parse(responseBodyJSON.body);
        resolve(parsedBody.url);
      } catch (error) {
        reject(error);
      }
    });
  }
  async facebookLoginCallback(
    accessToken: string,
    userID: string
  ): Promise<void> {
    const postInit = {
      body: {
        accessToken,
        userID,
      },
    };

    return new Promise(async (resolve, reject) => {
      try {
        await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/social-networks/facebook/login-callback",
          options: postInit,
        }).response;
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async getTwitterLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    const queryParams: Record<string, string> = {
      userID,
    };
    if (redirectData) {
      Object.assign(queryParams, redirectData);
    }
    const postInit = {
      queryParams,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const result = await get({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/social-networks/twitter/get-login-url",
          options: postInit,
        }).response;
        const responseBodyJSON = (await result.body.json()) as {
          [prop: string]: any;
        };
        const parsedBody = JSON.parse(responseBodyJSON.body);
        resolve(parsedBody.url);
      } catch (error) {
        reject(error);
      }
    });
  }
  async getYoutubeLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    const queryParams: Record<string, string> = {
      userID,
    };
    if (redirectData) {
      Object.assign(queryParams, redirectData);
    }
    const postInit = {
      queryParams,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await get({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/social-networks/youtube/get-login-url",
          options: postInit,
        }).response;
        const responseBodyJSON = (await result.body.json()) as {
          [prop: string]: any;
        };
        const parsedBody = JSON.parse(responseBodyJSON.body);
        resolve(parsedBody.url);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getPinterestLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    const queryParams: Record<string, string> = {
      userID,
    };
    if (redirectData) {
      Object.assign(queryParams, redirectData);
    }
    const postInit = {
      queryParams,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await get({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/social-networks/pinterest/get-login-url",
          options: postInit,
        }).response;

        const responseBodyJSON = (await result.body.json()) as {
          [prop: string]: any;
        };
        const parsedBody = JSON.parse(responseBodyJSON.body);
        resolve(parsedBody.url);
      } catch (error) {
        reject(error);
      }
    });
  }
  async getLinkedinLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    const queryParams: Record<string, string> = {
      userID,
    };
    if (redirectData) {
      Object.assign(queryParams, redirectData);
    }
    const postInit = {
      queryParams,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await get({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/social-networks/linkedin/get-login-url",
          options: postInit,
        }).response;

        const responseBodyJSON = (await result.body.json()) as {
          [prop: string]: any;
        };
        const parsedBody = JSON.parse(responseBodyJSON.body);
        resolve(parsedBody.url);
      } catch (error) {
        reject(error);
      }
    });
  }
  async getTiktokLoginURL(
    userID: string,
    redirectData?: Record<string, string>
  ): Promise<string> {
    const queryParams: Record<string, string> = {
      userID,
    };
    if (redirectData) {
      Object.assign(queryParams, redirectData);
    }
    const postInit = {
      queryParams,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await get({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/social-networks/tiktok/get-login-url",
          options: postInit,
        }).response;

        const responseBodyJSON = (await result.body.json()) as {
          [prop: string]: any;
        };
        const parsedBody = JSON.parse(responseBodyJSON.body);
        resolve(parsedBody.url);
      } catch (error) {
        reject(error);
      }
    });
  }
  async tiktokLoginCallback(
    tiktokCode: string,
    userID: string,
    state: string
  ): Promise<void> {
    const postInit = {
      body: {
        tiktokCode,
        userID,
        state,
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/social-networks/tiktok/login-callback",
          options: postInit,
        }).response;
        // const finalText = await result.body.text();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async sendContactEmail(
    email: string,
    subject: string,
    message: string
  ): Promise<string> {
    const postInit = {
      body: {
        email,
        subject,
        message,
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/contact-us",
          options: postInit,
        }).response;
        const finalText = await result.body.text();
        resolve(finalText);
      } catch (error) {
        reject(error);
      }
    });
  }

  async executeTask(
    workspaceId: string | undefined,
    taskExecutionData: TaskExecutionData,
    progressCallback?: (progress: any) => void
  ): Promise<TaskExecutionCardProps> {
    const { task, model, modelID, inputs, presetUserInput, privacyLevel } =
      taskExecutionData;
    const convertedPrivacy =
      converter.convertLocalPrivacyToAWSPrivacy(privacyLevel);
    const accessLevel =
      AWSManagerCommon.getAccessLevelFromTaskPrivacy(convertedPrivacy);
    //looping over every input of type url and upload the data to s3
    for (let key in inputs) {
      const currentVal = inputs[key];
      const { name, type } = currentVal;
      if (type.startsWith("URL_")) {
        await AWSS3Manager.uploadFileForKeyAndInput(
          name,
          inputs,
          accessLevel,
          type,
          progressCallback
        );
      }
    }

    const postInit: any = {
      body: {
        taskName: task,
        model,
        modelsID: modelID,
        inputs,
        workspaceId,
        privacyLevel: convertedPrivacy,
      },
    };

    if (presetUserInput != undefined) {
      postInit.body["preset"] = presetUserInput;
    }

    return new Promise(async (resolve, reject) => {
      try {
        const request = post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/start-task",
          options: postInit,
        });

        const responseBody = (await request.response).body;
        const responseBodyJSON = (await responseBody.json()) as {
          [prop: string]: any;
        };
        const converted = converter.convertTaskExecutionEntryToLocal(
          responseBodyJSON.data.createMLTaskExecutionResult
        );

        resolve(converted);
      } catch (error) {
        // console.log("failed to start task with error");
        // console.log(error);
        reject(error);
      }
    });
  }

  listUserComfyTokens(userID: string): Promise<ComfyTokenData[]> {
    const postInit = {
      queryParams: {
        userID,
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await get({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/comfy/list-tokens",
          options: postInit,
        }).response;
        const responseBodyJSON = (await result.body.json()) as {
          [prop: string]: any;
        };
        const { tokens } = JSON.parse(responseBodyJSON.body);
        const convertedTokens = tokens.map(
          (x: any) =>
            ({
              token: x.token,
              scopes: x.scopes,
              createdAt: x.createdAt,
              updatedAt: x.updatedAt,
              expirationDate: x.expirationDate,
              revokeDate: x.revokeDate,
            } as ComfyTokenData)
        );
        resolve(convertedTokens);
      } catch (error) {
        reject(error);
      }
    });
  }
  createComfyToken(
    userID: string,
    password: string,
    scopes: string[]
  ): Promise<string> {
    const postInit = {
      body: {
        userID,
        password,
        scopes,
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/comfy/create-token",
          options: postInit,
        }).response;

        const responseBodyJSON = (await result.body.json()) as {
          [prop: string]: any;
        };
        const parsedBody = JSON.parse(responseBodyJSON.body);
        resolve(parsedBody.token);
      } catch (error) {
        // console.log("failed to start task with error");
        // console.log(error);
        reject(error);
      }
    });
  }
  revokeComfyToken(token: string, userID: string): Promise<void> {
    const postInit = {
      body: {
        userID,
        token,
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await post({
          apiName: process.env.NEXT_PUBLIC_API_NAME!,
          path: "/comfy/revoke-token",
          options: postInit,
        }).response;

        await result.body.json();

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default AWSAPIManager.Instance;
