import {
  CopyObjectCommand,
  CopyObjectCommandInput,
  DeleteObjectCommand,
  GetObjectCommand,
  ObjectCannedACL,
  S3Client,
  S3ClientConfig,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { AwsCredentialIdentity } from "@smithy/types";

export const SocialManResizedPrefix = "resized-";

export async function getUrlFromBucket(
  s3Bucket: string,
  region: string,
  key: string,
  expiresInSeconds?: number,
  credentials?: AwsCredentialIdentity,
  ResponseContentType?: string
): Promise<string | undefined> {
  const s3Configuration: S3ClientConfig = {
    credentials,
    region: region,
  };

  //Getting public url doesn't work with instagram container, to fix this we need to create a cdn and distribute the files there

  const s3Client = new S3Client(s3Configuration);
  const command = new GetObjectCommand({
    Bucket: s3Bucket,
    Key: key,
    ResponseContentType: ResponseContentType,
    // ResponseContentDisposition: `attachment; filename="${
    //   key.split("/").splice(-1)[0]
    // }"`,
  });
  try {
    return await getSignedUrl(s3Client, command, {
      expiresIn: expiresInSeconds,
    });
  } catch (e) {
    console.log("failed to get object with error, ", e);
    return undefined;
  }
}

export async function moveFileOnS3(
  from: string,
  destination: string,
  bucket: string,
  region: string,
  identity: AwsCredentialIdentity | undefined,
  privacyLevel: ObjectCannedACL | undefined = undefined
) {
  await copyFileOnS3(from, destination, bucket, region, identity, privacyLevel);
  await deleteFileOnS3(from, bucket, region, identity);
}
export async function copyFileOnS3(
  from: string,
  destination: string,
  bucket: string,
  region: string,
  identity: AwsCredentialIdentity | undefined,
  privacyLevel: ObjectCannedACL | undefined = undefined
) {
  const s3Configuration: S3ClientConfig = {
    credentials: identity,
    region: region,
  };
  const s3Client = new S3Client(s3Configuration);
  const copyParams: CopyObjectCommandInput = {
    CopySource: `/${bucket}/${from}`,
    Bucket: bucket,
    Key: `${destination}`,
    ACL: privacyLevel,
  };
  await s3Client.send(new CopyObjectCommand(copyParams));
}
export async function deleteFileOnS3(
  key: string,
  bucket: string,
  region: string,
  identity: AwsCredentialIdentity | undefined
) {
  const s3Configuration: S3ClientConfig = {
    credentials: identity,
    region: region,
  };
  const s3Client = new S3Client(s3Configuration);
  const deleteParams = {
    Bucket: bucket,
    Key: key,
  };
  await s3Client.send(new DeleteObjectCommand(deleteParams));
}

export function constructFullS3KeyFromKey(
  key: string,
  privacyLevel: string,
  userId: string
) {
  return `${privacyLevel}/${userId}/${key}`;
}

export function constructFullS3KeyFromWorkspaceIDAndTaskID(
  workspaceID: string,
  taskID: string,
  privacyLevel: string,
  userID: string
) {
  return constructFullS3KeyFromKey(
    `${workspaceID}/${taskID}`,
    privacyLevel,
    userID
  );
}
