export function dateInXSecondsFromNow(seconds: number) {
  const now = new Date();
  const expiration = new Date(now.getTime() + seconds * 1000);
  return expiration;
}
export function dateIn1HourFromNow() {
  return dateInXSecondsFromNow(3600);
}
export function dateIn5MinutesFromNow() {
  return dateInXSecondsFromNow(5 * 60);
}
export function roundMinutesToNextMultipleOf(date: Date, multiple: number) {
  const roundedDate = new Date(date);
  const minutes = roundedDate.getMinutes();
  const remainder = minutes % multiple;

  if (remainder !== 0) {
    roundedDate.setMinutes(minutes + (5 - remainder));
  }

  roundedDate.setSeconds(0);
  roundedDate.setMilliseconds(0);

  return roundedDate;
}

export function isDateExpired(dateString: string): boolean {
  const inputDate = new Date(dateString);
  if (isNaN(inputDate.getTime())) {
    throw new Error("Invalid date string format");
  }
  const currentDate = new Date();
  return inputDate < currentDate;
}

export function has24HoursPassed(createdTimestamp: number): boolean {
  const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const currentTimestamp = Date.now(); // Get the current timestamp in milliseconds

  // Calculate the difference between the current time and the 'created' timestamp
  const timeDifference = currentTimestamp - createdTimestamp;
  // console.log(
  //   `twentyFourHoursInMilliseconds = ${twentyFourHoursInMilliseconds} timeDifference = ${timeDifference} currentTimestamp = ${currentTimestamp} createdTimestamp = ${createdTimestamp}`
  // );

  // Compare the time difference with 24 hours in milliseconds
  return timeDifference >= twentyFourHoursInMilliseconds;
}
