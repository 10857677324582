import { raleway } from "@/utils/FontsConst";
import React from "react";

type Props = { logoString: string; isSocialMan: boolean; isLoading?: boolean };

export default function LogoString({
  logoString,
  isSocialMan,
  isLoading = false,
}: Props) {
  function getColorSplitIndex() {
    if (isSocialMan) {
      return 6;
    }
    return 2;
  }
  return (
    <div
      className={`${isLoading ? "animate-wobble-scale " : ""} ${
        raleway.className
      } flex flex-row text-primary`}
    >
      {Array.from(logoString).map((x, index) => {
        const pClassName = index < getColorSplitIndex() ? "text-accent" : "";
        const divClass = `animate-pulse `;
        return (
          <div
            className={isLoading ? divClass : ""}
            style={{ animationDelay: `${index * 500}ms` }}
            key={index}
          >
            <p className={pClassName}>{x}</p>
          </div>
        );
      })}
    </div>
  );
}
