"use client";
import HeroWavesSVG from "@/components/ui/SVGIcons/HeroWaves";
import { useFetchPublicTasks } from "@/hooks/QueryHooks/ReactQueryWrapperhooks";
import { shuffle } from "@mltask/core/Utils/ArrayHelper";
import dynamic from "next/dynamic";
import { useMemo, useRef } from "react";
import CheckoutAppsHero from "./CheckoutAppsHero";
import CreatePresetHero from "./CreatePresetHero";
// import WaitlistHero from "./WaitlistHero";
const HeroParallax = dynamic(() =>
  import("@/components/ui/hero-parallax").then((mod) => mod.HeroParallax)
);

const MovingGetStartedButton = dynamic(
  () => import("./MovingGetStartedButton"),
  {
    ssr: false,
  }
);

type Props = {};

export default function MainHero({}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const {
    data: publicTasks,
    isFetching,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useFetchPublicTasks(undefined, 30);

  const cleanedTasks = useMemo(() => {
    let filteredTasks = (publicTasks ?? { pages: [] })?.pages
      .map((page) => page?.tasks.filter((task) => task.reactionData.HEART > 0))
      .flatMap((x) => x)
      .sort(
        (a, b) => (b?.reactionData.HEART ?? 0) - (a?.reactionData.HEART ?? 0)
      );
    return shuffle(filteredTasks ?? []);
  }, [publicTasks]);

  function renderHero() {
    return (
      <section ref={ref} className="flex w-full flex-col items-center">
        <div className="flex w-full justify-center overflow-visible bg-base-200">
          <HeroParallax tasks={cleanedTasks} />
        </div>
        <MovingGetStartedButton
          getRef={() => {
            return ref;
          }}
        />
        <HeroWavesSVG fillColorCSS="fill-base-200" inverted={false} />
      </section>
    );
  }
  // function renderWaitlistHero() {
  //   return (
  //     <section className="flex w-full flex-col items-center">
  //       <div className="flex w-full justify-center overflow-visible bg-base-200 py-6">
  //         <WaitlistHero />
  //       </div>
  //       <HeroWavesSVG fillColorCSS="fill-base-200" inverted={false} />
  //     </section>
  //   );
  // }
  return (
    <div className="flex grow flex-col">
      {renderHero()}
      {/* {renderWaitlistHero()} */}
      <CreatePresetHero />
      <CheckoutAppsHero />
    </div>
  );
}
