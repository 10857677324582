"use client";
import { FilterSVG } from "@/components/ui/SVGIcons/FilterSVG";
import HeroWavesSVG from "@/components/ui/SVGIcons/HeroWaves";
import Constants from "@/utils/Constants";
import { raleway } from "@/utils/FontsConst";
import { getSignupPath } from "@/utils/NavigationUtils";
import dynamic from "next/dynamic";
import React from "react";
import HeroBulletPoints from "./HeroBulletPoints";
const SparklesCore = dynamic(
  () => import("../sparkles").then((mod) => mod.SparklesCore),
  {
    ssr: false,
  }
);

const SimpleLink = dynamic(() => import("./SimpleLink"), {
  ssr: false,
});

type Props = { overrideBGColor?: string };

export default function CreatePresetHero({ overrideBGColor }: Props) {
  return (
    <section
      className={`relative flex w-full grow flex-col items-center ${
        overrideBGColor ?? Constants.defaultBGColor
      } `}
    >
      <div className="pointer-events-none absolute inset-0 mt-0 h-full w-full">
        <SparklesCore
          id="tsparticlesfullpage"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={25}
          className="h-full w-full"
          particleColor="#FFFFFF"
        />
      </div>
      <div className="mx-auto flex w-full flex-col px-4 py-12 text-center sm:px-6 md:px-12 lg:px-24 lg:py-24 ">
        <div className="mb-12 flex w-full flex-col space-y-8">
          <div className="mx-auto mb-5 inline-flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-accent p-4 text-accent-content">
            <FilterSVG className="size-20" />
          </div>
          <h1 className="text-2xl font-bold  tracking-tighter text-base-content md:text-4xl lg:text-5xl xl:text-6xl ">
            {`Monetize Your AI Expertise with`}
            <br />
            <strong className="ml-2 bg-gradient-to-r from-secondary to-warning bg-clip-text font-extrabold text-transparent ">
              {`Custom Presets`}
            </strong>
          </h1>

          <HeroBulletPoints
            points={[
              "Access proven presets from the community or create and sell your own. Your prompt logic stays completely private while your creations reach a global marketplace.",
              // "Efficiency Redefined: Craft, Utilize, and Even Profit from Custom Presets",
              // "Never worry about memorizing prompts again!",
              // "Access a plethora of community-made presets or design your own with MLTask.",
              // "Looking to profit?",
              // "Sell your unique prompts confidently—your structures remain private, securing your innovation from potential theft.",
            ]}
          />
        </div>
        <div className="mt-10 flex justify-center gap-4 ">
          <SimpleLink href={getSignupPath()} text={"Start Creating Presets"} />
        </div>
      </div>
      <HeroWavesSVG fillColorCSS="fill-base-400" inverted={true} />
    </section>
  );
}
