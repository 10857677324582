"use client";

import {
  CloudAccessLevels,
  PagedLocalModelInputPresetResults,
} from "@/modeltypings";
import { InternalCreditsPriceID } from "@/typings";
import { IBackendManagerInterface } from "@/utils/BackendManager";
import CoreConstants from "@mltask/core/CoreConstants";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  fetchCurrentUserQueryKey,
  fetchCurrentUserSocialManPostByIDQueryKey,
  fetchCurrentUserSocialManPostsQueryKey,
  fetchCurrentUserTaskResultsQueryKey,
  fetchModelPresetByIDQueryKey,
  fetchModelPresetByModelIDQueryKey,
  fetchModelPresetByUserIDQueryKey,
  fetchModelTaskIDFromModelIDQueryKey,
  fetchPublicTasksQueryKey,
  fetchTaskResultsByIDQueryKey,
  fetchUserByIDQueryKey,
  fetchUserByUsernameQueryKey,
  getTasksDataQueryKey,
  listUserComfyTokensQueryKey,
  useGetS3ContentQueryKey,
} from "./ReactQueryWrapperHooksKeys";

var BackendManager: IBackendManagerInterface;
import("@/utils/BackendManager").then((lib) => {
  BackendManager = lib.default;
});

export function useGetS3ContentQuery(
  s3Key: string,
  customUserID: string | undefined,
  isUserContent: boolean,
  contentLevel: CloudAccessLevels | undefined,
  enabled: boolean = true
) {
  let expirationTimeInSeconds = CoreConstants.S3CacheDurationTimeInMilS / 1000;
  return useQuery({
    queryKey: useGetS3ContentQueryKey(s3Key, isUserContent),
    queryFn: async () => {
      var link;
      if (isUserContent && contentLevel != undefined) {
        link = await BackendManager.getUserContent(
          customUserID,
          s3Key!,
          contentLevel,
          expirationTimeInSeconds
        );
      } else {
        link = await BackendManager.getCloudContent(
          s3Key!,
          expirationTimeInSeconds
        );
      }
      return link;
    },
    enabled: enabled,
    staleTime: expirationTimeInSeconds,
  });
}

export function useFetchCurrentUserSocialManPostByID(postID: string) {
  return useQuery({
    queryKey: fetchCurrentUserSocialManPostByIDQueryKey(postID),
    queryFn: () =>
      BackendManager.fetchCurrentUserSocialManPostByID(postID, "userPool"),
    staleTime: CoreConstants.fetchSocialManPostsCacheDurationTimeInMilS,
    refetchOnWindowFocus: false,
  });
}

export function useFetchModelPresetByIDQuery(
  presetID: string,
  enabled: boolean = true
) {
  return useQuery({
    queryKey: fetchModelPresetByIDQueryKey(presetID),
    queryFn: () => BackendManager.fetchModelPresetByID(presetID),
    enabled,
    staleTime: CoreConstants.fetchModelPresetByPresetIDCacheDurationTimeInMilS,
  });
}

export function useFetchCurrentUserQuery(enabled: boolean = true) {
  return useQuery({
    queryKey: fetchCurrentUserQueryKey(),
    queryFn: () => BackendManager.fetchCurrentUser(),
    enabled,
    staleTime: CoreConstants.fetchCurrentUserCacheDurationTimeInMilS,
    refetchOnWindowFocus: false,
  });
}

export function useStripeGetCustomerPortal() {
  return useQuery({
    queryKey: ["stripeGetCustomerPortal"],
    queryFn: () => BackendManager.getCustomerPortal(),
    enabled: false,
    staleTime: 0,
  });
}

export function useStripeCheckout(
  internalPriceID: InternalCreditsPriceID,
  dontRedirect: boolean
) {
  return useQuery({
    queryKey: ["stripeCheckout", internalPriceID],
    queryFn: () => BackendManager.stripeCheckout(internalPriceID, dontRedirect),
    enabled: false,
    staleTime: 0,
  });
}

export function useStripeGetPaymentHistory() {
  return useQuery({
    queryKey: ["stripeGetPaymentHistory"],
    queryFn: () => BackendManager.getPaymentHistory(),
    enabled: false,
    staleTime: 0,
  });
}

export function useStripeConnectAccountLink() {
  return useQuery({
    queryKey: ["stripeConnectAccountLink"],
    queryFn: () => BackendManager.getStripeConnectAccountLink(),
    enabled: false,
    staleTime: 0,
  });
}

export function useStripeConnectGetPayoutHistory() {
  return useQuery({
    queryKey: ["stripeConnectGetPayoutHistory"],
    queryFn: () => BackendManager.getStripeConnectAccountPayoutHistory(),
    enabled: false,
    staleTime: 0,
  });
}

export function useStripeGetConnectAccountLoginLink() {
  return useQuery({
    queryKey: ["stripeGetConnectAccountLoginLink"],
    queryFn: () => BackendManager.getStripeConnectAccountLoginLink(),
    enabled: false,
    staleTime: 0,
  });
}

export function useStripeCheckConnectAccountCompletedSetupAndUpdateUser() {
  return useQuery({
    queryKey: ["stripeCheckConnectAccountCompletedSetupAndUpdateUser"],
    queryFn: () =>
      BackendManager.checkStripeConnectAccountCompletedSetupAndUpdateUser(),
    enabled: false,
    staleTime: 0,
  });
}

export function useFetchUserByID(
  userID: string,
  enabled = true,
  authMode: string = "apiKey"
) {
  return useQuery({
    queryKey: fetchUserByIDQueryKey(userID),
    queryFn: () => BackendManager.fetchUserByID(userID, authMode),
    staleTime: CoreConstants.fechUserByIDCacheDurationTimeInMilS,
    enabled,
  });
}

export function useFetchUserByUsername(
  username: string,
  enabled = true,
  authMode?: string
) {
  return useQuery({
    queryKey: fetchUserByUsernameQueryKey(username),
    queryFn: () => BackendManager.fetchUserByUsername(username, authMode),
    staleTime: CoreConstants.fechUserByIDCacheDurationTimeInMilS,
    enabled,
  });
}

export function useGetTasksDataQuery() {
  return useQuery({
    queryKey: getTasksDataQueryKey(),
    queryFn: () => BackendManager.getTasksGQL(),
    enabled: true,
    staleTime: CoreConstants.fetchTasksCacheDurationTimeInMilS,
    refetchOnWindowFocus: false,
  });
}

export function useFetchPublicTasks(
  initialPageParam: string | undefined,
  limit?: number
) {
  return useInfiniteQuery({
    queryKey: fetchPublicTasksQueryKey(),
    queryFn: ({ pageParam }) =>
      BackendManager.fetchPublicTasks(pageParam, "apiKey", limit),
    initialPageParam: initialPageParam,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) =>
      lastPage?.nextToken as string | undefined,
    staleTime: CoreConstants.fechPublicTasksCacheDurationTimeInMilS,
    refetchOnWindowFocus: false,
  });
}

export function useFetchCurrentUserSocialManPosts(
  userID: string,
  initialPageParam: string | undefined
) {
  return useInfiniteQuery({
    queryKey: fetchCurrentUserSocialManPostsQueryKey(userID),
    queryFn: ({ pageParam }) =>
      BackendManager.fetchCurrentUserSocialManPosts(userID, pageParam),
    initialPageParam: initialPageParam,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) =>
      lastPage?.nextToken as string | undefined,
    staleTime: CoreConstants.fetchSocialManPostsCacheDurationTimeInMilS,
    refetchOnWindowFocus: false,
  });
}

export function useFetchModelPresetByModelID(modelID: string, enabled = true) {
  return useInfiniteQuery({
    queryKey: fetchModelPresetByModelIDQueryKey(modelID),
    queryFn: ({ pageParam }) =>
      BackendManager.fetchModelPresetByModelID(modelID, pageParam),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) =>
      lastPage?.nextToken,
    enabled: enabled,
    staleTime: CoreConstants.fetchModelPresetByModelIDCacheDurationTimeInMilS,
    refetchOnWindowFocus: false,
  });
}

export function useFetchModelPresetByUserID(userID: string, authMode?: string) {
  return useInfiniteQuery({
    queryKey: fetchModelPresetByUserIDQueryKey(userID),
    queryFn: ({ pageParam }) =>
      BackendManager.fetchModelPresetByUserID(userID, pageParam, authMode),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) =>
      lastPage?.nextToken,
    enabled: true,
    staleTime: CoreConstants.fetchModelPresetByUserIDCacheDurationTimeInMilS,
    refetchOnWindowFocus: false,
  });
}

export function useFetchModelTaskIDFromModelID(
  modelID: string,
  enabled = true
) {
  return useQuery({
    queryKey: fetchModelTaskIDFromModelIDQueryKey(modelID),
    queryFn: () => BackendManager.fetchModelTaskIDFromModelID(modelID),
    enabled,
    staleTime: CoreConstants.ComfyTokenCacheDurationTimeInMilS,
  });
}
export function useListUserComfyTokens(userID: string) {
  return useQuery({
    queryKey: listUserComfyTokensQueryKey(),
    queryFn: () => BackendManager.listUserComfyTokens(userID),
    enabled: true,
    staleTime: CoreConstants.ComfyTokenCacheDurationTimeInMilS,
  });
}

export function useFetchCurrentUserTaskResults(
  userID: string,
  authMode?: string
) {
  return useInfiniteQuery({
    queryKey: fetchCurrentUserTaskResultsQueryKey(userID),
    queryFn: ({ pageParam }) =>
      BackendManager.fetchCurrentUserTaskExecutionResults(
        userID,
        pageParam,
        authMode
      ),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) =>
      lastPage?.nextToken,
    enabled: true,
    staleTime: CoreConstants.fetchTasksCacheDurationTimeInMilS,
    refetchOnWindowFocus: false,
  });
}
export function useFetchTaskResultByID(
  taskID: string,
  staleTime: number = CoreConstants.fetchTasksCacheDurationTimeInMilS,
  authMode?: string
) {
  return useQuery({
    queryKey: fetchTaskResultsByIDQueryKey(taskID),
    queryFn: () => BackendManager.getTaskExecutionResult(taskID, authMode),
    enabled: true,
    staleTime,
    refetchOnWindowFocus: false,
  });
}
