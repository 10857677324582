"use client";
import "./AmplifyConfigurer";
// NOTE: keep this line the import above has to load before the import below
import { Hub } from "aws-amplify/utils";

Hub.listen(
  "auth",
  ({ channel, payload, source, patternInfo, ...rest }: any) => {
    // console.log("%c" + "AMPLIFY_AUTH_event: ", "color: #008080");
    // console.log("%c" + JSON.stringify(payload, null, 4), "color: #008080");
    switch (payload.event) {
      case "signedIn":
        signInListeners.forEach((x) => x?.());
        signInListeners = signInListeners.filter((x) => x != undefined);
        break;
      case "signedOut":
      case "oAuthSignOut":
        signOutListeners.forEach((x) => x?.());
        signOutListeners = signOutListeners.filter((x) => x != undefined);
        break;
      case "customOAuthState":
        customAuthStateListeners.forEach((x) => x?.(payload.data));
        customAuthStateListeners = customAuthStateListeners.filter(
          (x) => x != undefined
        );
        break;
      case "configured":
        break;
      case "credentials_configured":
        break;
      case "signIn_failure":
        break;
      case "signInWithRedirect_failure":
        signInRedirectFailureListeners = signInRedirectFailureListeners.filter(
          (x) => x != undefined
        );
        signInRedirectFailureListeners.forEach((x) => x?.());
        break;
      case "signUp":
        signUpListeners = signUpListeners.filter((x) => x != undefined);
        signUpListeners.forEach((x) => x?.());
        break;
      case "signUp_failure":
        break;
      case "confirmSignUp":
        confirmSignUpListeners = confirmSignUpListeners.filter(
          (x) => x != undefined
        );
        confirmSignUpListeners.forEach((x) => x?.());
        break;
      case "completeNewPassword_failure":
        break;
      case "autoSignIn":
        break;
      case "autoSignIn_failure":
        break;
      case "forgotPassword":
        break;
      case "forgotPassword_failure":
        break;
      case "forgotPasswordSubmit":
        break;
      case "forgotPasswordSubmit_failure":
        break;
      case "tokenRefresh":
        break;
      case "tokenRefresh_failure":
        break;
      case "cognitoHostedUI":
        break;
      case "cognitoHostedUI_failure":
        break;
      case "customState_failure":
        break;
      case "parsingCallbackUrl":
        break;
      case "userDeleted":
        break;
    }
  }
);

let confirmSignUpListeners: (() => void)[] = [];
let signOutListeners: (() => void)[] = [];
let customAuthStateListeners: ((customState: string) => void)[] = [];
let signInListeners: (() => void)[] = [];
let signUpListeners: (() => void)[] = [];
let signInRedirectFailureListeners: (() => void)[] = [];

export function addConfirmSignUpListener(listener: () => void) {
  // console.log("adding listener for sign up confirmation");
  confirmSignUpListeners.push(listener);
}
export function addSignInListener(listener: () => void) {
  // console.log("adding listener for sign In");
  signInListeners.push(listener);
}
export function addSignUpListener(listener: () => void) {
  // console.log("adding listener for sign In");
  signUpListeners.push(listener);
}
export function addSignInRedirectFailureListener(listener: () => void) {
  // console.log("adding listener for sign In");
  signInRedirectFailureListeners.push(listener);
}
export function addSignOutListener(listener: () => void) {
  // console.log("adding listener for sign out");
  signOutListeners.push(listener);
}
export function addCustomAuthStateListener(
  listener: (customState: string) => void
) {
  // console.log("adding listener for Custom Auth state");
  customAuthStateListeners.push(listener);
}

export default function AWSClientLoader() {
  return <></>;
}
