export function extractContentMimeTypeFromData(content: any) {
  const parts = content.split(",");
  const mimeType = parts[0].split(":")[1].split(";")[0];
  return mimeType;
}
export function getFileExtension(filepath: string): string {
  const parts = filepath.split(".");
  return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : "";
}

export function changeExtension(
  filePath: string,
  newExtension: string
): string {
  const parts = filePath.split(".");
  parts[parts.length - 1] = newExtension.replace(/^\./, "");
  return parts.join(".");
}

export function isImageFile(filename: string): boolean {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
  const ext = getFileExtension(filename);
  return imageExtensions.includes(ext);
}

export function isVideoFile(filename: string): boolean {
  const videoExtensions = [
    // Common video formats
    "mp4",
    "mov",
    "avi",
    "wmv",
    "flv",
    "mkv",
    "webm",
    "m4v",
    // Container formats
    "3gp",
    "3g2",
    "asf",
    "mpg",
    "mpeg",
    "m2v",
    "mts",
    "ts",
    "divx",
    // Professional formats
    "vob",
    "ogv",
    "dv",
    "qt",
    "f4v",
    "rm",
    "rmvb",
  ];

  const ext = getFileExtension(filename);
  return videoExtensions.includes(ext);
}

export function getMimeType(filepath: string): string {
  const extension = getFileExtension(filepath);
  switch (extension) {
    // Video types
    case "mp4":
      return "video/mp4";
    case "webm":
      return "video/webm";
    case "ogg":
      return "video/ogg";
    case "mov":
      return "video/quicktime";
    case "avi":
      return "video/x-msvideo";
    case "wmv":
      return "video/x-ms-wmv";
    case "mpeg":
    case "mpg":
      return "video/mpeg";
    case "flv":
      return "video/x-flv";
    case "mkv":
      return "video/x-matroska";

    // Audio types
    case "mp3":
      return "audio/mpeg";
    case "wav":
      return "audio/wav";
    case "aac":
      return "audio/aac";
    case "flac":
      return "audio/flac";
    case "wma":
      return "audio/x-ms-wma";
    case "aiff":
      return "audio/aiff";
    case "m4a":
      return "audio/x-m4a";
    case "wv":
      return "audio/x-wavpack";

    // Image types
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "bmp":
      return "image/bmp";
    case "svg":
      return "image/svg+xml";
    case "tiff":
    case "tif":
      return "image/tiff";
    case "webp":
      return "image/webp";

    // Text types
    case "txt":
      return "text/plain";
    case "html":
    case "htm":
      return "text/html";
    case "css":
      return "text/css";
    case "js":
      return "text/javascript";
    case "json":
      return "application/json";
    case "xml":
      return "application/xml";

    default:
      return "application/octet-stream"; // Default MIME type for unknown files
  }
}

export function extractFileExtensionFromData(content: any) {
  //this is faulty but we have to assume until we can figure it out
  let fileExtension;
  const mimeType = extractContentMimeTypeFromData(content);
  // video types
  if (mimeType === "video/mp4") {
    fileExtension = ".mp4";
  } else if (mimeType === "video/webm") {
    fileExtension = ".webm";
  } else if (mimeType === "video/ogg") {
    fileExtension = ".ogg";
  } else if (mimeType === "video/quicktime") {
    fileExtension = ".mov";
  } else if (mimeType === "video/x-msvideo") {
    fileExtension = ".avi";
  } else if (mimeType === "video/x-ms-wmv") {
    fileExtension = ".wmv";
  } else if (mimeType === "video/mpeg") {
    fileExtension = ".mpeg";
  } else if (mimeType === "video/x-flv") {
    fileExtension = ".flv";
  } else if (mimeType === "video/x-matroska") {
    fileExtension = ".mkv";
  }
  // audio types
  else if (mimeType === "audio/mpeg") {
    fileExtension = ".mp3";
  } else if (mimeType === "audio/wav") {
    fileExtension = ".wav";
  } else if (mimeType === "audio/aac") {
    fileExtension = ".aac";
  } else if (mimeType === "audio/ogg") {
    fileExtension = ".ogg";
  } else if (mimeType === "audio/flac") {
    fileExtension = ".flac";
  } else if (mimeType === "audio/mp4") {
    fileExtension = ".mp4";
  } else if (mimeType === "audio/x-ms-wma") {
    fileExtension = ".wma";
  } else if (mimeType === "audio/webm") {
    fileExtension = ".webm";
  } else if (mimeType === "audio/aiff") {
    fileExtension = ".aiff";
  } else if (mimeType === "audio/x-m4a") {
    fileExtension = ".m4a";
  } else if (mimeType === "audio/x-wavpack") {
    fileExtension = ".wv";
  }
  //Image types
  else if (mimeType === "image/jpeg") {
    fileExtension = ".jpg";
  } else if (mimeType === "image/png") {
    fileExtension = ".png";
  } else if (mimeType === "image/gif") {
    fileExtension = ".gif";
  } else if (mimeType === "image/bmp") {
    fileExtension = ".bmp";
  } else if (mimeType === "image/svg+xml") {
    fileExtension = ".svg";
  } else if (mimeType === "image/tiff") {
    fileExtension = ".tiff";
  } else if (mimeType === "image/webp") {
    fileExtension = ".webp";
  }
  //Text types
  else if (mimeType === "text/plain") {
    fileExtension = ".txt";
  } else if (mimeType === "text/html") {
    fileExtension = ".html";
  } else if (mimeType === "text/css") {
    fileExtension = ".css";
  } else if (mimeType === "text/javascript") {
    fileExtension = ".js";
  } else if (mimeType === "application/json") {
    fileExtension = ".json";
  } else if (mimeType === "application/xml") {
    fileExtension = ".xml";
  } else {
    // Default extension if the MIME type is not recognized
    fileExtension = ".unknown";
  }
  return fileExtension;
}

type FileMetadata = {
  FILE_NAME?: string;
  FILE_TYPE?: string;
  FILE_SIZE_KB?: number;
  DURATION?: number;
  WIDTH?: number;
  HEIGHT?: number;
  STRING_LENGTH?: number;
  TOKENS_COUNT?: number;
};
export async function processFileGetDataAndMetadata(
  file: File
): Promise<{ data: string; metadata: FileMetadata }> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    var metadata: FileMetadata = {};
    fileReader.onloadend = async (e) => {
      const { result } = e.target!;

      if (file.type.startsWith("text")) {
        let textMeta = await handleTextFile(metadata, result);
        metadata = { ...metadata, ...{ textMeta } };
      }

      if (file.type.startsWith("image")) {
        let imageMeta = await handleImageFile(metadata, result);
        metadata = { ...metadata, ...{ imageMeta } };
      }

      if (file.type.startsWith("video")) {
        let videoMeta = await handleVideoFile(metadata, result);
        metadata = { ...metadata, ...{ videoMeta } };
      }

      if (file.type.startsWith("audio")) {
        let audioMeta = await handleAudioFile(metadata, result);
        metadata = { ...metadata, ...{ audioMeta } };
      }
      resolve({ data: result as string, metadata });
    };
    // fileReader.readAsText(file);
    fileReader.readAsDataURL(file);
  });
}

export async function processFileGetData(
  file: File
): Promise<{ data: string }> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = async (e) => {
      const { result } = e.target!;

      resolve({ data: result as string });
    };
    // fileReader.readAsText(file);
    fileReader.readAsDataURL(file);
  });
}

async function handleTextFile(
  currentMetadata: FileMetadata,
  result: string | ArrayBuffer | null
): Promise<FileMetadata> {
  return new Promise((resolve, reject) => {
    const base64String = (result as string).split(",")[1];
    const buffer = Buffer.from(base64String, "base64");
    const fileContent = buffer.toString("utf-8");

    const stringLength: number = fileContent.length;
    currentMetadata.STRING_LENGTH = stringLength;
    resolve(currentMetadata);
  });
}

async function handleImageFile(
  currentMetadata: FileMetadata,
  result: string | ArrayBuffer | null
): Promise<FileMetadata> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      currentMetadata.WIDTH = width;
      currentMetadata.HEIGHT = height;
      resolve(currentMetadata);
    };
    img.src = result as string;
  });
}

async function handleVideoFile(
  currentMetadata: FileMetadata,
  result: string | ArrayBuffer | null
): Promise<FileMetadata> {
  return new Promise((resolve, reject) => {
    let media = document.createElement("video") as HTMLVideoElement;
    media.onloadedmetadata = function () {
      const duration = media.duration;
      const width: number = media.videoWidth;
      const height: number = media.videoHeight;
      currentMetadata.DURATION = duration;
      currentMetadata.WIDTH = width;
      currentMetadata.HEIGHT = height;
      resolve(currentMetadata);
    };
    media.src = result as string;
  });
}

async function handleAudioFile(
  currentMetadata: FileMetadata,
  result: string | ArrayBuffer | null
): Promise<FileMetadata> {
  return new Promise((resolve, reject) => {
    let media = new Audio();
    media.onloadedmetadata = function () {
      currentMetadata.DURATION = media.duration;
      resolve(currentMetadata);
    };
    media.src = result as string;
  });
}

export function hashFileData(file: File) {
  return `${file.name}-${file.lastModified}-${file.size}`;
}
