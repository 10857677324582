"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import LogoString from "./LogoString";
// import { useSearchParams } from "next/navigation";
type LogoProps = {
  textSize: number;
  isLoading?: boolean;
  isDisabled?: boolean;
  showParent?: boolean;
};

export default function Logo({
  textSize = 3,
  isLoading = false,
  isDisabled = false,
  showParent = false,
}: LogoProps) {
  // const searchParams = useSearchParams();
  const pathname = usePathname();
  const textSizeStyle =
    textSize <= 1
      ? "text-xl"
      : textSize == 2
      ? "text-2xl"
      : textSize == 3
      ? "text-2xl"
      : textSize == 4
      ? "text-2xl"
      : textSize == 5
      ? "text-2xl"
      : "text-6xl";
  const isSocialMan = pathname.includes("socialman");
  const logoString = isSocialMan ? "SocialMan" : "MLTask";

  var destination = isSocialMan ? "/socialman" : "/";
  function renderParent() {
    if (showParent != true || destination == "/") return;
    return (
      <div className={"my-auto flex flex-row"}>
        <p className={"text-lg text-secondary"}>MLT</p>
        <div className="divider divider-horizontal m-0 p-0"></div>
      </div>
    );
  }
  return (
    <div
      className={`flex ${textSizeStyle} mx-auto flex flex-row justify-center`}
    >
      <Link
        className={`${isDisabled == true ? "pointer-events-none" : ""}`}
        href={"/"}
      >
        {renderParent()}
      </Link>
      <Link
        className={`${isDisabled == true ? "pointer-events-none" : ""}`}
        href={destination}
      >
        <LogoString
          isLoading={isLoading}
          isSocialMan={isSocialMan}
          logoString={logoString}
        />
      </Link>
    </div>
  );
}
