"use client";
import HeroWavesSVG from "@/components/ui/SVGIcons/HeroWaves";
import Constants from "@/utils/Constants";
import { getSignupPath } from "@/utils/NavigationUtils";
import React from "react";
import { BsApp } from "react-icons/bs";
import { IoShareSocialSharp, IoSparkles } from "react-icons/io5";
import LogoString from "../Theming/LogoString";
import HeroBulletPoints from "./HeroBulletPoints";
import SimpleLink from "./SimpleLink";

type Props = { overrideBGColor?: string };

export default function CheckoutAppsHero({ overrideBGColor }: Props) {
  interface Point {
    x: number;
    y: number;
  }

  function calculateCircularPositions(
    centerX: number,
    centerY: number,
    radius: number,
    itemCount: number,
    offsetAngle: number = 0
  ): Point[] {
    const positions: Point[] = [];
    const angleIncrement = (2 * Math.PI) / itemCount;

    for (let i = 0; i < itemCount; i++) {
      const angle = i * angleIncrement;
      const x = centerX + radius * Math.cos(angle + offsetAngle);
      const y = centerY + radius * Math.sin(angle + offsetAngle);
      positions.push({ x, y });
    }

    return positions;
  }
  function renderTitle() {
    return (
      <div className="mb-12 flex w-full flex-col space-y-8">
        <div className="relative mx-auto mb-5 inline-flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-accent p-4 text-accent-content">
          <BsApp size={60} className="absolute inset-0 m-auto" />
          <IoSparkles
            size={10}
            className="absolute  inset-0 m-auto mr-[20px] mt-[22px]"
          />
          <IoShareSocialSharp size={25} className="absolute inset-0 m-auto" />
        </div>

        <h1 className="text-2xl font-bold tracking-tighter text-base-content md:text-4xl lg:text-5xl xl:text-6xl ">
          <div className="flex justify-center space-x-2">
            <span>{`Post to all your social networks with `}</span>
          </div>
          <div className="flex justify-center space-x-2">
            <LogoString logoString="SocialMan" isSocialMan={true} />
            <span>{`powered by`}</span>
            <strong className="ml-2 bg-gradient-to-r from-secondary to-warning bg-clip-text font-extrabold text-transparent ">
              {`MLTask`}
            </strong>
          </div>
        </h1>
      </div>
    );
  }
  return (
    <section
      className={`relative flex w-full grow flex-col items-center ${
        overrideBGColor ?? Constants.defaultBGColor
      } `}
    >
      <HeroWavesSVG fillColorCSS="fill-base-400" inverted={false} />
      {/* <div className="pointer-events-none absolute inset-0 mt-0 h-full w-full">
        <SparklesCore
          id="tsparticlesfullpage"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={25}
          className="h-full w-full"
          particleColor="#FFFFFF"
        />
      </div> */}
      <div className="mx-auto flex w-full flex-col px-4 py-12 text-center sm:px-6 md:px-12 lg:px-24 lg:py-24 ">
        {renderTitle()}

        <HeroBulletPoints
          points={[
            "Manage all your social platforms in one place and let AI craft captions and descriptions that resonate. Save time, boost engagement, and grow your online presence effortlessly.",
            // "Efficiency Redefined: Craft, Utilize, and Even Profit from Custom Presets",
            // "Never worry about memorizing prompts again!",
            // "Access a plethora of community-made presets or design your own with MLTask.",
            // "Looking to profit?",
            // "Sell your unique prompts confidently—your structures remain private, securing your innovation from potential theft.",
          ]}
        />
        <div className="mt-10 flex justify-center gap-4 ">
          <SimpleLink href={getSignupPath()} text={"Start posting now"} />
        </div>
      </div>

      <HeroWavesSVG fillColorCSS="fill-base-400" inverted={true} />
    </section>
  );
}
