import { raleway } from "@/utils/FontsConst";
import React from "react";

type Props = { points: string[] };

export default function HeroBulletPoints({ points }: Props) {
  return (
    <div className="mx-auto flex w-2/4 flex-col">
      {points.map((x, index) => (
        <p
          key={index}
          className={`mt-0 ${raleway.className} text-lg leading-relaxed md:text-lg lg:text-2xl`}
        >
          {x}
        </p>
      ))}
    </div>
  );
}
