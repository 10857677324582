export type SocialManPostStatus =
  | "DRAFT"
  | "ENQUEUED"
  | "EXECUTING"
  | "SCHEDULED"
  | "FINISHED"
  | "FAILED";

export type SocialManNetworks =
  | "TIKTOK"
  | "YOUTUBE"
  | "FACEBOOK"
  | "INSTAGRAM"
  | "TWITTER"
  | "LINKEDIN"
  | "PINTEREST";

export type PagedLocalSocialManPost = {
  posts: LocalSocialManPost[];
  nextToken?: string;
};

export enum LocalSocialManTiktokPrivacyStatus {
  PUBLIC_TO_EVERYONE = "PUBLIC_TO_EVERYONE",
  FOLLOWER_OF_CREATOR = "FOLLOWER_OF_CREATOR",
  MUTUAL_FOLLOW_FRIENDS = "MUTUAL_FOLLOW_FRIENDS",
  SELF_ONLY = "SELF_ONLY",
}

export type CalendarEventData = {
  id: string;
  title: string;
  start: Date;
  end: Date;
};

export type LocalSocialManPost = {
  id: string;
  userID: string;
  title?: string | undefined;
  description?: string | undefined;
  s3Key?: string | undefined;
  s3KeyResized?: string | undefined;
  createdAt: string;
  scheduledAt?: string | undefined;
  postedAt?: string | undefined;
  status: SocialManPostStatus;
  tiktok?: {
    title?: string;
    caption?: string;
    privacy?: LocalSocialManTiktokPrivacyStatus;
    contentDisclosureBrandedContent?: boolean;
    contentDisclosureEnabled?: boolean;
    contentDisclosureYourBrand?: boolean;
    usersCanComment?: boolean;
    usersCanDuet?: boolean;
    usersCanStitch?: boolean;
    videoCoverTimestampMs?: number;
    postID?: string;
  };
  linkedin?: {
    caption?: string;
    postID?: string;
  };
  youtube?: {
    targetChannelID?: string;
    title?: string;
    description?: string;
    tags?: string;
    thumbnailURL?: string;
    privacy?: string;
    category?: string;
    videoID?: string;
  };
  pinterest?: {
    title?: string;
    boardID?: string;
    thumbnailURL?: string;
    link?: string;
    description?: string;
    pinID?: string;
  };
  instagram?: {
    caption?: string;
    thumbnail?: string;
    targetAccountID?: string;
    postToStory?: boolean;
    postID?: string;
    storyID?: string;
  };
  facebook?: {
    caption?: string;
    thumbnail?: string;
    targetPageID?: string;
    postToStory?: boolean;
    postID?: string;
    storyID?: string;
    storyURL?: string;
  };
  twitter?: {
    caption: string;
    tweetID?: string;
  };
  postedToTiktok?: boolean;
  postedToLinkedin?: boolean;
  postedToPinterest?: boolean;
  postedToYoutube?: boolean;
  postedToFacebook?: boolean;
  postedToInstagram?: boolean;
  postedToTwitter?: boolean;
  enabledFacebook: boolean;
  enabledPinterest: boolean;
  enabledInstagram: boolean;
  enabledTiktok: boolean;
  enabledLinkedin: boolean;
  enabledTwitter: boolean;
  enabledYoutube: boolean;
};
