import {
  CognitoIdentityClient,
  GetIdCommand,
} from "@aws-sdk/client-cognito-identity";
import {
  AdminGetUserCommandOutput,
  AdminGetUserRequest,
  AttributeType,
  CognitoIdentityProvider,
  CognitoIdentityProviderClient,
  ListUsersCommand,
} from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import

export enum CognitoCustomUserAttributes {
  GLOBAL_USER_ID = "GLOBAL_USER_ID",
  STRIPE_CUSTOMER_ID = "STRIPE_CUSTOMER_ID",
}
export async function getUserByUsername(
  region: string,
  UserPoolId: string,
  Username: string
): Promise<AdminGetUserCommandOutput> {
  const cognitoIdServiceProvider = new CognitoIdentityProvider({
    region: region,
  });
  const params: AdminGetUserRequest = {
    UserPoolId,
    Username,
  };
  let user = await cognitoIdServiceProvider.adminGetUser(params);
  return user;
}
export function adminConfirmUser(
  region: string,
  UserPoolId: string,
  Username: string
) {
  const cognitoIdServiceProvider = new CognitoIdentityProvider({
    region: region,
  });
  cognitoIdServiceProvider.adminConfirmSignUp({ UserPoolId, Username });
}
export function getCustomUserAttributeKey(
  attributeName: CognitoCustomUserAttributes
): string {
  switch (attributeName) {
    case CognitoCustomUserAttributes.GLOBAL_USER_ID:
      return "custom:globalUserID";
    case CognitoCustomUserAttributes.STRIPE_CUSTOMER_ID:
      return "custom:stripeCustomer_ID";
  }
  throw new Error(`Unrecognized Attribute name ${attributeName}`);
}

export async function updateUserAttributes(
  Attributes: AttributeType[],
  region: string,
  UserPoolId: string,
  Username: string
) {
  const cognitoIdServiceProvider = new CognitoIdentityProvider({
    region: region,
  });
  var newUserAttributes = {
    UserAttributes: Attributes,
    UserPoolId: UserPoolId,
    Username: Username,
  };
  return cognitoIdServiceProvider.adminUpdateUserAttributes(newUserAttributes);
}

export async function getCognitoDataFromSubId(
  userPoolId: string,
  subId: string
) {
  const client = new CognitoIdentityProviderClient();

  const params = {
    UserPoolId: userPoolId,
    Filter: `sub="${subId}"`,
    Limit: 1,
  };
  const command = new ListUsersCommand(params);
  try {
    const response = await client.send(command);

    if (response.Users && response.Users.length > 0) {
      return response.Users[0];
    } else {
      console.log("User not found");
      return null;
    }
  } catch (error) {
    console.error("Error querying Cognito user by subId:", error);
    throw error;
  }
}

export async function getCognitoIdFromSubId(userPoolId: string, subId: string) {
  return (await getCognitoDataFromSubId(userPoolId, subId))!.Username;
}
export async function getCognitoAttributesFromSubId(
  userPoolId: string,
  subId: string
) {
  return (await getCognitoDataFromSubId(userPoolId, subId))!.Attributes;
}

export async function getIdentityID(
  idToken: string,
  IdentityPoolId: string,
  userPoolId: string,
  region: string
): Promise<string | undefined> {
  // Create a GetId parameters object
  const client = new CognitoIdentityClient({
    region: region,
  });
  const arn = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
  const Logins = { [`${arn}`]: idToken };

  const command = new GetIdCommand({
    IdentityPoolId,
    Logins,
  });

  try {
    const response = await client.send(command);
    return response.IdentityId || "";
  } catch (error) {
    console.error("Error getting identity ID:", error);
    throw error;
  }
  // Call GetId to retrieve the identity ID associated with the user sub ID
}
